function DataDB(name) {
  this.dbName = name
  this.isActive = false
  this.db = null
  this.loaded = false

  // 내부에 메일 배열을 가지고 있도록 하여, 없는 것만 새로 DB에 추가하도록 처리(배열에도 함께 추가.).
  // 데이터를 가져가는 것은 배열에서 가져가도록 할 것.
  // 표시되는 메세지의 날짜에 맞춰서 적당히 가져가도록~!!
  this.dataList = []
  this.dataMap = {}

  this.init = () => {
    const DBOpenRequest = window.indexedDB.open(this.dbName, 1)

    DBOpenRequest.onerror = (error) => {
      console.log('error - db open failed', error).this.isActive = false
    }

    DBOpenRequest.onsuccess = (event) => {
      console.log('success - db open success.')

      this.isActive = true

      this.db = event.target.result

      this.load()
    }

    DBOpenRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        console.log('error - db upgrade.', error)
        this.isActive = false
      }

      const objectStore = db.createObjectStore(this.dbName, { keyPath: 'roomId' })

      objectStore.createIndex('conversationId', 'conversationId', { unique: true })
      // objectStore.createIndex('receivedTime', 'receivedTime', { unique: false })
      // objectStore.createIndex('id', 'id', { unique: true })
    }

    this.isActive = true
  }

  this.close = () => {
    if (this.isActive === true) {
      this.db.close()
    }
    this.isActive = false
  }

  this.load = () => {
    if (this.isActive !== true) {
      return null
    }

    this.dataList = []
    this.dataMap = {}

    const promise = new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.dbName], 'readonly')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database load finished.', tEvent)
      }

      transaction.onerror = (tEvent) => {
        console.log('Transaction not opened due to error.', tEvent)
        reject(tEvent)
      }

      const objectStore = transaction.objectStore(this.dbName)
      const req = objectStore.openCursor()
      // const index = objectStore.index('conversationId')

      // const keyRangeValue = IDBKeyRange.only(conversationId)
      // const req = index.openCursor(keyRangeValue)
      req.onsuccess = (event) => {
        const cursor = event.target.result

        if (cursor) {
          console.log('cursor read successfully.', event)

          this.dataList.push(cursor.value)

          const { roomId, conversationId } = cursor.value
          this.dataMap[roomId] = conversationId

          cursor.continue()
        } else {
          console.log('Entries all loaded.')
          this.loaded = true
          resolve(this.dataList)
        }
      }

      req.onerror = (err) => {
        console.log('cursor read error.', err)
        reject(err)
      }
    })

    return promise
  }

  this.addData = (roomId, conversationId) => {
    if (this.isActive !== true) {
      return null
    }

    if (this.dataMap[roomId] == null) {
      const data = {
        roomId,
        conversationId,
      }

      this.dataList.push(data)
      this.dataMap[roomId] = conversationId

      const transaction = this.db.transaction([this.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(this.dbName)

      const objectStoreRequest = objectStore.add(data)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Added successfully.', event)
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to add.', error)
      }

      return data
    }

    return this.dataMap[roomId]
  }

  this.getDataList = () => {
    return this.dataList
  }

  this.getDataMap = () => {
    return this.dataMap
  }

  this.isLoaded = () => {
    return this.loaded
  }

  // this.sortMailList = () => {
  //   this.mailList.sort((a, b) => {
  //     let ret = 0
  //     if (a.receivedTime > b.receivedTime) {
  //       ret = 1
  //     } else if (a.receivedTime < b.receivedTime) {
  //       ret = -1
  //     }
  //     return ret
  //   })
  // }
}

const dataDB = new DataDB('conversationDB')
dataDB.init()

export default dataDB
