import mailApiMS365 from 'services/mailApi/graph'
import mailApiGMAIL from 'services/mailApi/gmail'
import mailApiImap from 'services/mailApi/imap'
import mailApiTemp from 'services/mailApi/temp'
import store from 'store'

let mailApi = null

export function init() {
  let info = null
  const str = store.get('app.user.registorInfo')
  if (str != null) {
    info = JSON.parse(str)
  }

  if (info != null) {
    const provider = info.provider.toLowerCase()
    switch (provider) {
      case 'ms365': {
        const token = store.get('app.user.providerToken')
        if (token != null) {
          mailApiMS365.init(token)

          mailApi = mailApiMS365
          return mailApi
        }
        break
      }
      case 'gmail': {
        mailApi = mailApiGMAIL
        return mailApi
      }
      case 'imap': {
        mailApi = mailApiImap
        return mailApi
      }
      case 'officemail':
      default:
        mailApi = mailApiTemp
        return mailApi
    }
  }

  return null
}

export async function getCurrentAccount() {
  if (mailApi == null) {
    return null
  }

  const ret = await mailApi.getCurrentAccount()
  return ret
}

export async function getMyProfilePhoto(size) {
  if (mailApi == null) {
    return null
  }

  const ret = await mailApi.getMyProfilePhoto(size)
  return ret
}

export async function getMailFolders() {
  const ret = await mailApi.getMailFolders()
  return ret
}

export async function getMessage(mailId) {
  const ret = await mailApi.getMessage(mailId)
  return ret
}

export async function getMessage2(internetMessageId) {
  const ret = await mailApi.getMessage2(internetMessageId)
  return ret
}

export async function getMessages(folderId, select) {
  const ret = await mailApi.getMessages(folderId, select)
  return ret
}

export async function getSortedMessagesByCId(select, size) {
  const ret = await mailApi.getSortedMessagesByCId(select, size)
  return ret
}

export async function getMessagesByCId(conversationId, select, size, startDate, endDate, isDesc) {
  const ret = await mailApi.getMessagesByCId(
    conversationId,
    select,
    size,
    startDate,
    endDate,
    isDesc,
  )
  return ret
}

export async function getMessageInfo(messageId) {
  const ret = await mailApi.getMessageInfo(messageId)
  return ret
}

export function getIterator(res, recvCB, readToEnd) {
  return mailApi.getIterator(res, recvCB, readToEnd)
}

export function test() {
  mailApi.test()
}

const apiMap = {
  init,
  getCurrentAccount,
  getMyProfilePhoto,
  getMailFolders,
  getMessage,
  getMessage2,
  getMessages,
  getSortedMessagesByCId,
  getMessagesByCId,
  getMessageInfo,
  getIterator,
  test,
}

export default apiMap
