import React from 'react'

import classNames from 'classnames'

import styles from './style.module.scss'

const resourcePath = 'https://rework.so/resources/fileicon/'

class FTIcon extends React.Component {
  state = {
    imgSrc: '',
    text: null,
    size: null,
    alt: '',
  }

  componentDidMount() {
    const { fileName, fileType, size } = this.props

    // console.log(fileName, fileType, size)

    let info = null
    let text = null
    let alt = 'file'
    if (fileName != null) {
      const pos = fileName.lastIndexOf('.')
      const ext = fileName.substring(pos + 1)
      info = this.getImageInfo(ext)
      alt = ext
      text = info.type === 'general' ? ext : null
    } else if (fileType != null) {
      info = this.getImageInfo(fileType)
      text = info.type === 'general' ? fileType : null
      alt = fileType
    } else {
      info = this.getImageInfo('')
    }

    this.setState({
      imgSrc: info.path,
      text,
      size,
      alt,
    })
  }

  // componentDidUpdate(prevProps) {
  // }

  componentWillUnmount() {}

  getImageInfo = (fileType) => {
    let imgFileName = ''
    let type = null
    const fType = fileType.toLowerCase()
    switch (fType) {
      case 'mp3':
      case 'wav':
      case 'mpa':
      case 'wma':
      case 'ogg':
      case 'aif':
      case 'cda':
      case 'mid':
      case 'midi':
      case 'wpl':
        imgFileName = 'file-audio.png'
        break

      case 'apk':
      case '7z':
      case 'arj':
      case 'deb':
      case 'pkg':
      case 'rar':
      case 'rpm':
      case 'z':
      case 'zip':
        imgFileName = 'file-archive.png'
        break

      // disk
      case 'iso':
      case 'bin':
      case 'toast':
      case 'vcd':
        imgFileName = 'file-archive.png'
        break

      case 'c':
      case 'cpp':
      case 'cs':
      case 'css':
      case 'html':
      case 'java':
      case 'js':
      case 'json':
      case 'php':
      case 'py':
        imgFileName = 'file-code.png'
        break

      // database
      case 'sql':
      case 'csv':
      case 'db':
      case 'dbf':
      case 'log':
      case 'mdb':
      case 'sav':
      case 'tar':
      case 'xml':
      case 'dat':
        imgFileName = 'file-general.png'
        type = 'general'
        break

      // document
      case 'doc':
      case 'docx':
      case 'odt':
      case 'rtf':
      case 'wpd':
      case 'tex':
        imgFileName = 'file-word.png'
        break

      case 'txt':
        imgFileName = 'file-text.png'
        break

      // email
      case 'email':
      case 'eml':
      case 'emlx':
      case 'msg':
      case 'oft':
      case 'ost':
      case 'pst':
      case 'vcf':
        imgFileName = 'file-general.png'
        type = 'general'
        break

      case 'exe':
        imgFileName = 'file-general.png'
        type = 'general'
        break

      case 'fnt':
      case 'fon':
      case 'otf':
      case 'ttf':
        imgFileName = 'file-general.png'
        break

      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'gif':
      case 'exif':
      case 'tiff':
      case 'tif':
      case 'bmp':
      case 'svg':
      case 'heic':
      case 'heif':
        imgFileName = 'file-image.png'
        break

      case 'pdf':
        imgFileName = 'file-pdf.png'
        break

      case 'bak':
      case 'cab':
      case 'cfg':
      case 'cpl':
      case 'dll':
      case 'cur':
      case 'dmp':
      case 'drv':
      case 'icns':
      case 'ico':
      case 'ini':
      case 'lnk':
      case 'msi':
      case 'sys':
      case 'tmp':
        imgFileName = 'file-general.png'
        type = 'general'
        break

      case 'ppt':
      case 'pptx':
      case 'key':
      case 'odp':
      case 'pps':
        imgFileName = 'file-powerpoint.png'
        break

      case 'xlsx':
      case 'xlsm':
      case 'xls':
      case 'ods':
        imgFileName = 'file-excel.png'
        break

      case 'mp4':
      case 'avi':
      case 'flv':
      case 'mov':
      case 'mkv':
      case 'mpeg':
      case 'mpg':
      case 'h264':
      case '3gp':
      case '3g2':
      case 'rm':
      case 'swf':
      case 'm4v':
      case 'vob':
      case 'wmv':
        imgFileName = 'file-video.png'
        break

      case 'hwp':
      case 'hwpx':
        imgFileName = 'file-hwp.png'
        break

      default:
        imgFileName = 'file-general.png'
        type = 'general'
    }

    return {
      path: `${resourcePath}${imgFileName}`,
      type,
    }
  }

  render() {
    const { imgSrc, text, size, alt } = this.state
    const { className } = this.props

    const styleProps = {}
    if (size != null) {
      styleProps.width = size
    }

    const textElem = text != null ? <div className={classNames('type')}>{text}</div> : ''

    return (
      <div className={classNames(styles.ftIcon, 'ftIcon', className)}>
        <img src={imgSrc} style={styleProps} alt={alt} />
        {textElem}
      </div>
    )
  }
}

export default FTIcon
