const actions = {
  SET_STATE: 'menu/SET_STATE',
  SET_POPUP: 'menu/SET_POPUP',
  GET_DATA: 'menu/GET_DATA',
  RELOAD: 'menu/RELOAD',
  ADD_LISTENER: 'menu/ADD_LISTENER',
  REMOVE_LISTENER: 'menu/REMOVE_LISTENER',
  NOTIFY_LISTENER: 'menu/NOTIFY_LISTENER',
}

export default actions
