/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import {
  Form,
  Modal,
  Button,
  Input,
  Tooltip,
  Progress,
  Row,
  Col,
  Spin,
  Radio,
  notification,
  message as AntMessage,
} from 'antd'
import {
  DownloadOutlined,
  // ExclamationCircleOutlined
} from '@ant-design/icons'

import parse from 'html-react-parser'
// import Viewer from 'react-viewer'
import Viewer from 'components/ImageViewer'
import ReactPlayer from 'react-player'
import RoomSetting from 'pages/chat/component/RoomSetting'
import ContentView from 'pages/chat/component/ContentView'
import RoomMemberList from 'pages/chat/component/MemberView/RoomMemberList'
import SelectMember from 'pages/chat/component/MemberView/SelectMember'
import ChannelList from 'pages/chat/component/ChannelList'
import ShortMemberList from 'pages/chat/component/ShortMemberList'

import emojiData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { getEnvParam } from 'env'
import classNames from 'classnames'
import i18n from 'i18n'
import { history } from 'index'

import { processError, openFile, convertHEIC } from 'utils'
import { getDefaultName } from 'pages/chat/component/chatView/data/cUtil'

import conf from 'conf'
import store from 'store'
import localforage from 'localforage'

import {
  addMember,
  deleteMember,
  leaveRoom,
  getDirectMessageRoom,
  createChannel,
} from 'pages/conversations/component/roomUtil'

import { clientForLanding } from 'myNet'
import { customFetch } from 'services/jwt'
import Ngql from 'gql/ninegql'

import exitSVG from 'assets/images/icon-exit.svg'
import closeSVG from 'assets/images/toolbar-close.svg'

import styles from '../style.module.scss'
import dataManager from '../chatView/data/dataManager'

function ChatUI(reactObj) {
  const self = this

  self.reactObj = reactObj

  self.spinning = false

  self.strokeColor = '#448ef7'

  const downloadProgress = (e, percent) => {
    console.log('download progress - ', percent)
  }

  self.confirmDownload = (chatObj, fileDigest, fileName, fileSize) => {
    Modal.confirm({
      wrapClassName: classNames(styles.popupBase),
      title: 'Confirm',
      icon: null,
      content: i18n.t('chat.confirm.downloadFile'),
      okText: i18n.t('common.button.ok'),
      cancelText: i18n.t('common.button.cancel'),
      okButtonProps: { type: 'text' },
      cancelButtonProps: { type: 'text' },
      maskTransitionName: 'maskTransitionName',
      onOk: () => {
        self.showDownload(chatObj, fileDigest, fileName, fileSize)
      },
      onCancel: () => {
        console.log('Cancel')
      },
    })
  }

  self.downloadAndSave = (chatObj, fileDigest, fileName) => {
    self.download(chatObj, fileDigest, downloadProgress).then(({ downloadPath }) => {
      const link = document.createElement('a')
      link.href = downloadPath
      link.download = fileName
      link.click()
      link.remove() // IE 미지원
      window.URL.revokeObjectURL(downloadPath)
    })
  }

  self.download = (chatObj, fileDigest, onProgress, fileName, mimetype) => {
    const onBeforeSend = (xhr) => {
      self.xhr = xhr
    }

    const promise = new Promise((resolve, reject) => {
      if (chatObj != null && chatObj.xhrGetFile != null) {
        chatObj
          .xhrGetFile(fileDigest, onProgress, onBeforeSend)
          .then(({ xhr }) => {
            self.xhr = null
            console.log('xhrGetFile', xhr)

            let blob = xhr.response

            let downloadBlob = blob
            if (mimetype != null) {
              downloadBlob = new Blob([blob], { type: mimetype })
            }

            const ret = {
              downloadPath: window.URL.createObjectURL(downloadBlob),
            }

            if (fileName != null || mimetype != null) {
              if (blob.type === 'text/xml' && fileName != null) {
                const lcFileName = fileName.toLowerCase()
                if (lcFileName.endsWith('.svg') === true) {
                  blob = new Blob([blob], { type: 'image/svg+xml' })
                } else if (
                  lcFileName.endsWith('.heic') === true ||
                  lcFileName.endsWith('.heif') === true
                ) {
                  const tmpFile = new File([blob], fileName, { type: mimetype || 'image/heic' })
                  // createObjectURL에는 blob, file 모두 사용가능하므로, file을 blob 대신 전달.
                  self.spinning = false
                  if (onProgress != null) {
                    onProgress(null, null)
                  }
                  convertHEIC(tmpFile, 'image/jpeg', 0.8).then((imgBlob) => {
                    console.log(imgBlob)
                    self.spinning = false
                    if (onProgress != null) {
                      onProgress(null, null)
                    }
                    ret.path = window.URL.createObjectURL(imgBlob)
                    resolve(ret)
                  })

                  return
                } else if (mimetype != null) {
                  blob = new Blob([blob], { type: mimetype })
                }
              } else if (mimetype != null) {
                blob = new Blob([blob], { type: mimetype })
              }

              ret.path = window.URL.createObjectURL(blob)
            }

            resolve(ret)
          })
          .catch((err) => {
            self.xhr = null
            console.log('error - ', err)
            AntMessage.info('download failed.')
            // @todo - error 처리. - 404 file not found.
            reject(err)
          })
      } else {
        reject(new Error('chat api not found.'))
      }
    })

    return promise
  }

  self.cancelDownload = () => {
    if (self.xhr != null) {
      try {
        self.xhr.abort()
      } catch (err) {
        console.log(err)
      }
      self.xhr = null
    }
  }

  self.showPopup = (clsName, title, content) => {
    const modalExt = {
      afterClose: () => {
        self.cancelDownload()
      },
    }

    modalExt.modal = Modal.info({
      wrapClassName: classNames(styles.mediaPopup, clsName),
      title,
      icon: '',
      content,
      closeIcon: <img src={closeSVG} alt="close" />,
      closable: true,
      maskClosable: true,
      centered: true,
      afterClose: () => {
        if (modalExt.afterClose != null) {
          modalExt.afterClose()
        }
      },
      okButtonProps: { disabled: true },
      cancelButtonProps: { disabled: true },
      maskTransitionName: 'maskTransitionName',
    })

    return modalExt
  }

  self.showImage = (chatObj, fileDigest, fileName, fileSize, dimension, mimetype) => {
    self.downloadPercent = 0

    self.progressStatus = null
    self.spinning = true

    const popupObj = {}

    popupObj.getProgressElem = () => {
      return (
        <div className={classNames(styles.content, { spinning: self.spinning })}>
          <Spin tip="Loading..." spinning={self.spinning}>
            <Progress
              type="circle"
              strokeColor={self.strokeColor}
              percent={self.downloadPercent}
              status={self.progressStatus}
            />
          </Spin>
        </div>
      )
    }

    popupObj.modalExt = self.showPopup(styles.image, null, popupObj.getProgressElem())

    popupObj.spiningTimer = setTimeout(() => {
      popupObj.spiningTimer = null
      self.spinning = false
      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }, 3000)

    const onProgress = (evt, percent) => {
      if (evt != null && evt.total === 0) {
        // console.log(' loaded - ', evt.loaded, attachment.info.filesize);
        self.downloadPercent = Math.round((evt.loaded * 100) / fileSize)
      } else if (percent != null) {
        self.downloadPercent = percent
      }

      console.log(' onProgress - ', evt, self.downloadPercent)

      if (popupObj.spiningTimer != null) {
        clearTimeout(popupObj.spiningTimer)
        popupObj.spiningTimer = null
        self.spinning = false
      }

      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }

    console.log(' - req download : ', fileDigest, fileName)
    self
      .download(chatObj, fileDigest, onProgress, fileName, mimetype)
      .then(({ path, downloadPath }) => {
        console.log(' - download : ', fileName, path, downloadPath)

        self.spinning = false

        let viewerVisible = true

        const download = () => {
          console.log(' - downloadImg : ', fileName, downloadPath)
          const link = document.createElement('a')
          link.href = downloadPath
          link.download = fileName
          link.click()
          link.remove() // IE 미지원
        }

        function closeModal() {
          viewerVisible = false
          updateModal()
          popupObj.modalExt.modal.destroy()
        }

        function updateModal() {
          popupObj.modalExt.afterClose = () => {
            self.cancelDownload()
            console.log(' - revoke : ', fileName, path, downloadPath)

            window.URL.revokeObjectURL(downloadPath)
            if (path != null) {
              window.URL.revokeObjectURL(path)
            }
          }

          const viewAttr = {
            visible: viewerVisible,
            onClose: () => {
              closeModal()
            },
            onMaskClick: () => {
              closeModal()
            },
            downloadable: true,
            noNavbar: true,
            changeable: false,
            zoomSpeed: 0.1,
            images: [
              { src: path, alt: fileName },
              // { src: '', alt: 'test' }
            ],
            onChange: (activeImage, index) => {
              console.log(' -- ', activeImage, index)
              if (index === 1) {
                activeImage.src = path
              }
            },
            customToolbar: (defaultoolbar) => {
              console.log(defaultoolbar)
              const ret = [...defaultoolbar]
              const downloadCfg = defaultoolbar.find((item) => {
                if (item.key === 'download') {
                  return true
                }
                return false
              })

              if (downloadCfg != null) {
                downloadCfg.onClick = () => {
                  download()
                }
                // } else {
                //   ret.push({
                //     key: 'download',
                //     render: <DownloadOutlined style={{ fontSize: 14 }} />,
                //     onClick: () => {
                //       // console.log( 'download - ', e);
                //       download()
                //     },
                //   })
              }
              return ret
            },
          }

          popupObj.modalExt.modal.update({
            wrapClassName: classNames(styles.mediaPopup, styles.image, styles.viewer),
            title: 'Image viewer',
            content: (
              <div className={styles.content}>
                <div className={styles.scaleWrapper}>
                  <Viewer {...viewAttr} />
                </div>
              </div>
            ),
          })
        }

        updateModal()
      })
      .catch((/* err */) => {
        // console.log(err);
        self.progressStatus = 'exception'
        onProgress(null, 0)
      })
  }

  self.showVideo = (chatObj, fileDigest, fileName, fileSize, dimension, mimetype) => {
    console.log(' showVideo - ', fileName, dimension, mimetype)

    self.downloadPercent = 0

    self.progressStatus = null
    self.spinning = true

    self.isVideo = mimetype.toLowerCase().indexOf('video') === 0

    const popupObj = {}

    popupObj.getProgressElem = () => {
      return (
        <div className={classNames(styles.content, { spinning: self.spinning })}>
          <Spin tip="Loading..." spinning={self.spinning}>
            <Progress
              type="circle"
              strokeColor={self.strokeColor}
              percent={self.downloadPercent}
              status={self.progressStatus}
            />
          </Spin>
        </div>
      )
    }

    popupObj.modalExt = self.showPopup(styles.video, null, popupObj.getProgressElem())

    popupObj.spiningTimer = setTimeout(() => {
      popupObj.spiningTimer = null
      self.spinning = false
      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }, 3000)

    const onProgress = (evt, percent) => {
      console.log(' onProgress - ', evt, percent)
      if (evt != null && evt.total === 0) {
        // console.log(' loaded - ', evt.loaded, attachment.info.filesize);
        self.downloadPercent = Math.round((evt.loaded * 100) / fileSize)
      } else if (percent != null) {
        self.downloadPercent = percent
      }

      if (popupObj.spiningTimer != null) {
        clearTimeout(popupObj.spiningTimer)
        popupObj.spiningTimer = null
        self.spinning = false
      }

      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }

    self
      .download(chatObj, fileDigest, onProgress, fileName, mimetype)
      .then(({ path, downloadPath }) => {
        // console.log(fileName, path)

        self.spinning = false

        const download = () => {
          const link = document.createElement('a')
          link.href = downloadPath
          link.download = fileName
          link.click()
          link.remove() // IE 미지원
        }

        popupObj.modalExt.afterClose = () => {
          self.cancelDownload()
          window.URL.revokeObjectURL(downloadPath)
          if (path != null) {
            window.URL.revokeObjectURL(path)
          }
        }

        self.canPlay = ReactPlayer.canPlay(path)

        console.log(' - canPlay ? - ', self.canPlay, fileName, path)

        let viewW = 'min(90vw, 640px)'
        let viewH = 'min(90vh, 350px)'

        const onReady = (e) => {
          console.log(' - onReady : ', e)
          const player = e.getInternalPlayer()
          if (
            player.tagName === 'VIDEO' &&
            player.videoWidth != null &&
            player.videoHeight != null
          ) {
            const w = player.videoWidth
            const h = player.videoHeight

            if (w > 0 && h > 0) {
              viewW = `min(90vw, ${w}px)`
              viewH = `min(90vh, ${h}px)`

              updateModal()
            }
          }
        }

        const onError = (e) => {
          console.log(' - onError : ', e)
        }

        function updateModal() {
          const playerAttrs = {
            config: {
              file: {
                forceAudio: self.isVideo !== true,
              },
            },
          }

          playerAttrs.width = viewW
          playerAttrs.height = viewH

          popupObj.modalExt.modal.update({
            title: (
              <div className={styles.topBtns}>
                <Tooltip placement="bottom" title="Download">
                  <Button
                    type="default"
                    size="small"
                    icon={<DownloadOutlined style={{ fontSize: 20 }} />}
                    onClick={download}
                  />
                </Tooltip>
              </div>
            ),
            content: (
              <div className={styles.content}>
                <ReactPlayer
                  url={path}
                  controls
                  onReady={onReady}
                  onError={onError}
                  {...playerAttrs}
                />
              </div>
            ),
          })
        }

        updateModal()
      })
      .catch((/* err */) => {
        // console.log(err);
        self.progressStatus = 'exception'
        onProgress(null, 0)
      })
  }

  self.showDownload = (chatObj, fileDigest, fileName, fileSize) => {
    console.log(' showDownload - ', fileName)

    self.downloadPercent = 0

    self.progressStatus = null
    self.spinning = true

    const popupObj = {}

    popupObj.getProgressElem = () => {
      return (
        <div className={classNames(styles.content, { spinning: self.spinning })}>
          <Spin tip="Loading..." spinning={self.spinning}>
            <Progress
              type="circle"
              strokeColor={self.strokeColor}
              percent={self.downloadPercent}
              status={self.progressStatus}
            />
          </Spin>
        </div>
      )
    }

    popupObj.modalExt = self.showPopup(styles.download, null, popupObj.getProgressElem())

    popupObj.spiningTimer = setTimeout(() => {
      popupObj.spiningTimer = null
      self.spinning = false
      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }, 3000)

    const onProgress = (evt, percent) => {
      console.log(' onProgress - ', evt, percent)
      if (evt != null && evt.total === 0) {
        // console.log(' loaded - ', evt.loaded, attachment.info.filesize);
        self.downloadPercent = Math.round((evt.loaded * 100) / fileSize)
      } else if (percent != null) {
        self.downloadPercent = percent
      }

      if (popupObj.spiningTimer != null) {
        clearTimeout(popupObj.spiningTimer)
        popupObj.spiningTimer = null
        self.spinning = false
      }

      popupObj.modalExt.modal.update({
        content: popupObj.getProgressElem(),
      })
    }

    self
      .download(chatObj, fileDigest, onProgress, fileName)
      .then(({ path, downloadPath }) => {
        // console.log(fileName, path)

        self.spinning = false

        const download = () => {
          const link = document.createElement('a')
          link.href = downloadPath
          link.download = fileName
          link.click()
          link.remove() // IE 미지원

          window.URL.revokeObjectURL(downloadPath)
          if (path != null) {
            window.URL.revokeObjectURL(path)
          }

          popupObj.modalExt.modal.destroy()
        }

        download()
      })
      .catch((/* err */) => {
        // console.log(err);
        self.progressStatus = 'exception'
        onProgress(null, 0)
      })
  }

  self.showEditTitle = (chatObj, roomTitle, descText, onChanged) => {
    const formRef = React.createRef()
    const inputElem = (
      <Form ref={formRef} layout="vertical">
        <Form.Item name="displayName" label="Room name" initialValue={roomTitle}>
          <Input placeholder="Room name" />
        </Form.Item>
        <Form.Item name="description" label="Description" initialValue={descText}>
          <Input placeholder="Description" />
        </Form.Item>
      </Form>
    )

    Modal.confirm({
      title: i18n.t('chat.title.editProfile'),
      wrapClassName: classNames(styles.popupBase),
      icon: null,
      content: inputElem,
      okText: i18n.t('common.button.ok'),
      cancelText: i18n.t('common.button.cancel'),
      maskTransitionName: 'maskTransitionName',
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
      onOk: () => {
        if (formRef.current != null) {
          const newTitle = formRef.current.getFieldValue('displayName')
          const newDesc = formRef.current.getFieldValue('description')
          console.log(' input value : ', newTitle)

          chatObj
            .updateRoomEx(newTitle, newDesc)
            .then((res) => {
              console.log(' updateRoom res : ', res)
              if (res.json.code === 200) {
                const roomObj = chatObj.getRoomObj()
                dataManager.getRoomObj(
                  roomObj.ChatRoom.id,
                  () => {
                    if (onChanged != null) {
                      onChanged(newTitle, newDesc)
                    }
                  },
                  false,
                  false,
                  true,
                )
              }
            })
            .catch((err) => {
              notification.error({
                message: err.code,
                description: err.message,
              })
            })
        }
      },
      onCancel: () => {
        console.log('Cancel')
      },
    })
  }

  self.confirmArchive = (chatObj, isArchive, onChanged) => {
    const title =
      isArchive !== false ? i18n.t('chat.menu.archiveChat') : i18n.t('chat.menu.unarchiveChat')

    const content =
      isArchive !== false
        ? i18n.t('chat.confirm.archiveChat')
        : i18n.t('chat.confirm.unarchiveChat')

    Modal.confirm({
      title,
      wrapClassName: classNames(styles.popupBase),
      icon: null,
      content,
      okText: i18n.t('common.button.ok'),
      cancelText: i18n.t('common.button.cancel'),
      maskTransitionName: 'maskTransitionName',
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
      onOk: () => {
        chatObj
          .updateRoomEx(null, null, null, null, isArchive !== false)
          .then((res) => {
            console.log(' archive res : ', res)
            if (res.data.code === 200) {
              const roomObj = chatObj.getRoomObj()
              dataManager.getRoomObj(
                roomObj.ChatRoom.id,
                () => {
                  if (onChanged != null) {
                    onChanged(roomObj.ChatRoom.archive)
                  }
                },
                false,
                false,
                true,
              )
            }
          })
          .catch((err) => {
            notification.error({
              message: err.code,
              description: err.message,
            })
          })
      },
      onCancel: () => {
        console.log('Cancel')
      },
    })
  }

  self.uploadPhoto = (file, loadingFunc, onUpdated) => {
    console.log(file)

    // this.updateInfo.imgUpdating = true
    // this.forceUpdate()

    const mutationUploadFile = Ngql.Mutation('UploadImageFile', {
      uploadImageFile: Ngql.Node(
        {
          url: true,
          ok: true,
          errors: true,
        },
        null,
        false,
        {
          file: Ngql.Var('file', 'Upload!'),
        },
      ),
    })

    const queryForm = new FormData()

    const queryStr = Ngql.generateGQLString(mutationUploadFile)

    const gqlObj = {
      operationName: 'UploadImageFile',
      query: queryStr,
      variables: {
        file: null,
      },
    }

    const jsonStr = JSON.stringify(gqlObj)
    console.log(jsonStr)

    queryForm.set('operations', jsonStr)
    queryForm.set('map', '{"1":["variables.file"]}')
    if (file != null) {
      queryForm.set('1', file)
    }

    localforage.getItem('app.user.token', (err, token) => {
      const headers = {}
      if (token != null) {
        headers.authorization = `JWT ${token}`
      }

      if (loadingFunc != null) {
        loadingFunc(true)
      }

      const errorFunc = (code, msg) => {
        notification.warning({
          message: code,
          description: msg,
        })
        return true
      }

      customFetch(conf.SERVER.URL, {
        method: 'POST',
        headers,
        body: queryForm,
      })
        .then((response) => {
          // console.log(response);
          response.json().then((res) => {
            if (processError(res, errorFunc) === false) {
              if (res.data.uploadImageFile != null && res.data.uploadImageFile.ok === true) {
                AntMessage.info('file upload - success')
                if (loadingFunc != null) {
                  loadingFunc(false, res.data.uploadImageFile.url)
                }

                if (onUpdated != null) {
                  onUpdated()
                }
              }
            } else if (loadingFunc != null) {
              loadingFunc(false)
            }
          })
        })
        .catch((error) => {
          processError(error, errorFunc)

          if (loadingFunc != null) {
            loadingFunc(false)
          }
          this.updateInfo.imgUpdating = false
          this.forceUpdate()
        })
    })
  }

  self.updateAccountInfo = (id, sendData, onUpdated, hideResultMsg) => {
    const mutation = Ngql.Mutation('updateUserInfo', {
      updateUser: Ngql.Node(
        {
          ok: true,
          errors: true,
        },
        null,
        false,
        {
          userData: Ngql.Var('userData', 'updateUserInput'),
          userGid: Ngql.Var('userGid', 'String'),
        },
      ),
    })

    const variables = {
      userData: {
        // displayName: (displayName == null || displayName.length === 0) ? ' ' : displayName,
        // displayName,
        // avatarUrl,
        ...sendData,
      },
      // userGid: 'VXNlck5vZGU6MTk=',
    }

    if (id != null) {
      variables.userGid = id
    }

    Ngql.GQLObj(mutation, { vars: variables })
      .mutate({}, clientForLanding)
      .then((res) => {
        console.log(res)
        if (hideResultMsg !== true) {
          AntMessage.info(i18n.t('profile.result.modified'))
        }
        if (onUpdated != null) {
          onUpdated()
        }
        return true
      })
      .catch((error) => {
        console.log(error)
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  }

  self.getAutoImage = (onLoaded) => {
    const queryNewPhotoUrl = Ngql.Query('newPhotoUrl', {
      newPhotoUrl: true,
    })

    const gqlObj = Ngql.GQLObj(
      queryNewPhotoUrl,
      {
        useUI: false,
      },
      null,
    )

    gqlObj.watch(
      (res) => {
        console.log(' --- new Image url -- ', res.data.newPhotoUrl)
        if (onLoaded != null) {
          onLoaded(res.data.newPhotoUrl)
        }
      },
      {},
      clientForLanding,
    )
  }

  self.openProfileSettings = (id, avatarUrl, displayName, email, onUpdated) => {
    const ref = React.createRef()

    const photoObj = {
      loading: false,
      url: avatarUrl,
      isAutoImg: false,
      file: null,
      okDisabled: true,
      loadingFunc: (value, url) => {
        let changed = false
        if (url !== photoObj.url) {
          photoObj.url = url
          changed = true
        }
        if (photoObj.loading !== value) {
          photoObj.loading = value
          changed = true
        }

        if (changed) {
          photoObj.okDisabled = false
          updateModal()
        }
      },
      onValuesChanged: (changedValues, allValues) => {
        console.log(' --- changedValues : ', changedValues, allValues)
        photoObj.okDisabled =
          allValues.displayName == null || allValues.displayName.trim().length === 0

        updateModal()
      },
    }

    const autoImage = () => {
      self.getAutoImage((url) => {
        photoObj.isAutoImg = true
        photoObj.loadingFunc(false, url)
      })
    }

    const openAvatar = (loadingFunc) => {
      const acceptedType = 'image/png, image/jpeg, image/bmp, image/tif'
      const sizeLimit = 500 * 1024
      openFile(
        null,
        (url, file) => {
          console.log(file)
          photoObj.file = file
          photoObj.isAutoImg = false
          loadingFunc(false, url)
        },
        acceptedType,
        sizeLimit,
        () => {
          AntMessage.warning(`${i18n.t('common.warning.filesizeLimit')} (500kb)`)
        },
      )
    }

    // const clearAvatar = () => {
    //   photoObj.isAutoImg = false

    //   photoObj.loadingFunc(false, null)
    // }

    const renderContent = () => {
      const img =
        photoObj.url != null && photoObj.url.length > 0 ? (
          <img className="uploadImg" src={photoObj.url} alt={displayName} />
        ) : (
          <span className="fe fe-user" />
        )

      return (
        <Form
          ref={ref}
          layout="vertical"
          hideRequiredMark
          className="mb-4"
          onValuesChange={photoObj.onValuesChanged}
        >
          <div className="member-container bm14">
            <div className="avatar">
              {img}
              <Spin className={styles.spin} spinning={photoObj.loading} />
            </div>
            <div className="info">
              <div className="name">{i18n.t('chat.setting.profileImage')}</div>
              <div className="email">{i18n.t('chat.string.profileImage')}</div>
              <div className={classNames('extra-container')}>
                <Button
                  type="default"
                  size="large"
                  onClick={() => {
                    openAvatar(photoObj.loadingFunc)
                  }}
                >
                  {i18n.t('chat.button.change')}
                </Button>
                {/*
                <Button
                  type="default"
                  size="large"
                  onClick={() => {
                    clearAvatar()
                  }}
                >
                  Reset photo
                </Button>
                */}
                <Button
                  type="default"
                  size="large"
                  onClick={() => {
                    autoImage()
                  }}
                >
                  {i18n.t('chat.button.auto')}
                </Button>
              </div>
            </div>
          </div>
          <Form.Item
            name="displayName"
            label={i18n.t('chat.setting.displayName')}
            initialValue={displayName}
          >
            <Input size="text" placeholder="display name" />
          </Form.Item>
        </Form>
      )
    }

    const modalCfg = {
      title: i18n.t('chat.title.editProfile'),
      wrapClassName: classNames(styles.editProfilePopup, styles.popupBase, 'w460'),
      icon: null,
      content: renderContent(),
      okButtonProps: {
        size: 'large',
        disabled: photoObj.okDisabled,
      },
      cancelButtonProps: {
        size: 'large',
      },
      okText: i18n.t('common.button.ok'),
      cancelText: i18n.t('common.button.cancel'),
      maskTransitionName: 'maskTransitionName',
      onOk: () => {
        // (closeFunc) => {
        if (ref.current != null) {
          const name = ref.current.getFieldValue('displayName')
          console.log('onOK - name : ', name)
          const sendObj = {}
          if (name !== displayName) {
            sendObj.displayName = name
          }
          if (photoObj.isAutoImg === true && photoObj.url !== avatarUrl) {
            sendObj.avatarUrl = photoObj.url
          }
          if (sendObj.displayName != null || sendObj.avatarUrl != null) {
            self.updateAccountInfo(id, sendObj, onUpdated)
          }
        }

        if (photoObj.isAutoImg !== true && photoObj.url !== avatarUrl) {
          console.log('onOK - file : ', photoObj.file)
          self.uploadPhoto(photoObj.file, photoObj.loadingFunc, onUpdated)
        }
      },
      onCancel: () => {
        console.log('Cancel')
      },
    }

    const modalObj = Modal.confirm(modalCfg)

    function updateModal() {
      modalObj.update({
        content: renderContent(),
        okButtonProps: {
          size: 'large',
          disabled: photoObj.okDisabled,
        },
      })
    }
  }
}

// opts - title, onOK, onCancle, afterClose
export function openAddMember(opts) {
  const extObj = {
    listProps: {
      defaultList: opts.defaultList,
      fixedList: opts.fixedList,
      exceptedList: opts.exceptedList,
      hideFixedList: opts.hideFixedList,
      enableDelete: opts.enableDelete,
      enableUnregisted: opts.enableUnregisted,
    },
    memberList: [], // email list 임.
    addList: [],
    popupProps: {},
  }

  extObj.listProps.onListChange = (list, tempMemberObjList, fixedList, addList) => {
    extObj.memberList = [...list]
    extObj.addList = [...addList]
    extObj.tempMemberObjList = [...tempMemberObjList]

    extObj.updateModal()

    if (opts.onListChange != null) {
      opts.onListChange(list, tempMemberObjList, fixedList, addList)
    }
  }

  extObj.listProps.onOpenSelectedList = (visible) => {
    extObj.popupProps.selectedVisible = visible

    extObj.updateModal()
  }

  const modalCfg = {
    title: opts.title || 'Invite',
    icon: null,
    wrapClassName: classNames(styles.addMemberPopup, styles.popupBase, extObj.popupProps),
    content: <SelectMember {...extObj.listProps} />,
    okText: opts.okText || i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    closable: true,
    maskClosable: true,
    okButtonProps: {
      size: 'large',
      disabled: extObj.memberList.length < 1,
    },
    cancelButtonProps: {
      size: 'large',
    },
    onOk: (closeFunc) => {
      if (opts.onOk != null) {
        opts.onOk(
          {
            memberList: extObj.memberList,
            addList: extObj.addList,
            tempMemberObjList: extObj.tempMemberObjList,
          },
          closeFunc,
        )
      } else {
        closeFunc()
      }
    },
    onCancel: () => {
      if (opts.onCancel != null) {
        opts.onCancel('cancel')
      }
    },
    afterClose: () => {
      if (opts.afterClose != null) {
        opts.afterClose()
      }
    },
  }

  extObj.modalObj = Modal.confirm(modalCfg)

  extObj.updateModal = () => {
    extObj.modalObj.update({
      wrapClassName: classNames(styles.addMemberPopup, styles.popupBase, extObj.popupProps),
      okButtonProps: {
        size: 'large',
        disabled: extObj.memberList.length < 1,
      },
    })
  }

  return extObj
}

export function openChatInfo(opts, fixedMemberList, enableDelete) {
  const extObj = {
    memberList: [...fixedMemberList],
    formRef: React.createRef(),
    isFormValid: false,
    initialValues: {
      // isPrivate: true,
      access_role: 'private',
    },
    popupProps: {},
  }

  if (opts != null && opts.initialValues != null) {
    extObj.initialValues = {
      ...extObj.initialValues,
      ...opts.initialValues,
    }
  }

  extObj.onValuesChange = (e) => {
    console.log(' - onValuesChange : ', e)
    setTimeout(() => {
      if (extObj.formRef.current != null) {
        console.log(' - onValuesChange2 : ', extObj.formRef.current)
        extObj.formRef.current
          .validateFields()
          .then((values) => {
            console.log(' - validateFields : ', values)
            extObj.isFormValid = true
            extObj.updateModal()
          })
          .catch((errorInfo) => {
            console.log(' - validateFields err : ', errorInfo)
            extObj.isFormValid = false
            extObj.updateModal()
          })
      }
    }, 100)
  }

  extObj.onListChange = (list, tempMemberObjList) => {
    extObj.memberList = [...list]

    console.log(' - onListChange - ', list, tempMemberObjList)

    extObj.tempMemberMap = {}

    if (tempMemberObjList != null) {
      tempMemberObjList.forEach((member) => {
        extObj.tempMemberMap[member.email] = member
      })
    }

    extObj.updateModal()
  }

  extObj.onClickAddMember = () => {
    openAddMember({
      title: i18n.t('chat.setting.newMember'),
      onOk: (retObj, closeFunc) => {
        if (retObj.memberList != null) {
          const newList = [...retObj.memberList]
          const tempMemberObjList = [...retObj.tempMemberObjList]
          console.log(' - add member - ', newList)

          extObj.onListChange(newList, tempMemberObjList)
          closeFunc()
        }
      },
      hideFixedList: true,
      defaultList: extObj.memberList,
      fixedList: fixedMemberList,
      enableDelete,
      enableUnregisted: true,
    })
  }

  extObj.onOpenSelectedList = (visible) => {
    extObj.popupProps.selectedVisible = visible

    extObj.updateModal()
  }

  extObj.isValid = () => {
    return extObj.isFormValid === true && extObj.memberList.length >= 0
  }

  extObj.getMemberListElem = (addList) => {
    // const userEmail = getEnvParam('userEmail')

    const memberList = addList.filter((/* item */) => {
      // if (item === userEmail) {
      //   return false
      // }
      return true
    })

    const extraElem = (
      <Button
        className={styles.extraBtn}
        type="default"
        size="large"
        htmlType="button"
        onClick={() => {
          extObj.onClickAddMember()
        }}
      >
        {i18n.t('chat.setting.newMember')}
      </Button>
    )

    return (
      <ShortMemberList
        addList={memberList}
        tempMemberMap={extObj.tempMemberMap}
        extraElem={extraElem}
      />
    )
  }

  // label={i18n.t('chat.setting.roomName')}
  // label={i18n.t('chat.setting.description')}
  extObj.render = () => {
    const memberListObj = extObj.getMemberListElem(extObj.memberList)

    return (
      <Form
        ref={extObj.formRef}
        layout="vertical"
        onValuesChange={extObj.onValuesChange}
        initialValues={extObj.initialValues}
      >
        <Form.Item name="display_name" rules={[{ required: true, message: '' }]}>
          <Input placeholder={i18n.t('chat.alt.channelTitle')} />
        </Form.Item>
        <Form.Item name="description">
          <Input.TextArea
            autoSize={{ maxRows: 6 }}
            rows={2}
            placeholder={i18n.t('chat.alt.channelDesc')}
          />
        </Form.Item>
        <Form.Item className={styles.mb20} label={i18n.t('chat.setting.accessScope')}>
          <Col>
            <Row>
              <Form.Item name="access_role">
                <Radio.Group>
                  <Radio value="public">{i18n.t('chat.setting.radioPublic')}</Radio>
                  <Radio value="private">{i18n.t('chat.setting.radioPrivate')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row>
              <span className={styles.itemDesc}>{i18n.t('chat.setting.privateChannelDesc')}</span>
            </Row>
          </Col>
        </Form.Item>
        {memberListObj}
      </Form>
    )
  }

  const modalCfg = {
    title: opts.title,
    icon: null,
    wrapClassName: classNames(styles.addMemberPopup, styles.popupBase, extObj.popupProps),
    content: extObj.render(),
    okText: i18n.t('chat.button.create'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    maskClosable: true,
    okButtonProps: {
      size: 'large',
      disabled: extObj.isValid() !== true,
    },
    cancelButtonProps: {
      size: 'large',
    },
    onOk: (closeFunc) => {
      if (opts.onOk != null && extObj.formRef.current != null) {
        const values = extObj.formRef.current.getFieldsValue()
        // const accessRole = values.isPrivate === true ? 'private' : 'public'

        const data = {
          display_name: values.display_name,
          description: values.description,
          access_role: values.access_role,
          members: extObj.memberList,
          tempMemberObjList: extObj.tempMemberObjList,
        }

        opts.onOk(data, closeFunc)
      } else {
        closeFunc()
      }
    },
    onCancel: () => {
      if (opts.onCancel != null) {
        opts.onCancel('cancel')
      }
    },
    afterClose: () => {
      if (opts.afterClose != null) {
        opts.afterClose()
      }
    },
  }

  extObj.modalObj = Modal.confirm(modalCfg)

  extObj.updateModal = () => {
    extObj.modalObj.update({
      wrapClassName: classNames(styles.addMemberPopup, styles.popupBase, extObj.popupProps),
      okButtonProps: {
        size: 'large',
        disabled: extObj.isValid() !== true,
      },
      content: extObj.render(),
    })
  }
}

const setFileElem = (ref) => {
  if (ref != null) {
    console.log(' ---- ref ---- ', ref, ref.innerText)
    const elems = ref.querySelectorAll('span')
    if (elems.length > 0) {
      setTimeout(() => {
        // const w = elems[1].offsetWidth // + 1
        let w = elems[1].offsetWidth // + 1;
        const wStr = window.getComputedStyle(elems[1], null).width
        if (wStr.substring(wStr.length - 2) === 'px') {
          const num = wStr.substring(0, wStr.length - 2)
          w = window.parseFloat(num)
        }
        if (ref.offsetWidth > w) {
          elems[1].style.maxWidth = `${w}px`
          elems[0].style.maxWidth = `calc(100% - ${w}px)`
        }
      }, 100)
    }
  }
}

export function getFileNameElem(attachments) {
  let filenameElem = ''
  if (attachments != null && attachments.length > 0) {
    const { file_name: filename } = attachments[0]
    const extPos = filename.lastIndexOf('.')
    // const fname = filename.substring(0, extPos);
    // const ext = filename.substring(extPos);
    // let extStr = ext;
    // if (attachments.length === 2) {
    //   extStr = `${ext} & 1 file`;
    // } else if (attachments.length > 2) {
    //   extStr = `${ext} & ${attachments.length - 1} files`;
    // }
    // filenameElem = (
    //   <div className={styles.attachments}>
    //     <span className={styles.filename}>{fname}</span>
    //     <span className={styles.ext}>{extStr}</span>
    //   </div>
    // )

    let txt = filename
    let pos = extPos

    if (pos < 0) {
      pos = txt.length
    }
    if (attachments.length >= 2) {
      txt = i18n.t('chat.message.fileNames', { filename, count: attachments.length - 1 })
      // txt = `${filename} & ${attachments.length - 1} files`
    }

    const front = txt.substring(0, pos)
    const end = txt.substring(pos)

    filenameElem = (
      <div
        ref={(ref) => {
          setFileElem(ref)
        }}
        className={classNames(styles.attachments)}
      >
        <span className={styles.front}>{front}</span>
        <span className={styles.end}>{end}</span>
      </div>
    )
  }

  return filenameElem
}

export function getMembersElem(roomObj, reactObj, addElems) {
  const userEmail = getEnvParam('userEmail')

  const tempMemberList = roomObj.ChatRoom.members.filter((member) => {
    return member.email !== userEmail
  })

  let titleElem = null

  if (tempMemberList.length > 0) {
    titleElem = tempMemberList.map((member, idx) => {
      const name = getDefaultName(member)

      let elem = null
      if (idx === 0) {
        elem = (
          <span className="name" key={member.email}>
            <span>{name}</span>
          </span>
        )
      } else {
        elem = (
          <React.Fragment key={member.email}>
            <span className="comma">,&nbsp;</span>
            <span className="name">
              <span>{name}</span>
            </span>
          </React.Fragment>
        )
      }

      return elem
    })
  } else if (roomObj.ChatRoom.members.length > 0) {
    const member = roomObj.ChatRoom.members[0]
    const name = getDefaultName(member)

    titleElem = (
      <span className="name">
        <span>{name}</span>
      </span>
    )
  }

  if (reactObj != null) {
    return (
      <div className="direct-message-title">
        <div
          ref={(ref) => {
            reactObj.onSetNames(ref)
          }}
          className="names"
        >
          {titleElem}
          {addElems}
        </div>
      </div>
    )
  }

  return (
    <div className="direct-message-title">
      <div className="names">
        {titleElem}
        {addElems}
      </div>
    </div>
  )
}

export function getTitleElem(roomObj, reactObj, addElems) {
  if (roomObj.ChatRoom.type === 'direct_message') {
    return getMembersElem(roomObj, reactObj, addElems)
  }

  return (
    <React.Fragment>
      <span>{roomObj.ChatRoom.display_name}</span>
      {addElems}
    </React.Fragment>
  )
}

export function exitRoom(chatObj, onLeave) {
  Modal.confirm({
    wrapClassName: classNames(styles.popupBase),
    title: 'Confirm',
    icon: null, // <ExclamationCircleOutlined />,
    content: i18n.t('chat.confirm.leaveRoom'),
    okText: i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    okButtonProps: { type: 'text' },
    cancelButtonProps: { type: 'text' },
    onOk: () => {
      const roomObj = chatObj.getRoomObj()
      if (roomObj != null) {
        leaveRoom(roomObj.ChatRoom.id).then(() => {
          if (onLeave != null) {
            onLeave()
          } else {
            history.push('/conversation')
          }
        })
      }
    },
    onCancel: () => {
      console.log('Cancel')
    },
  })
}

export function deleteRoom(chatObj, onDelete) {
  const { ChatRoom } = chatObj.getRoomObj()

  Modal.confirm({
    title: 'Confirm',
    wrapClassName: classNames(styles.popupBase),
    icon: null, // <ExclamationCircleOutlined />,
    content: i18n.t('chat.confirm.deleteRoom', { name: ChatRoom.display_name }),
    okText: i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    okButtonProps: { type: 'primary', size: 'large' },
    cancelButtonProps: { type: 'text', size: 'large' },
    onOk: () => {
      chatObj
        .deleteRoom()
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            if (onDelete != null) {
              onDelete()
            }
          } else {
            notification.error({
              message: res.data.code,
              description: res.data.message,
            })
          }
        })
        .catch((error) => {
          notification.warning({
            message: error.code,
            description: error.message,
          })
        })
    },
    onCancel: () => {
      console.log('Cancel')
    },
  })
}

export function confirmAccessRole(chatObj, accessRole, onSuccess) {
  const roomObj = chatObj.getRoomObj()

  const { display_name: roomTitle } = roomObj.ChatRoom

  console.log(' - confirmAccessRole : ', accessRole)

  let title = ''
  let content = ''
  let okText = ''

  if (accessRole === 'public') {
    title = i18n.t('chat.title.changeToPublic')
    content = i18n.t('chat.confirm.changeToPublic', { name: roomTitle })
    okText = i18n.t('chat.button.changeToPublic')
  } else if (accessRole === 'private') {
    title = i18n.t('chat.title.changeToPrivate')
    content = i18n.t('chat.confirm.changeToPrivate', { name: roomTitle })
    okText = i18n.t('chat.button.changeToPrivate')
  }

  confirmBase({
    title,
    content,
    okText,
    okButtonProps: {
      size: 'large',
    },
    cancelButtonProps: {
      size: 'large',
    },
    onOk: () => {
      // console.log(' - confirmBase : Ok ')
      chatObj
        .updateRoomEx(null, null, accessRole, null)
        .then((res) => {
          if (res.json.code === 200) {
            console.log(' -- updateRoom : ', res)
            if (onSuccess) {
              onSuccess(res.json)
            }
          } else {
            notification.error({
              message: res.json.code,
              description: res.json.message,
            })
          }
        })
        .catch((err) => {
          notification.error({
            message: err.code,
            description: err.message,
          })
        })
    },
  })
}

export function confirmDMToChannel(chatObj, onSuccess) {
  // const roomObj = chatObj.getRoomObj();

  // const { description } = roomObj.ChatRoom;

  const tmpObj = {
    title: i18n.t('chat.title.changeToPrivate'),
    content: i18n.t('chat.confirm.convertToPrivate'),
    okText: i18n.t('chat.button.changeToPrivate'),
    formRef: React.createRef(),
    btnDisabled: true,
  }

  tmpObj.onChange = (e) => {
    console.log(e)
    const text = e.target.value
    if (text.trim().length > 0) {
      tmpObj.btnDisabled = false
    } else {
      tmpObj.btnDisabled = true
    }

    tmpObj.displayName = text

    tmpObj.updateModal()
  }

  tmpObj.render = () => {
    return (
      <React.Fragment>
        <div>{tmpObj.content}</div>
        <Form ref={tmpObj.formRef} layout="vertical">
          <Form.Item
            name="displayName"
            label={i18n.t('chat.setting.roomName')}
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder={i18n.t('chat.alt.title')} onChange={tmpObj.onChange} />
          </Form.Item>
          {/*
          <Form.Item name="description" label={i18n.t('chat.setting.description')} initialValue={description}>
            <Input placeholder={i18n.t('chat.alt.desc')} />
          </Form.Item>
          */}
        </Form>
      </React.Fragment>
    )
  }

  tmpObj.modalObj = confirmBase({
    title: tmpObj.title,
    content: tmpObj.render(),
    okText: tmpObj.okText,
    okButtonProps: { size: 'large', disabled: tmpObj.btnDisabled },
    onOk: () => {
      // console.log(' - confirmBase : Ok ')
      chatObj
        .convertDMToChannel(tmpObj.displayName)
        .then((res) => {
          console.log(' - convertDMToChannel : ', res)
          if (res.data.code === 200) {
            if (onSuccess) {
              onSuccess(res.json)
            }
          } else {
            notification.error({
              message: res.data.code,
              description: res.data.message,
            })
          }
        })
        .catch((err) => {
          notification.error({
            message: err.code,
            description: err.message,
          })
        })
    },
  })

  tmpObj.updateModal = () => {
    tmpObj.modalObj.update({
      okButtonProps: { size: 'text', disabled: tmpObj.btnDisabled },
    })
  }
}

export function openSetting(chatObj, roomObj, onChanged, defaultTab) {
  console.log(chatObj)

  const tmpObj = {
    okDisabled: true,
    popupProps: {},
  }

  const onExitClick = () => {
    exitRoom(chatObj)

    if (tmpObj.modal != null) {
      tmpObj.modal.destroy()
    }
  }

  tmpObj.onChange = (changedData) => {
    console.log(' - onChange : ', changedData)

    tmpObj.okDisabled = false

    tmpObj.updateModal()
  }

  tmpObj.onOpenSelectedList = (visible) => {
    tmpObj.popupProps.selectedVisible = visible

    tmpObj.updateModal()
  }

  const exitBtn =
    defaultTab != null && defaultTab.length > 0 ? (
      ''
    ) : (
      <Button
        type="text"
        className={classNames(styles.exitRoomBtn)}
        icon={<img src={exitSVG} alt="exit" />}
        onClick={onExitClick}
      >
        {i18n.t('chat.button.exitRoom')}
      </Button>
    )

  const inputElem = (
    <React.Fragment>
      <RoomSetting
        ref={(ref) => {
          tmpObj.ref = ref
        }}
        roomObj={roomObj}
        defaultTab={defaultTab}
        onChange={tmpObj.onChange}
        onOpenSelectedList={tmpObj.onOpenSelectedList}
      />
      {exitBtn}
    </React.Fragment>
  )

  tmpObj.processInputData = (data) => {
    if (data.roomInfo != null) {
      const { ChatRoom } = roomObj
      const newTitle =
        ChatRoom.type === 'direct_message' ? ChatRoom.display_name : data.roomInfo.displayName
      const newDesc = data.roomInfo.description
      const newAccessRole = null // data.roomInfo.accessRole
      const img = data.roomInfo.photo
      console.log(' input value : ', newTitle)

      chatObj
        .updateRoomEx(newTitle, newDesc, newAccessRole, img)
        .then((res) => {
          console.log(' updateRoom res : ', res)
          if (res.json.code === 200) {
            dataManager.getRoomObj(
              roomObj.ChatRoom.id,
              () => {
                if (onChanged != null) {
                  onChanged({
                    roomInfo: {
                      title: newTitle,
                      description: newDesc,
                      accessRole: newAccessRole,
                    },
                  })
                }
              },
              false,
              false,
              true,
            )
          }
        })
        .catch((err) => {
          notification.error({
            message: err.code,
            description: err.message,
          })
        })
    }
    if (data.alarm != null) {
      const newNoti = {
        push: data.alarm,
      }

      chatObj
        .updateConfig(newNoti)
        .then((res) => {
          console.log(' updateConfig res : ', res)
          if (res.data.code === 200) {
            dataManager.getRoomObj(
              roomObj.ChatRoom.id,
              () => {
                if (onChanged != null) {
                  onChanged({
                    alarm: true,
                  })
                }
              },
              false,
              false,
              true,
            )
          }
        })
        .catch((err) => {
          notification.error({
            message: err.code,
            description: err.message,
          })
        })
    }
    if (data.members != null && data.members.length > 0) {
      const addEmailList = [...data.members]
      const removeEmailList = []
      roomObj.roomMemberList
        .filter((member) => {
          return member.status === 1
        })
        .forEach((member) => {
          const pos = addEmailList.indexOf(member.email)
          if (pos >= 0) {
            addEmailList.splice(pos, 1)
          } else {
            removeEmailList.push(member.email)
          }
        })

      console.log('addEmailList - ', addEmailList)
      if (addEmailList.length > 0) {
        if (roomObj.ChatRoom.type === 'direct_message') {
          // console.log(' add member in direct_message - not implemented.')
          const memberObjMap = {}
          const oldEmailList = roomObj.roomMemberList.map((item) => {
            memberObjMap[item.email] = item
            return item.email
          })

          const newList = [...oldEmailList, ...addEmailList]

          getDirectMessageRoom(
            newList,
            (primaryId, newRoomObj) => {
              if (onChanged != null) {
                onChanged({
                  directMessage: {
                    primaryId,
                    newRoomObj,
                  },
                })
              }
            },
            (err) => {
              console.log(' - getDirectMessageRoom error - ', err)
            },
            memberObjMap,
          )
        } else {
          const addList = addEmailList.map((item) => {
            return {
              email: item,
              // type: ..
              // status: ..
            }
          })

          addMember(roomObj.ChatRoom.id, addList)
            .then((res) => {
              console.log(res)
              if (res.data.code === 200) {
                console.log(res.data)

                if (onChanged != null) {
                  onChanged({
                    members: {
                      add: true,
                    },
                  })
                }
              }
            })
            .catch((err) => {
              notification.error({
                message: err.code,
                description: err.message,
              })
            })
        }
      }

      console.log('removeList - ', removeEmailList)
      if (removeEmailList.length > 0) {
        if (roomObj.ChatRoom.type !== 'direct_message') {
          const removeList = removeEmailList.map((item) => {
            return {
              email: item,
              // type: ..
              // status: ..
            }
          })

          deleteMember(roomObj.ChatRoom.id, removeList)
            .then((res) => {
              console.log(res)
              if (res.data.code === 200) {
                console.log(res.data)

                if (onChanged != null) {
                  onChanged({
                    members: {
                      remove: true,
                    },
                  })
                }
              }
            })
            .catch((err) => {
              notification.error({
                message: err.code,
                description: err.message,
              })
            })
        }
      }
    }
  }

  let title = i18n.t('chat.title.setting')

  switch (defaultTab) {
    case 'room-name':
      title = i18n.t('chat.title.roomInfoSetting')
      break
    case 'members':
      {
        const { ChatRoom } = roomObj
        const fixedList = ChatRoom.members.filter((item) => {
          return item.status === 1 // delete 상태가 아닌 것.
        })
        title = (
          <React.Fragment>
            <span className="memberTitle">{i18n.t('chat.title.membersSetting')}</span>
            <span className="count">{fixedList.length}</span>
          </React.Fragment>
        )
      }
      break
    case 'alarm':
      title = i18n.t('chat.title.alarmSetting')
      break
    default:
  }

  tmpObj.modalObj = Modal.confirm({
    wrapClassName: classNames(styles.popupBase, styles.roomSetting, tmpObj.popupProps),
    title,
    icon: null,
    content: inputElem,
    closable: true,
    maskClosable: true,
    width: 480,
    okText: i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    okButtonProps: {
      size: 'large',
      disabled: roomObj.ChatRoom.archive !== true && tmpObj.okDisabled,
    },
    cancelButtonProps: {
      size: 'large',
      className: classNames({ hidden: roomObj.ChatRoom.archive }),
    },
    onOk: (closeFunc) => {
      if (roomObj.ChatRoom.archive === true) {
        closeFunc()
        return
      }

      const data = tmpObj.ref.getData()
      console.log(' ----- ', data)

      // 변경 사항이 있는 경우에만 confirm 창 표시.
      if (
        data.roomInfo != null ||
        data.alarm != null ||
        (data.members != null && data.members.length > 0)
      ) {
        Modal.confirm({
          wrapClassName: classNames(styles.popupBase),
          title: 'Confirm',
          icon: null,
          content: i18n.t('chat.confirm.saveSettings'),
          okText: i18n.t('common.button.ok'),
          cancelText: i18n.t('common.button.cancel'),
          okButtonProps: { type: 'text' },
          cancelButtonProps: { type: 'text' },
          maskTransitionName: 'maskTransitionName',
          onOk: () => {
            tmpObj.processInputData(data)
            closeFunc()
          },
          onCancel: () => {
            console.log('Cancel')
            closeFunc()
          },
        })
      } else {
        closeFunc()
      }
    },
    onCancel: () => {
      console.log('Cancel')
    },
  })

  tmpObj.updateModal = () => {
    tmpObj.modalObj.update({
      wrapClassName: classNames(styles.popupBase, styles.roomSetting, tmpObj.popupProps),
      okButtonProps: {
        size: 'large',
        disabled: roomObj.ChatRoom.archive !== true && tmpObj.okDisabled,
      },
    })
  }
}

export function openMember(roomObj, onChanged, disableAdd) {
  const tmpObj = {
    okDisabled: false,
    popupProps: {},
    roomObj,
  }

  tmpObj.onRoomUpdate = (changedData) => {
    console.log(' - onRoomUpdate : ', changedData)

    if (changedData.needClose === true) {
      tmpObj.modalObj.destroy()
    } else {
      tmpObj.okDisabled = false

      const roomId = tmpObj.roomObj.ChatRoom.id

      dataManager.getRoomObj(
        roomId,
        (newRoomObj) => {
          tmpObj.roomObj = newRoomObj
          tmpObj.updateModal()
        },
        false,
        false,
        true,
      )
    }

    if (onChanged) {
      onChanged(changedData)
    }
  }

  tmpObj.onClickAddMember = () => {
    // const userEmail = getEnvParam('userEmail')

    const exceptedList = tmpObj.roomObj.roomMemberList
      .filter((member) => {
        return member.status === 1
      })
      .map((item) => {
        return item.email
      })

    openAddMember({
      title: i18n.t('chat.setting.newMember'),
      okText: i18n.t('chat.button.add'),
      onOk: (retObj, closeFunc) => {
        if (retObj.addList != null) {
          const newList = [...retObj.addList]

          const textElem = parse(i18n.t('chat.setting.confirmAddMember', { count: newList.length }))
          Modal.confirm({
            title: i18n.t('chat.title.addMember'),
            wrapClassName: classNames(styles.popupBase),
            icon: null,
            content: <span>{textElem}</span>,
            okText: i18n.t('chat.button.add'),
            okButtonProps: {
              type: 'text',
            },
            cancelButtonProps: {
              type: 'text',
            },
            onOk: () => {
              console.log(' - add member - ', newList)

              tmpObj.processInputData({
                members: newList,
              })

              closeFunc()
            },
          })
          closeFunc()
        }
      },
      exceptedList,
      enableDelete: true,
    })
  }

  tmpObj.render = () => {
    const addMemberBtn =
      tmpObj.roomObj.ChatRoom.archive !== true ? (
        <Button
          type="primary"
          size="large"
          className={classNames(styles.addMemberBtn, 'abs_L_B')}
          onClick={tmpObj.onClickAddMember}
          disabled={disableAdd}
        >
          {i18n.t('chat.button.addMember')}
        </Button>
      ) : (
        ''
      )

    return (
      <React.Fragment>
        <RoomMemberList roomObj={tmpObj.roomObj} />
        {addMemberBtn}
      </React.Fragment>
    )
  }

  tmpObj.processInputData = (data) => {
    if (data.members != null && data.members.length > 0) {
      const addEmailList = [...data.members]
      const removeEmailList = []
      roomObj.roomMemberList
        .filter((member) => {
          return member.status === 1
        })
        .forEach((member) => {
          const pos = addEmailList.indexOf(member.email)
          if (pos >= 0) {
            addEmailList.splice(pos, 1)
          } else {
            removeEmailList.push(member.email)
          }
        })

      console.log('addEmailList - ', addEmailList)
      if (addEmailList.length > 0) {
        if (roomObj.ChatRoom.type === 'direct_message') {
          // console.log(' add member in direct_message - not implemented.')
          const memberObjMap = {}
          const oldEmailList = roomObj.roomMemberList
            .filter((member) => {
              return member.status === 1
            })
            .map((item) => {
              memberObjMap[item.email] = item
              return item.email
            })

          const newList = [...oldEmailList, ...addEmailList]

          getDirectMessageRoom(
            newList,
            (primaryId, newRoomObj) => {
              tmpObj.onRoomUpdate({
                directMessage: {
                  primaryId,
                  newRoomObj,
                },
                needClose: true,
                roomObj: newRoomObj,
              })
            },
            (err) => {
              console.log(' - getDirectMessageRoom error - ', err)
            },
            memberObjMap,
          )
        } else {
          const addList = addEmailList.map((item) => {
            return {
              email: item,
              // type: ..
              // status: ..
            }
          })

          addMember(roomObj.ChatRoom.id, addList)
            .then((res) => {
              console.log(res)
              if (res.data.code === 200) {
                console.log(res.data)

                tmpObj.onRoomUpdate({
                  member: {
                    add: true,
                  },
                })
              }
            })
            .catch((err) => {
              notification.error({
                message: err.code,
                description: err.message,
              })
            })
        }
      }

      console.log('removeList - ', removeEmailList)
      if (removeEmailList.length > 0) {
        if (roomObj.ChatRoom.type !== 'direct_message') {
          const removeList = removeEmailList.map((item) => {
            return {
              email: item,
              // type: ..
              // status: ..
            }
          })

          deleteMember(roomObj.ChatRoom.id, removeList)
            .then((res) => {
              console.log(res)
              if (res.data.code === 200) {
                console.log(res.data)

                tmpObj.onRoomUpdate({
                  member: {
                    remove: true,
                  },
                })
              }
            })
            .catch((err) => {
              notification.error({
                message: err.code,
                description: err.message,
              })
            })
        }
      }
    }
  }

  tmpObj.getTitleElem = () => {
    const { ChatRoom } = tmpObj.roomObj
    const fixedList = ChatRoom.members.filter((item) => {
      return item.status === 1 // delete 상태가 아닌 것.
    })
    const title = (
      <React.Fragment>
        <span className="memberTitle">{i18n.t('chat.title.membersSetting')}</span>
        <span className="count">{fixedList.length}</span>
      </React.Fragment>
    )

    return title
  }

  tmpObj.modalObj = Modal.info({
    wrapClassName: classNames(styles.popupBase, tmpObj.popupProps),
    title: tmpObj.getTitleElem(),
    icon: null,
    content: tmpObj.render(),
    closable: true,
    maskClosable: true,
    width: 480,
    okText: i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    okButtonProps: { type: 'normal', size: 'large', disabled: tmpObj.okDisabled },
    cancelButtonProps: { size: 'large' },
    onOk: (closeFunc) => {
      closeFunc()
    },
    onCancel: () => {
      console.log('Cancel')
    },
  })

  tmpObj.updateModal = () => {
    tmpObj.modalObj.update({
      title: tmpObj.getTitleElem(),
      // wrapClassName: classNames(styles.popupBase, tmpObj.popupProps),
      content: tmpObj.render(),
      okButtonProps: { size: 'large', disabled: tmpObj.okDisabled },
    })
  }
}

export function openContentList(chatObj, roomObj, key, options) {
  console.log(' - openContentList ', key)

  const contentObj = {}

  contentObj.onTabChanged = (tabKey, title) => {
    contentObj.title = title

    contentObj.updateModal()
  }

  const inputElem = (
    <React.Fragment>
      <ContentView
        chatObj={chatObj}
        roomObj={roomObj}
        defaultTab={key}
        options={options}
        onTabChanged={contentObj.onTabChanged}
      />
    </React.Fragment>
  )

  contentObj.modalObj = Modal.info({
    wrapClassName: classNames(styles.popupBase),
    title: '',
    icon: null,
    content: inputElem,
    closable: true,
    maskClosable: true,
    width: 480,
    okText: i18n.t('common.button.ok'),
    cancelText: i18n.t('common.button.cancel'),
    maskTransitionName: 'maskTransitionName',
    okButtonProps: { size: 'large' },
    onOk: () => {},
    onCancel: () => {
      console.log('Cancel')
    },
  })

  contentObj.updateModal = () => {
    contentObj.modalObj.update({
      title: contentObj.title,
    })
  }
}

export function openPopupCreateDirectMessage(onRoomReady) {
  const userEmail = getEnvParam('userEmail')

  openAddMember({
    title: i18n.t('chat.menu.directMessage'),
    okText: i18n.t('common.button.start'),
    onOk: (retObj, closeFunc) => {
      if (retObj.memberList != null) {
        const newList = [...retObj.memberList]

        const memberObjMap = {}

        if (retObj.tempMemberObjList != null) {
          retObj.tempMemberObjList.forEach((item) => {
            memberObjMap[item.email] = item
          })
        }

        getDirectMessageRoom(
          newList,
          (primaryId, newRoomObj) => {
            console.log(' - getDirectMessageRoom ok - ', newRoomObj)
            if (onRoomReady != null) {
              onRoomReady(primaryId, newRoomObj, null)
            }
            closeFunc()
          },
          (err) => {
            console.log(' - getDirectMessageRoom error - ', err)
          },
          memberObjMap,
        )
      }
    },
    fixedList: [userEmail],
    enableDelete: true,
    enableUnregisted: true,
  })
}

export function openPopupCreateChannel(onRoomReady) {
  const userEmail = getEnvParam('userEmail')

  openChatInfo(
    {
      title: 'New Channel',
      onOk: (data, closeFunc) => {
        if (data != null) {
          const memberObjMap = {}

          if (data.tempMemberObjList != null) {
            data.tempMemberObjList.forEach((item) => {
              memberObjMap[item.email] = item
            })
          }

          createChannel(
            data.display_name,
            data.description,
            data.access_role,
            data.members,
            (primaryId, newRoomObj) => {
              console.log(' - createChannel ok - ', newRoomObj)
              if (onRoomReady != null) {
                onRoomReady(primaryId, newRoomObj, null)
              }
              closeFunc()
            },
            (err) => {
              console.log(' - createChannel error - ', err)
              notification.error({
                message: err.code,
                description: err.message,
              })
            },
            memberObjMap,
          )
        }
      },
    },
    [userEmail],
    true,
  )
}

export function openPopupBrowseChannel(extraBtnProp, onItemClick) {
  const contentObj = {}

  const extraBtn = ''

  // if (extraBtnProp != null) {
  //   extraBtn = (
  //     <span className="extraBtn">
  //       <Button
  //         type="primary"
  //         htmlType="button"
  //         onClick={() => {
  //           contentObj.onClickExtraBtn()
  //         }}
  //       >
  //         {extraBtnProp.btnText}
  //       </Button>
  //     </span>
  //   )
  // }

  contentObj.title = (
    <div className="popupTitle">
      {i18n.t('chat.menu.browseChannel')}
      {extraBtn}
    </div>
  )

  contentObj.onClickExtraBtn = () => {
    if (extraBtnProp.onClick != null) {
      extraBtnProp.onClick()
    }

    contentObj.modalObj.destroy()
  }

  contentObj.onItemClick = (item) => {
    if (onItemClick != null) {
      onItemClick(item)
    }

    contentObj.modalObj.destroy()
  }

  contentObj.render = () => {
    return <ChannelList onItemClick={contentObj.onItemClick} />
  }

  contentObj.modalObj = Modal.confirm({
    // wrapClassName: classNames(styles.popupBase, styles.noBtn, styles.channelsPopup),
    wrapClassName: classNames(styles.popupBase, styles.channelsPopup),
    title: contentObj.title,
    icon: null,
    content: contentObj.render(),
    closable: true,
    maskClosable: true,
    // width: 480,
    maskTransitionName: 'maskTransitionName',
    // footer: null,
    okText: i18n.t('chat.button.newChannel'),
    cancelText: i18n.t('common.button.cancel'),
    okButtonProps: { className: styles.newChannelBtn, type: 'primary', size: 'large' },
    cancelButtonProps: { size: 'large' },
    onOk: () => {
      contentObj.onClickExtraBtn()
    },
    // onCancel: () => {
    //   console.log('Cancel')
    // },
  })

  // contentObj.updateModal = () => {
  //   contentObj.modalObj.update({
  //     title: contentObj.title,
  //   })
  // }
}

export function showSpin(text, delay) {
  const popupObj = {
    spinning: true,
  }

  popupObj.getSpin = () => {
    const attrs = {
      spinning: popupObj.spinning,
    }
    if (text != null) {
      attrs.tip = text
    }
    return (
      <div className={classNames(styles.content, { spinning: popupObj.spinning })}>
        <Spin {...attrs} />
      </div>
    )
  }

  popupObj.modal = Modal.info({
    wrapClassName: classNames(styles.mediaPopup, styles.spinFull),
    icon: '',
    content: popupObj.getSpin(),
    closeIcon: null,
    closable: false,
    maskClosable: false,
    centered: true,
    okButtonProps: { disabled: true },
    cancelButtonProps: { disabled: true },
    maskTransitionName: 'none',
    maskStyle: {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  })

  popupObj.close = () => {
    popupObj.modal.destroy()
  }

  if (delay != null) {
    setTimeout(() => {
      popupObj.close()
    }, delay)
  }

  return popupObj
}

export function confirmBase(options) {
  return Modal.confirm({
    wrapClassName: classNames(styles.popupBase),
    title: options.title,
    icon: null,
    content: options.content,
    okText: options.okText || i18n.t('common.button.ok'),
    cancelText: options.cancelText || i18n.t('common.button.cancel'),
    okButtonProps: options.okButtonProps || { type: 'text' },
    cancelButtonProps: options.cancelButtonProps || { type: 'text' },
    maskTransitionName: 'maskTransitionName',
    onOk: options.onOk,
    onCancel: options.onCancel,
  })
}

export function warningBase(options) {
  const props = {
    title: options.title,
    wrapClassName: classNames(styles.popupBase),
    content: options.content,
    okText: options.okText || i18n.t('common.button.ok'),
    okButtonProps: { size: 'large' },
    maskTransitionName: 'maskTransitionName',
    onOk: options.onOk,
  }
  if (options.icon !== undefined) {
    props.icon = options.icon
  }
  return Modal.warning(props)
}

export async function getEmojiModalElem(visible, options) {
  const emojiModalProps = {
    open: visible,
    title: null,
    // content: <Picker data={emojiData} onEmojiSelect={console.log} />,
    className: styles.emojiPopup,
    footer: null,
    centered: true,
    closable: false,
    mask: true,
    maskClosable: true,
    width: 'auto',
    bodyStyle: {
      padding: 0,
    },
    afterClose: () => {
      if (options.afterClose) {
        options.afterClose()
      }
    },
    onCancel: () => {
      if (options.onCancel) {
        options.onCancel()
      }
    },
  }

  return (
    <Modal {...emojiModalProps}>
      <Picker data={emojiData} onEmojiSelect={options.onSelected} />
    </Modal>
  )
}

const backMaskInfo = {}
export function getBackMask() {
  if (backMaskInfo.elem == null) {
    backMaskInfo.elem = document.createElement('div')
    const { style } = backMaskInfo.elem
    style.position = 'absolute'
    style.zIndex = 1000
    style.left = '0px'
    style.top = '0px'
    style.right = '0px'
    style.bottom = '0px'
    // style.backgroundColor = '#33333388';
    style.pointerEvents = 'visible'
  }

  return backMaskInfo.elem
}

export default ChatUI
