import { gql } from '@apollo/client'
import React from 'react'
// import i18n from 'i18n'

import { getNgqlUIInfo } from 'env'

// import getLogger from 'webpack-log'
import {
  getSearchParams,
  setSearchParams,
  getSearchParamProps,
  addParamChangeListener,
  removeParamChangeListener,
  getTotalPageCnt,
  setInFilter,
  encodeFilterMap,
  encodeSortMap,
  encodeNSearch,
  setFilter,
  checkPageReplace,
} from 'utils'

// eslint-disable-next-line import/no-cycle
import client from 'myNet'

import { extendsReactObj } from './ext'

// const log = getLogger({ name: 'ngql' })

let ngqlUIInfo = null

// ui mode 설명.
// read : detail, edit 에서 모두 표시. field 데이터 전달되지 않음.
// readonly : detail에서만 표시. field 데이터 전달되지 않음.
// read 이면서 field 데이터를 전달하려면, required 속성을 줄 것.

function getOrderStrByName(name, isASC) {
  let str = null
  if (isASC === false) {
    str = `${name}_DESC`
  } else {
    str = `${name}_ASC`
  }
  return str
}

function getStrBycCamelName(name) {
  const upperCaseRegex = /[A-Z]/g
  let str = name.replace(upperCaseRegex, '_$&')
  str = str.toUpperCase()
  return str
}

function getOrderStrBycCamelName(name, isASC) {
  const str = getStrBycCamelName(name)
  return getOrderStrByName(str, isASC)
}

function removeSortKey(key, sorts) {
  let sortEnum = getOrderStrByName(key, true)
  let pos = sorts.indexOf(sortEnum)
  if (pos < 0) {
    sortEnum = getOrderStrByName(key, false)
    pos = sorts.indexOf(sortEnum)
  }
  if (pos >= 0) {
    sorts.splice(pos, 1)
  }
}

function Fields(propField, strField) {
  if (strField) {
    const items = strField.split(/\n/)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
        .replace(/(\s)*#[^\n]*$/, '')
        .trim()
        .split(/(\s)+/)
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < item.length; j++) {
        if (item[j].length > 0) {
          propField[item[j]] = true
        }
      }
    }
  }
  console.log(propField)
  return propField
}

function InlineFragment(fields, ownerType) {
  return {
    nineType: 'inline-fragment',
    ownerType,
    fields,
  }
}

function renamed(name, node) {
  const renamedObj = {
    type: node.type,
    nineType: node.nineType,
    filters: { ...node.filters },
    fields: { ...node.fields },
    fragmentName: node.fragmentName,
    isArray: node.isArray === true,
    isNodeConnection: node.isNodeConnection === true,
    name,
  }

  if (node.extendUI != null) {
    renamedObj.extendUI = node.extendUI
  }

  return renamedObj
}

function Field(enabled, type, uiProps, metaProps) {
  return {
    enabled,
    nineType: 'field',
    type,
    uiProps,
    metaProps,
  }
}

function extendField(field, uiProps, metaProps) {
  const extField = {
    enabled: field.enabled,
    nineType: field.nineType,
    type: field.type,
  }
  extField.uiProps = { ...field.uiProps, ...uiProps }
  extField.metaProps = { ...field.metaProps, ...metaProps }

  return extField
}

// isFragment : true 인 경우에만 name을 사용.
function Node(fields, type, isFragment, filters, isArray, fragmentName) {
  const fragmentN = fragmentName || type
  const ret = {
    nineType: isFragment === true ? 'fragment' : 'node',
    type,
    fields,
    filters,
    fragmentName: fragmentN,
    isArray: isArray === true,
  }

  ret.extendUI = (uiProps) => {
    ret.uiProps = uiProps
    return ret
  }

  return ret
}

function NodeExt(parent, fields, fragmentName) {
  const fragmentN = fragmentName || parent.fragmentName
  return {
    nineType: parent.nineType,
    type: parent.type,
    fields: { ...parent.fields, ...fields },
    filters: parent.filters,
    fragmentName: fragmentN,
    isArray: parent.isArray,
  }
}

function Query(name, fields) {
  const ret = {
    name,
    nineType: 'query',
    fields,
  }
  return ret
}

function Mutation(name, fields) {
  const ret = {
    name,
    nineType: 'mutation',
    fields,
  }
  return ret
}

function Subscription(name, fields) {
  const ret = {
    name,
    nineType: 'subscription',
    fields,
  }
  return ret
}

const PageInfo = Node(
  {
    hasNextPage: true,
    hasPreviousPage: true,
    startCursor: true,
    endCursor: true,
  },
  'PageInfo',
  true,
)

function Edge(nodeType, node, nodeName) {
  const type = nodeType ? `${nodeType}Edge` : null
  const isFragment = nodeType != null
  const nodeN = nodeName || 'node'
  const fragmentN = type

  const fields = {
    cursor: true,
  }
  fields[nodeN] = node
  // node.name = 'node'

  // Edge 만
  return renamed('edges', Node(fields, type, isFragment, null, true, fragmentN))
}

function NodeConnection(node, filters, isFragment, fragmentName, edgeName, nodeName) {
  const edgeN = edgeName || 'edges'
  const type = node != null && node.type != null ? `${node.type}Connection` : ''

  const fragmentN = fragmentName || type

  const ret = {
    type,
    nineType: isFragment === true ? 'fragment' : 'node',
    filters,
    fields: {
      totalCount: true,
      edgeCount: true,
    },
    fragmentName: fragmentN,
    isNodeConnection: true,
  }

  ret.extendUI = (uiProps) => {
    ret.uiProps = uiProps
    return ret
  }

  if (node != null) {
    ret.fields.pageInfo = PageInfo
    ret.fields[edgeN] = Edge(node.type, node, nodeName)
  }
  return ret
}

// totalCount, edgeCount가 없는 NodeConnection에 사용.
function NodeConnection2(node, filters, isFragment, fragmentName, edgeName, nodeName) {
  const edgeN = edgeName || 'edges'
  const type = node ? `${node.type}Connection` : ''

  const fragmentN = fragmentName || type

  const ret = {
    type,
    nineType: isFragment === true ? 'fragment' : 'node',
    filters,
    fields: {},
    fragmentName: fragmentN,
    isNodeConnection: true,
  }

  if (node != null) {
    ret.fields.pageInfo = PageInfo
    ret.fields[edgeN] = Edge(node.type, node, nodeName)
  }
  return ret
}

function QueryItem(node, queryName) {
  return {
    type: node.type,
    nineType: node.nineType,
    filters: { ...node.filters },
    fields: { ...node.fields },
    fragmentName: node.fragmentName,
    isArray: node.isArray,
    name: node.name,
    queryName,
  }
}

function Var(name, type) {
  return {
    nineType: 'var',
    name,
    type: type || 'String',
  }
}

// recursive check로 바꾸자.
function equalsProp(x, y) {
  if (x === y) return true

  if (!(x instanceof Object) || !(y instanceof Object)) return false

  if (Object.keys(x).some((key) => y[key] === undefined) === true) return false
  if (Object.keys(y).some((key) => x[key] === undefined) === true) return false

  const ret = Object.keys(x).some((key) => {
    if (
      x[key] instanceof Object &&
      y[key] instanceof Object &&
      equalsProp(x[key], y[key]) === false
    ) {
      return true
    }
    return false
  })

  return ret === false
}

function objToString(obj, isSORT) {
  let str = ''

  if (isSORT === true) {
    return obj
  }

  const type = typeof obj
  if (type === 'object') {
    let objStr = ''
    Object.entries(obj).forEach(([key, value]) => {
      if (objStr.length > 0) {
        objStr += ', '
      }
      objStr += `${key}:${objToString(value)}`
    })
    if (objStr.length > 0) {
      objStr = ` ${objStr} `
    }
    str += `{${objStr}}`
  } else if (type === 'string') {
    str = `"${obj}"`
  } else {
    str = obj
  }
  return str
}

const INDENT_STR = '  '

function getGQLString(nodeName, obj, parentIndent, owner) {
  let str = ''
  let fieldStr = ''
  let indent = parentIndent

  if (obj.filters) {
    Object.entries(obj.filters).forEach(([key, value]) => {
      if (value.nineType === 'var') {
        if (owner.variableMap[key] == null) {
          owner.variableMap[key] = value
        } else {
          console.log('variable duplicated - ', key)
        }
      }
      const valueStr = value.nineType === 'var' ? `$${key}` : value
      const varKey = value.name != null ? value.name : key
      fieldStr += fieldStr.length > 0 ? ', ' : ''
      if (value.type != null && value.type.toLowerCase().indexOf('string') < 0) {
        fieldStr += `${varKey}: ${valueStr}`
      } else if (typeof value !== 'string') {
        if (value.nineType === 'var') {
          fieldStr += `${varKey}: ${valueStr}`
        } else {
          const isSORT = key === 'sort'
          const objStr = objToString(valueStr, isSORT)
          fieldStr += `${varKey}: ${objStr}`
        }
      } else {
        let fValue = `"${valueStr}"`
        if (key === 'sort') {
          fValue = valueStr
        }
        fieldStr += `${varKey}: ${fValue}`
      }
    })
    if (fieldStr.length > 0) {
      fieldStr = `(${fieldStr})`
    }
  }
  if (nodeName) {
    if (obj.nineType === 'inline-fragment') {
      // inline fragment 에는 아무 이름이나 넣어놓으면 된다. ( query가 생성될 때는 이름은 무시됨. )
      str += `${parentIndent}... on $( obj.ownerType } {\n`
    } else if (obj.queryName != null && nodeName !== obj.queryName) {
      str += `${parentIndent + nodeName}: ${obj.queryName + fieldStr} {\n`
    } else if (obj.name != null && nodeName !== obj.name) {
      str += `${parentIndent + nodeName}: ${obj.name + fieldStr} {\n`
    } else {
      str += `${parentIndent + nodeName + fieldStr} {\n`
    }
    indent = parentIndent + INDENT_STR
  }

  if (obj.nineType === 'fragment' && nodeName) {
    let fragmentName = obj.fragmentName || obj.type

    // console.log(' ==== ', fragmentName, owner.fragmentMap[fragmentName])
    // console.log(' ====1 ', owner.fragmentNameTable, obj)

    if (owner.fragmentMap[fragmentName] != null) {
      if (equalsProp(obj, owner.fragmentMap[fragmentName]) === false) {
        if (owner.fragmentNameTable[fragmentName] == null) {
          owner.fragmentNameTable[fragmentName] = 0
        }
        owner.fragmentNameTable[fragmentName] += 1

        fragmentName += owner.fragmentNameTable[fragmentName]
        obj.fragmentName = fragmentName
      }
    }

    if (owner.fragmentMap[fragmentName] == null) {
      owner.fragments.push(obj)
      owner.fragmentMap[fragmentName] = obj
    }

    str += `${indent}...${fragmentName}\n`
  } else {
    // console.log( ' --- ', nodeName, obj);
    Object.entries(obj.fields).forEach(([key, value]) => {
      if (value != null) {
        if (value.nineType === 'field') {
          if (value.enabled !== false) {
            if (value.type == null) {
              str += `${indent + key}\n`
            } else if (typeof value.type === 'string') {
              str += `${indent + key}: ${value.type}\n`
            } else {
              str += getGQLString(key, value.type, indent, owner)
            }
          }
        } else if (value !== false) {
          if (value === true) {
            str += `${indent + key}\n`
          } else if (typeof value === 'string') {
            str += `${indent + key}: ${value}\n`
          } else {
            str += getGQLString(key, value, indent, owner)
          }
        }
      }
    })
  }
  if (nodeName) {
    str += `${parentIndent}}\n`
  }
  return str
}

function generateGQLString(obj) {
  if (obj == null) {
    throw new Error('GQL must not be null.')
  }
  console.log(obj)

  let str = ''
  const indent = INDENT_STR
  if (obj.nineType === 'query' || obj.nineType === 'mutation' || obj.nineType === 'subscription') {
    obj.fragments = []
    obj.fragmentMap = {}
    obj.variableMap = {}
    obj.fragmentNameTable = {}

    Object.entries(obj.fields).forEach(([key, value]) => {
      if (value !== null) {
        if (value.nineType === 'field' && value.enabled !== false) {
          if (value.type == null) {
            str += `${indent + key}\n`
          } else if (typeof value.type === 'string') {
            str += `${indent + key}: ${value.type}\n`
          } else {
            str += getGQLString(key, value.type, indent, obj)
          }
        } else if (value !== false) {
          if (value === true) {
            str += `${indent + key}\n`
          } else if (typeof value === 'string') {
            str += `${indent + key}: ${value}\n`
          } else {
            str += getGQLString(key, value, indent, obj)
          }
        }
      }
    })

    str += `}\n`

    let idx = 0
    while (idx < obj.fragments.length) {
      // console.log(obj.fragments.length, obj.fragments)
      const elem = obj.fragments[idx]
      str += `\n`
      str += `fragment ${elem.fragmentName || elem.type} on ${elem.type} {\n`
      str += getGQLString(null, elem, indent, obj)
      str += `}\n`
      idx += 1
    }

    let varStr = ''
    Object.entries(obj.variableMap).forEach(([key, varObj]) => {
      varStr += varStr.length > 0 ? ', ' : ''
      varStr += `$${key}: ${varObj.type}`
    })
    if (varStr.length > 0) {
      varStr = `(${varStr})`
    }

    const operationName = obj.name ? `${obj.nineType} ${obj.name + varStr}` : null

    str = str.replace(/{\s*}/g, '')

    if (operationName !== null) {
      str = `${operationName} {\n${str}`
    } else {
      str = `${obj.nineType} {\n${str}`
    }
  }
  return str
}

function generateGQL(obj) {
  const str = generateGQLString(obj)
  console.log(' -- gql str - ', str)
  return gql(str)
}

function getNodeData(res, key, arrayIFace) {
  const resData = res[key]
  if (resData != null && typeof resData === 'object') {
    let data = null
    // type 비교를 위해 edgeCount와 totalCount는 query에 함께 포함되도록 할 것.
    if (arrayIFace != null) {
      data = {
        name: key,
        nodes: arrayIFace.getNodes(resData),
        totalCount: 0,
        isEdges: false,
        type: 'iface',
      }

      if (arrayIFace.getTotalCount != null) {
        data.totalCount = arrayIFace.getTotalCount(resData)
      }

      if (arrayIFace.getMoreCursor) {
        data.hasNext = arrayIFace.getMoreCursor(resData, null) != null
      }
    } else if (resData.edges != null || (resData.edgeCount != null && resData.totalCount != null)) {
      data = {
        name: key,
        nodes: resData.edges,
        totalCount: resData.totalCount,
        isEdges: true,
        type: 'edges',
      }

      if (resData.pageInfo) {
        data.hasNext = resData.pageInfo.hasNextPage
      }
    } else if (Array.isArray(resData) === true) {
      data = {
        name: key,
        nodes: resData.map((item) => {
          return { node: item }
        }),
        totalCount: resData.length,
        isEdges: false,
        type: 'array',
      }
    } else {
      data = {
        name: key,
        nodes: [{ node: resData }],
        totalCount: 1,
        isEdges: false,
        type: 'node',
      }
    }

    return data
  }
  return null
}

// function initTableColumeCSS(reactObj, tableInfo) {
//   if (tableInfo.useMobileMode === true && reactObj.onLanguageChangedInNGQLTableColumn == null) {
//     reactObj.onLanguageChangedInNGQLTableColumn = () => {
//       reactObj.forceUpdate()
//     }

//     i18n.on('languageChanged', reactObj.onLanguageChangedInNGQLTableColumn)

//     reactObj.mobileModeOff = () => {
//       if (reactObj.onLanguageChangedInNGQLTableColumn != null) {
//         i18n.off('languageChanged', reactObj.onLanguageChangedInNGQLTableColumn)
//         delete reactObj.onLanguageChangedInNGQLTableColumn
//       }
//     }
//   }

//   if (reactObj.mobileModeOff == null) {
//     reactObj.mobileModeOff = () => {}
//   }
// }

function ResultObj(queryObj, gqlopt, reactObj) {
  if (ngqlUIInfo == null) {
    ngqlUIInfo = getNgqlUIInfo()
    ngqlUIInfo.generateDetailUIByKey = generateDetailUIByKey
  }

  this.queryObj = queryObj
  this.gqlopt = gqlopt || {}
  this.reactObj = reactObj

  extendsReactObj(this.reactObj, this)

  this.uiInfo = {}

  if (this.gqlopt.uiInfo != null) {
    this.uiInfo = {
      ...this.gqlopt.uiInfo,
    }
  }

  if (this.reactObj != null) {
    this.setUIObj({
      dataList: [],
      resData: {},
    })
  }

  this.nodeList = []

  const resObj = this

  Object.keys(queryObj.fields).forEach((key) => {
    if (queryObj != null && queryObj.fields[key] != null && queryObj.fields[key].fields != null) {
      const { fields, isArray } = queryObj.fields[key]
      if (fields.edges != null) {
        const { node } = fields.edges.fields
        this.nodeList.push(node)
      } else if (isArray === true) {
        this.nodeList.push(queryObj.fields[key])
      } else {
        this.nodeList.push(queryObj.fields[key])
      }
    }
  })

  this.setUIInfo = (options) => {
    if (options.loadingStateName != null) {
      this.setLoadingName(options.loadingStateName)
    }

    if (options.orders != null) {
      this.uiInfo.orders = options.orders

      this.uiInfo.orders.forEach((key) => {
        if (
          queryObj != null &&
          queryObj.fields[key] != null &&
          queryObj.fields[key].fields != null
        ) {
          const { fields, isArray } = queryObj.fields[key]
          if (fields.edges != null) {
            const { node } = fields.edges.fields
            this.nodeList.push(node)
          } else if (isArray === true) {
            this.nodeList.push(fields)
          } else {
            this.nodeList.push(fields)
          }
        }
      })
    }
  }

  this.mappingResponse = (resParent) => {
    // log.info(' --- ', resParent)

    if (this.needDataClear !== false) {
      this.setUIObj(
        {
          dataList: [],
          resData: {},
        },
        false,
      )
    }

    const { dataList, resData } = this.getUIObj()

    if (this.uiInfo.orders == null) {
      Object.keys(resParent).forEach((key) => {
        const data = getNodeData(resParent, key, this.arrayIFace)

        if (data != null) {
          if (resData[key] == null || this.needDataClear !== false) {
            dataList.push(data)
            resData[key] = data
          } else {
            resData[key].nodes = resData[key].nodes.concat(data.nodes)
            resData[key].totalCount = data.totalCount
            resData[key].hasNext = data.hasNext
          }
        }
      })
    } else {
      this.uiInfo.orders.forEach((key) => {
        const data = getNodeData(resParent, key, this.arrayIFace)

        if (data != null) {
          if (resData[key] == null || this.needDataClear !== false) {
            dataList.push(data)
            resData[key] = data
          } else {
            resData[key].nodes = resData[key].nodes.concat(data.nodes)
            resData[key].totalCount = data.totalCount
            resData[key].hasNext = data.hasNext
          }
        }
      })
    }

    this.setUIObj({
      dataList,
      resData,
    })
  }

  const getSubValue = (key, value) => {
    let data = null

    Object.keys(value).some((subKey) => {
      if (subKey === 'ok' || subKey === 'errors') {
        return false
      }
      const subValue = value[subKey]
      data = {
        name: key,
        isEdges: true,
        type: 'edges',
        // isEdges: false,
        // type: 'nodes'
      }

      if (Array.isArray(subValue) === true) {
        data.nodes = subValue
      } else {
        data.nodes = [subValue]
      }

      // edges인 것처럼 만들어 줌.
      data.nodes = data.nodes.map((item) => {
        return {
          node: item,
          cursor: null,
        }
      })
      data.totalCount = data.nodes.length
      return true
    })

    return data
  }

  this.mappingMutationResponse = (resParent) => {
    // log.info(' --- ', resParent)

    this.setUIObj(
      {
        dataList: [],
        resData: {},
      },
      false,
    )

    const { dataList, resData } = this.getUIObj()

    if (this.uiInfo.orders == null) {
      Object.keys(resParent).forEach((key) => {
        const data = getSubValue(key, resParent[key])

        if (data != null) {
          // eslint-disable-next-line no-unused-expressions
          dataList.push(data)
          resData[key] = data
        }
      })
    } else {
      this.uiInfo.orders.forEach((key) => {
        const data = getSubValue(key, resParent[key])

        if (data != null) {
          // eslint-disable-next-line no-unused-expressions
          dataList.push(data)
          resData[key] = data
        }
      })
    }

    this.setUIObj({
      dataList,
      resData,
    })
  }

  this.getResult = () => {
    const { dataList } = this.getUIObj()
    return dataList
  }

  this.getResultMap = () => {
    const { resData } = this.getUIObj()
    return resData
  }

  this.getResultData = (key) => {
    const { dataList, resData } = this.getUIObj()
    if (key != null) {
      return resData[key]
    }
    return dataList[0]
  }

  this.getResultNodes = (key) => {
    const { dataList, resData } = this.getUIObj()
    if (key != null) {
      return resData[key].nodes
    }
    return dataList[0].nodes
  }

  this.clear = (key) => {
    const { dataList, resData } = this.getUIObj()
    if (key != null) {
      if (resData[key] != null) {
        resData[key].nodes = []
        resData[key].totalCount = 0
      }
    } else if (dataList[0] != null) {
      dataList[0].nodes = []
      dataList[0].totalCount = 0
    }

    resObj.setAllDataList(dataList, false)
  }

  this.getResultNode = (key) => {
    const { dataList, resData } = this.getUIObj()
    if (dataList.length > 0) {
      const nodes = key != null ? resData[key].nodes : dataList[0].nodes
      if (nodes.length > 0) {
        return nodes[0].node
      }
    }
    return {}
  }

  const createUICommonFunc = (ret, data, selfObj, isEdit) => {
    ret.getAllElem = (uiAttrs) => {
      return ngqlUIInfo.createAllElem(ret, uiAttrs)
    }

    ret.getTableFieldNodes = (key) => {
      if (key == null) {
        return null
      }

      const nodeData = ret.nodeInfo.map[key]
      if (nodeData == null || nodeData.isTable !== true) {
        return null
      }

      const uiObj = nodeData.node.nodeInfo.map.edges.node.nodeInfo.map.node.node

      return uiObj.getNodes()
    }

    ret.getTableFieldElem = (key, uiAttrs, btnsUI) => {
      if (key == null) {
        return null
      }

      const nodeData = ret.nodeInfo.map[key]
      if (nodeData == null || nodeData.isTable !== true) {
        return null
      }

      const uiObj = nodeData.node.nodeInfo.map.edges.node.nodeInfo.map.node.node

      const formRef = ret.getFormRef(key)

      if (isEdit === true) {
        return ngqlUIInfo.createEditContainer(
          uiObj,
          ret.nodeInfo.options,
          nodeData.uiProps,
          uiAttrs,
          formRef,
          btnsUI,
        )
      }
      return ngqlUIInfo.createDetailContainer(
        uiObj,
        ret.nodeInfo.options,
        nodeData.uiProps,
        uiAttrs,
      )
    }

    ret.getTableObj = (key, tableOpts) => {
      if (key == null) {
        return null
      }

      if (ret.nodeInfo.memTableMap[key] == null) {
        const nodeInfo = ret.nodeInfo.map[key]
        if (nodeInfo == null || nodeInfo.isTable !== true) {
          return null
        }

        ret.nodeInfo.memTableMap[key] = getMemTableObj([], tableOpts, selfObj.reactObj)
      }

      if (data != null && data[key] != null) {
        ret.nodeInfo.memTableMap[key].setData(data[key].edges)
      }

      return ret.nodeInfo.memTableMap[key]
    }
  }

  this.getDetailUI = (options) => {
    if (this.nodeList == null || this.nodeList.length === 0) {
      throw new Error('Field must not be empty.')
    }

    const opts = options || {}

    const { dataList } = this.getUIObj()

    const data =
      dataList != null && dataList.length > 0 && dataList[0].nodes.length > 0
        ? dataList[0].nodes[0].node
        : null
    const node = this.nodeList[0]

    if (node.uiProps != null) {
      opts.uiNodeProps = node.uiProps
    }

    this.loadingStateInfo.enabled = false

    const ret = generateDetailUI(node.fields, opts, data, this.reactObj)

    resObj.detailUI = ret

    ret.name = resObj.name

    ret.getChildNodes = (key) => {
      const innerNode = key != null ? ret.nodeInfo.map[key].node : ret
      return innerNode.getNodes()
    }

    ret.getElem = (key, uiAttrs) => {
      const innerNode = key != null ? ret.nodeInfo.map[key].node : ret
      const qNode = key != null ? node.fields[key] : node
      const { uiProps } = qNode

      if (Object.keys(innerNode.compList).length === 0) {
        return null
      }

      return ngqlUIInfo.createDetailContainer(innerNode, options, uiProps, uiAttrs)
    }

    createUICommonFunc(ret, data, this, false)

    return ret
  }

  this.getEditUI = (options) => {
    if (this.nodeList == null || this.nodeList.length === 0) {
      throw new Error('Field must not be empty.')
    }

    const opts = options || {}

    const { dataList } = this.getUIObj()

    const data = dataList != null && dataList.length > 0 ? dataList[0].nodes[0].node : null
    const node = this.nodeList[0]

    if (node.uiProps != null) {
      opts.uiNodeProps = node.uiProps
    }

    this.loadingStateInfo.enabled = false

    const ret = generateEditUI(node.fields, opts, data, this.reactObj)

    resObj.editUI = ret

    ret.name = resObj.name

    resObj.validate = async (key) => {
      if (key == null || key === ret.name) {
        await ret.validateForm()
      } else {
        await ret.validateSubForm(key)
      }
    }

    resObj.validateAll = async () => {
      await ret.validateForm()

      if (ret.nodeInfo.list.length > 0) {
        Object.keys(ret.nodeInfo.map).forEach(async (key) => {
          if (ret.nodeInfo.map[key].isTable !== true) {
            await ret.validateSubForm(key)
          }
        })
      }
    }

    resObj.getFields = (key, exceptReadOnly) => {
      if (key == null || key === ret.name) {
        return ret.getFormFields(exceptReadOnly)
      }
      return ret.getSubFormFields(key, exceptReadOnly)
    }

    resObj.setFields = (values, key) => {
      if (key == null || key === ret.name) {
        return ret.setFormFields(values)
      }

      return ret.setSubFormFields(key, values)
    }

    ret.getTitle = (key) => {
      let label = ''
      let qNode = null
      if (key === null) {
        qNode = node
      } else {
        qNode = node.fields[key]
      }

      const { uiProps } = qNode

      if (uiProps != null) {
        label = options.t && uiProps.labelKey ? options.t(uiProps.labelKey) : uiProps.label
      }

      return label
    }

    ret.getFormRef = (key) => {
      const formKey = key == null ? resObj.name : key
      if (ret.refMap == null) {
        ret.refMap = {}
      }
      if (ret.refMap[formKey] == null) {
        ret.refMap[formKey] = React.createRef()
      }
      return ret.refMap[formKey]
    }

    ret.getChildNodes = (key) => {
      const innerNode = key != null ? ret.nodeInfo.map[key].node : ret
      return innerNode.getNodes()
    }

    ret.getElem = (key, uiAttrs, btnsUI) => {
      const innerNode = key != null ? ret.nodeInfo.map[key].node : ret
      const qNode = key != null ? node.fields[key] : node
      const { uiProps } = qNode

      const formRef = ret.getFormRef(key)

      return ngqlUIInfo.createEditContainer(innerNode, options, uiProps, uiAttrs, formRef, btnsUI)
    }

    createUICommonFunc(ret, data, this, true)

    return ret
  }
}

function getMappingRes(res, resultObj) {
  const resRoot = res.data

  resultObj.mappingResponse(resRoot)
  return {
    response: res,
    resultObj,
  }
}

function getMappingMutationRes(res, resultObj) {
  const resRoot = res.data

  resultObj.mappingMutationResponse(resRoot)
  return {
    response: res,
    resultObj,
  }
}

function GQLObj(queryObj, gqlopt, reactObj) {
  const queryText = generateGQLString(queryObj)
  console.log(' --- ', queryText)
  const resObj = new ResultObj(queryObj, gqlopt, reactObj)
  console.log(' ResultObj : ', resObj)
  const gqlData = gql(queryText)
  console.log(' gql --- ', gqlData)

  const useUI = gqlopt == null || gqlopt.useUI !== false

  let variables = gqlopt != null && gqlopt.vars != null ? gqlopt.vars : {}

  let fixedVariables = gqlopt != null && gqlopt.fixedVars != null ? gqlopt.fixedVars : {}

  resObj.setVariables = (vars1) => {
    variables = vars1
  }

  resObj.getVariables = () => {
    return variables
  }

  resObj.setFixedVariables = (vars1) => {
    fixedVariables = vars1
  }

  resObj.addFixedVariables = (vars1) => {
    fixedVariables = {
      ...fixedVariables,
      ...vars1,
    }
  }

  resObj.getFixedVariables = () => {
    return fixedVariables
  }

  resObj.setSearchItems = (items) => {
    resObj.setUIObj({
      searchItems: items,
    })
  }

  resObj.getSearchItems = () => {
    const { searchItems } = resObj.getUIObj()
    return searchItems
  }

  // 초기화.
  if (gqlopt != null && gqlopt.searchItems != null) {
    resObj.setSearchItems(gqlopt.searchItems)
  }

  let searchFilters = {}

  resObj.getSearchFilters = () => {
    return searchFilters
  }

  resObj.mobileMode = false

  resObj.setMobileMode = (isMobileView) => {
    if (resObj.mobileMode !== isMobileView) {
      resObj.mobileMode = isMobileView
      const defaultV = resObj.getDefaultVariable()
      if (defaultV != null) {
        variables = {
          ...variables,
          ...defaultV,
        }
      }
    }
  }

  resObj.getDefaultVariable = () => {
    let defaultVariables = null
    if (resObj.tableInfo != null) {
      if (resObj.mobileMode === true && resObj.tableInfo.mobileInfo != null) {
        // eslint-disable-next-line prefer-destructuring
        defaultVariables = resObj.tableInfo.mobileInfo.defaultVariables
      } else {
        // eslint-disable-next-line prefer-destructuring
        defaultVariables = resObj.tableInfo.defaultVariables
      }
    }
    return defaultVariables || {}
  }

  resObj.getKeepFilters = () => {
    if (resObj.tableInfo != null && resObj.tableInfo.keepFilters != null) {
      return resObj.tableInfo.keepFilters
    }
    return null
  }

  function removeSortKeys(sorts, sortMap) {
    resObj.sortKeyList.forEach((key) => {
      removeSortKey(key, sorts)
      if (sortMap != null) {
        delete sortMap[key]
      }
    })
  }

  // let prevVariables = null;
  resObj.isParamChecked = false

  resObj.checkParams = () => {
    const pageCtrl = resObj.getPageControl()
    if (resObj.tableInfo != null && pageCtrl !== false) {
      if (resObj.isParamChecked === false) {
        let inited = true

        const defaultV = resObj.getDefaultVariable()
        if (defaultV != null) {
          variables = {
            ...variables,
            ...defaultV,
          }
        }

        const sparams = getSearchParamProps()

        if (resObj.pageParamName != null) {
          if (sparams[resObj.pageParamName] != null) {
            resObj.setArgument(variables, 'pageNum', sparams[resObj.pageParamName])
          }
        }
        if (sparams.nfilter != null) {
          if (resObj.filterMap != null) {
            const nfilter = {}
            Object.entries(sparams.nfilter).forEach(([key, value]) => {
              const idxList = value
              const arr = []
              idxList.forEach((idx) => {
                const dataList = resObj.filterMap[key]
                arr.push(dataList[idx])
              })
              nfilter[key] = arr
            })
            resObj.tableInfo.defaultFilters = nfilter
            let filters = {}
            if (variables.filters != null) {
              filters = {
                ...variables.filters,
              }

              resObj.filterKeyList.forEach((key) => {
                const pos = key.lastIndexOf('.')
                const filterKey = key.substring(pos + 1)
                setInFilter(filters, filterKey, null)
              })
            }
            Object.entries(nfilter).forEach(([key, value]) => {
              const pos = key.lastIndexOf('.')
              const filterKey = key.substring(pos + 1)
              setInFilter(filters, filterKey, value)
            })
            if (Object.keys(filters).length > 0) {
              variables.filters = filters
            } else {
              delete variables.filters
            }
          } else {
            inited = false
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (resObj.tableInfo.defaultFilters != null) {
            delete resObj.tableInfo.defaultFilters
          }
        }

        if (sparams.nsort != null) {
          // const nfilterStr = decodeURIComponent(sparams.nfilter)
          // const nfilter = JSON.parse(nfilterStr)
          if (resObj.sortKeyList != null) {
            const nsort = {}
            Object.entries(sparams.nsort).forEach(([key, value]) => {
              nsort[key] = value
            })

            resObj.tableInfo.defaultSorts = nsort
            let sorts = []
            if (variables.sort != null) {
              sorts = [...variables.sort]

              removeSortKeys(sorts, resObj.sortMap)
            }

            Object.entries(nsort).forEach(([key, value]) => {
              const sortEnum = getOrderStrByName(key, value)
              sorts.push(sortEnum)
            })

            if (sorts.length > 0) {
              variables.sort = sorts
            } else {
              delete variables.sort
            }
          } else {
            inited = false
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (resObj.tableInfo.defaultSorts != null) {
            delete resObj.tableInfo.defaultSorts
          }
        }

        const filters = {
          ...variables.filters,
        }

        const searchItems = resObj.getSearchItems()

        if (searchItems != null) {
          Object.keys(searchItems).forEach((key) => {
            setFilter(filters, key, undefined) // 기존값을 제거.
            delete searchFilters[key]
          })
        }

        if (sparams.nsearch != null) {
          const { nsearch } = sparams

          Object.entries(nsearch).forEach(([key, value]) => {
            if (searchItems == null || searchItems[key] !== undefined) {
              setFilter(filters, key, value, false, resObj.searchPreset)
              searchFilters[key] = value
            }
          })
        }

        if (Object.keys(filters).length > 0) {
          variables.filters = filters
        } else {
          delete variables.filters
        }

        // prevVariables = {
        //   ...variables
        // };

        if (inited === true) {
          resObj.isParamChecked = true
        }
      }
      // else if (prevVariables != null) {
      //   variables = {
      //     ...prevVariables
      //   }

      //   Object.entries(defaultV).forEach(([key, value]) => {
      //     if (variables[key] == null) {
      //       variables[key] = value;
      //     }
      //   });
      // }
    }
    return pageCtrl
  }

  resObj.getPaginationInfo = () => {
    const pageCtrl = resObj.checkParams()

    const { dataList } = resObj.getUIObj()

    const pagination = {
      current: Math.max(1, resObj.getArgument(variables, 'pageNum') || 1),
      pageSize: resObj.getArgument(variables, 'pageSize') || 5,
      onChange: (page) => {
        resObj.movePage(page)
      },
      total: dataList.length > 0 ? dataList[0].totalCount : 0,
      showSizeChanger: false,
      hideOnSinglePage: pageCtrl != null ? pageCtrl.hideOnSinglePage : false,
    }
    return pagination
  }

  resObj.checkPageReplace = (props, prevProps) => {
    if (props.location != null && prevProps.location != null) {
      if (checkPageReplace(props.location, prevProps.location) === false) {
        console.log(' ----  checkPageReplace ', props, prevProps)

        resObj.checkParams()
        if (resObj.refetch != null) {
          resObj.refetch()
        } else {
          resObj.watch()
        }
        // resObj.reactObj.forceUpdate()
      }
    }
  }

  resObj.resetSearch = (skipRefetch, extItem) => {
    const searchItems = resObj.getSearchItems()

    if (searchItems != null) {
      const filters = {
        ...variables.filters,
      }

      Object.keys(searchItems).forEach((key) => {
        setFilter(filters, key, undefined) // 기존값을 제거.
      })

      if (extItem != null && extItem.filter != null) {
        setFilter(filters, null, extItem, true) // 기존값을 제거.
      }

      if (Object.keys(filters).length > 0) {
        variables.filters = filters
      } else {
        delete variables.filters
      }
    }

    searchFilters = {}

    if (skipRefetch !== true) {
      if (resObj.refetch != null) {
        resObj.refetch()
      } else {
        resObj.watch()
      }
    }
  }

  resObj.search = (key, value, searchPreset, skipRefetch, useParam) => {
    // console.log('process search')
    resObj.searchPreset = searchPreset

    const filters = {
      ...variables.filters,
    }

    const searchItems = resObj.getSearchItems()

    if (searchItems == null || searchItems[key] !== undefined) {
      setFilter(filters, key, value, false, resObj.searchPreset)
      if (value !== undefined) {
        searchFilters[key] = value
      } else {
        delete searchFilters[key]
      }
    }

    if (useParam === true) {
      const params = {}
      if (Object.keys(searchFilters).length > 0) {
        params.nsearch = encodeURIComponent(encodeNSearch(searchFilters))
      } else {
        params.nsearch = undefined
      }
      setSearchParams(params)
    }

    if (Object.keys(filters).length > 0) {
      variables.filters = filters
    } else {
      delete variables.filters
    }

    if (skipRefetch !== true) {
      if (resObj.movePage != null) {
        resObj.movePage(1)
      } else {
        resObj.watch()
      }
    }
  }

  function processSorter(sortList, sorter, clearPrev, sortMap) {
    const orderKey = sorter.columnKey != null ? sorter.columnKey : null
    const fieldKey = sorter.field != null ? sorter.field[1] : null
    let ascEnum = null
    let desEnum = null
    const sortKey = orderKey || getStrBycCamelName(fieldKey)

    if (clearPrev === true) {
      // 기존의 sort 정보를 제거.
      if (orderKey == null) {
        ascEnum = getOrderStrBycCamelName(fieldKey, true)
        desEnum = getOrderStrBycCamelName(fieldKey, false)
      } else {
        ascEnum = getOrderStrByName(orderKey, true)
        desEnum = getOrderStrByName(orderKey, false)
      }

      let pos = sortList.indexOf(ascEnum)
      if (pos >= 0) {
        sortList.splice(pos, 1)
      }
      pos = sortList.indexOf(desEnum)
      if (pos >= 0) {
        sortList.splice(pos, 1)
      }

      delete sortMap[sortKey]
    }

    if (sorter.order != null) {
      let sortEnum = null

      if (orderKey == null) {
        sortEnum = getOrderStrBycCamelName(fieldKey, sorter.order !== 'descend')
      } else {
        sortEnum = getOrderStrByName(orderKey, sorter.order !== 'descend')
      }
      sortMap[sortKey] = sorter.order !== 'descend'

      console.log('sort enum : ', sortEnum)
      sortList.unshift(sortEnum)
    }
  }

  resObj.getOnChange = () => {
    return (pagination, filters, sorter, extra) => {
      if (resObj.reactObj != null) {
        const onChangeFunc = resObj.reactObj[`onChange${resObj.name}`]
        if (onChangeFunc != null) {
          if (onChangeFunc(pagination, filters, sorter, extra, resObj) === true) {
            return
          }
        }
      }
      if (resObj.tableInfo.onChange != null) {
        if (resObj.tableInfo.onChange(pagination, filters, sorter, extra, resObj) === true) {
          return
        }
      }

      const vars = resObj.getVariables()

      // console.log('params', pagination, filters, sorter, extra)
      if (extra.action === 'filter') {
        // console.log('onChangeFilter - ', filters)

        if (resObj.filterMap != null) {
          if (vars.filters == null) {
            vars.filters = {}
          }

          const filterStr = encodeFilterMap(resObj.filterMap, vars, filters)

          const param = {
            nfilter: encodeURIComponent(filterStr),
            // nfilter: encodeURIComponent(JSON.stringify(filters)),
          }

          setSearchParams(param)

          resObj.setVariables(vars)

          if (resObj.movePage != null) {
            resObj.movePage(1)
          } else {
            resObj.watch()
          }
        }
      } else if (extra.action === 'sort') {
        if (resObj.sortMap == null) {
          resObj.sortMap = {}
        }

        let sortList = []
        const { sortMap } = resObj

        // if (sorter.order == null) {
        //   delete (resObj.sortMap[orderKey]);
        // } else {
        //   resObj.sortMap[orderKey] = Ngql.getOrderStrByName(orderKey, sorter.order !== 'descend')
        // }

        // Object.entries(resObj.sortMap).forEach((values) => {
        //   sortList.push(values[1]);
        // });

        if (Array.isArray(sorter) === true) {
          sorter.forEach((item) => {
            processSorter(sortList, item, false, sortMap)
          })
        } else if (
          sorter.column != null &&
          sorter.column.sorter != null &&
          sorter.column.sorter.multiple != null
        ) {
          processSorter(sortList, sorter, false, sortMap)
        } else {
          if (vars.sort != null) {
            sortList = [...vars.sort]
            removeSortKeys(sortList, sortMap)
          }
          processSorter(sortList, sorter, true, sortMap)
        }

        resObj.tableInfo.defaultSorts = {
          ...sortMap,
        }

        const sortStr = encodeSortMap(sortMap)

        const param = {
          nsort: encodeURIComponent(sortStr),
          // nfilter: encodeURIComponent(JSON.stringify(filters)),
        }

        if (param.nsort.length === 0) {
          param.nsort = undefined
        }

        setSearchParams(param)

        if (sortList.length > 0) {
          vars.sort = sortList
        } else {
          delete vars.sort
        }
        resObj.setVariables(vars)

        if (resObj.refetch != null) {
          resObj.refetch()
        } else {
          resObj.watch()
        }
      } else if (extra.action === 'paginate') {
        resObj.movePage(pagination.current)
      }
    }
  }

  const getElemData = (options, elemInfo, itemFunc, retFunc) => {
    if (resObj.nodeList == null || resObj.nodeList.length === 0) {
      throw new Error('Field must not be empty.')
    }
    const node = resObj.nodeList[0]

    const { dataList } = resObj.getUIObj()

    let dataSource = dataList.length > 0 ? dataList[0].nodes : []
    if (options != null && options.getDataSource != null) {
      dataSource = options.getDataSource(dataSource)
    }

    const itemInfo = itemFunc(node, options, elemInfo, resObj)

    return retFunc(node, options, elemInfo, itemInfo, dataSource, resObj)
  }

  makeArrayElemFunc(resObj, getElemData)

  resObj.setAllDataList = (dataList, loading) => {
    if (resObj.editUI != null) {
      console.log('editUI -- ', resObj.reactObj, resObj.editUI)
      const formRef = resObj.editUI.getFormRef(resObj.name)
      const { nodes } = dataList[0]
      if (formRef.current != null) {
        if (nodes.length > 0) {
          formRef.current.setFieldsValue(resObj.editUI.initFields(nodes[0].node))
        }
      }
      const { nodeInfo } = resObj.editUI
      if (nodeInfo.list.length > 0) {
        Object.keys(nodeInfo.map).forEach((key) => {
          const childNode = nodeInfo.map[key]
          if (childNode.isTable !== true) {
            const subFormRef = resObj.editUI.getFormRef(key)
            if (subFormRef.current != null && nodes.length > 0) {
              subFormRef.current.setFieldsValue(childNode.node.initFields(nodes[0].node[key]))
            }
          }
        })
      }
      return
    }
    if (resObj.reactObj != null) {
      // if (dataList.length > 0) {
      //   state[resObj.name] = dataList[0].nodes
      // }
      // resObj.reactObj.setState(state)

      resObj.setLoading(loading, false)
      resObj.setUIObj({ dataList })
    }
  }

  const copyInnerValue = (target, props) => {
    Object.entries(props).forEach(([key, value]) => {
      if (typeof value === 'object') {
        if (target[key] == null) {
          target[key] = {}
        }
        copyInnerValue(target[key], value)
      } else {
        target[key] = value
      }
    })
  }

  // filter는 page이동 또는 parameter 변경 시 자동으로 삭제되는 속성이므로
  // 필요한 경우, fixedVaraible 또는 keepFilter를 이용하여 값을 유지시켜야 함.
  const getFinalVariables = (v) => {
    const ret = { ...v }
    copyInnerValue(ret, fixedVariables)
    return ret
  }

  resObj.watch = function query(cbFunc, prop, otherClient) {
    const options = prop != null ? prop : {}

    const targetClient = otherClient || client

    resObj.checkParams()

    const finalVars = getFinalVariables(variables)

    const observableQuery = targetClient.watchQuery({
      query: gql(queryText),
      variables: finalVars,
      fetchPolicy: 'network-only',
      ...options,
    })

    resObj.setLoading(true)

    const subscription = observableQuery.subscribe({
      next: (res) => {
        // log.info(' ---1 ', res);
        let { dataList } = resObj.getUIObj()

        if (useUI === true) {
          getMappingRes(res, resObj)

          const { dataList: newDataList } = resObj.getUIObj()
          dataList = newDataList

          if (cbFunc != null) {
            cbFunc(res, dataList)
          }

          const pageCtrl = resObj.getPageControl()

          if (resObj.tableInfo != null && dataList.length > 0 && pageCtrl !== false) {
            const defaultV = this.getDefaultVariable()
            const pageSize = resObj.getArgument(defaultV, 'pageSize', 5)

            const totalPage = getTotalPageCnt(dataList[0].totalCount, pageSize)
            if (totalPage > 0 && resObj.getArgument(defaultV, 'pageNum') > totalPage) {
              setTimeout(() => {
                resObj.movePage(totalPage)
              }, 10)
            }
          }
        } else if (cbFunc != null) {
          cbFunc(res)
        }

        if (useUI === true) {
          resObj.setAllDataList(dataList, res.loading)
        }
      },
      error: () => {
        resObj.setLoading(false)
      },
      complete: () => {
        resObj.setLoading(false)
      },
    })

    resObj.observableQuery = observableQuery
    resObj.subscription = subscription

    let prevVariables = null

    resObj.refetch = (vars) => {
      const v = getFinalVariables(vars || variables)

      const apolloVariables = resObj.observableQuery.variables
      if (prevVariables != null) {
        Object.entries(prevVariables).forEach(([key, value]) => {
          if (value != null && v[key] == null) {
            // v[key] = null;
            delete apolloVariables[key]
          }
        })
      }

      prevVariables = {
        ...v,
      }

      if (reactObj != null && resObj.uiInfo != null && resObj.uiInfo.loadingStateName != null) {
        const useLoadingState = resObj.isLoadingStateEnabled()

        let timer = null
        if (useLoadingState !== false && resObj.reactObj != null) {
          timer = setTimeout(() => {
            resObj.setLoading(true)
          }, 500)
        }
        resObj.observableQuery.refetch(v).finally(() => {
          clearTimeout(timer)
          resObj.setLoading(false)
        })
        return null
      }

      // return resObj.observableQuery.setVariables(v)
      // refetch를 사용하는 경우, variable의 이전값을 내부적으로 덮어쓰게 되므로
      // variable의 key값이 없어져야 하는 경우, 그 값이 그대로 유지되어버리는 문제가 발생할 수 있다.
      return resObj.observableQuery.refetch(v)
    }

    resObj.resetCache = () => {
      resObj.observableQuery.resetLastResults()
    }

    resObj.movePage = (page) => {
      resObj.needDataClear = true
      resObj.setArgument(variables, 'pageNum', page)
      if (variables.after != null) {
        delete variables.after
      }

      resObj.refetch(variables)

      const obj = {}
      if (resObj.pageParamName != null) {
        obj[resObj.pageParamName] = page
        setSearchParams(obj)
      }
    }

    resObj.morePage = (remainData) => {
      const { dataList } = resObj.getUIObj()
      resObj.needDataClear = remainData != null ? !remainData : false
      resObj.setArgument(variables, 'pageNum', 1)

      if (dataList.length > 0) {
        const curData = dataList[0]
        const { nodes } = curData
        const lastData = nodes[nodes.length - 1]
        if (resObj.arrayIFace != null) {
          const cursor = resObj.arrayIFace.getMoreCursor(null, lastData)
          resObj.setArgument(variables, 'after', cursor)
        } else if (lastData.cursor != null) {
          variables.after = lastData.cursor
        } else {
          throw new Error('cursor is required.')
        }
      }

      setSearchParams({}, false)

      resObj.refetch(variables)
    }

    resObj.resetParamCheck = () => {
      resObj.isParamChecked = false
      const defaultV = resObj.getDefaultVariable()
      const keepFilters = resObj.getKeepFilters()

      let hasKeepFilterData = false
      const filters = {}
      if (keepFilters != null && variables != null && variables.filters != null) {
        Object.keys(keepFilters).forEach((item) => {
          if (keepFilters[item] === true && variables.filters[item] !== undefined) {
            filters[item] = variables.filters[item]
            hasKeepFilterData = true
          }
        })
      }

      if (defaultV != null) {
        variables = {
          ...defaultV,
        }
      } else {
        variables = {}
      }

      if (hasKeepFilterData === true) {
        if (variables.filters != null) {
          variables.filters = {
            ...variables.filters,
            ...filters,
          }
        } else {
          variables.filters = filters
        }
      }

      if (resObj.checkParams != null) {
        resObj.checkParams()
      }
    }

    addParamChangeListener(resObj.resetParamCheck)

    resObj.close = () => {
      removeParamChangeListener(resObj.resetParamCheck)

      if (resObj.subscription != null) {
        resObj.subscription.unsubscribe()
      }

      if (reactObj.mobileModeOff != null) {
        reactObj.mobileModeOff()
      }

      if (resObj.tableInfo != null && resObj.tableInfo.defaultFilters != null) {
        delete resObj.tableInfo.defaultFilters
      }
    }

    return observableQuery
  }

  // resObj.watchQuery = function query(prop, otherClient) {
  //   const options = prop != null ? prop : {}

  //   const targetClient = otherClient || client

  //   const observableQuery = targetClient.watchQuery({
  //     query: gql(queryText),
  //     variables,
  //     fetchPolicy: 'network-only',
  //     ...options,
  //   })

  //   return observableQuery
  // }

  resObj.query = function query(prop, otherClient) {
    const options = prop != null ? prop : {}

    const promise = new Promise((resolve, reject) => {
      const targetClient = otherClient || client

      resObj.setLoading(true)

      const finalVars = getFinalVariables(variables)

      targetClient
        .query({
          query: gql(queryText),
          variables: finalVars,
          fetchPolicy: 'network-only',
          ...options,
        })
        .then((res) => {
          if (useUI === true) {
            getMappingRes(res, resObj)
            const { dataList } = resObj.getUIObj()
            resObj.setAllDataList(dataList, false)
          }
          resolve(res)
        })
        .catch((error) => {
          resObj.setLoading(false)
          console.log(' ---- ', error.extensions, error.message)
          // if (error.graphQLErrors[0].message !== 'Signature has expired') {
          // }
          reject(error)
        })
    })
    console.log(' --11- ', promise)
    return promise
  }

  resObj.mutate = function mutate(prop, otherClient) {
    const options = prop != null ? prop : {}

    const promise = new Promise((resolve, reject) => {
      const targetClient = otherClient || client

      const finalVars = getFinalVariables(variables)

      targetClient
        .mutate({
          mutation: gql(queryText),
          variables: finalVars,
          ...options,
        })
        .then((res) => {
          if (useUI === true) {
            getMappingMutationRes(res, resObj)
            const { dataList } = resObj.getUIObj()
            resObj.setAllDataList(dataList, false)
          }
          resolve(res)
        })
        .catch((error) => {
          console.log(' ---- ', error.code, error.message)
          // if (error.graphQLErrors[0].message !== 'Signature has expired') {
          // }
          reject(error)
        })
    })
    return promise
  }

  resObj.subscribe = function subscribe(cbFunc, prop, otherClient) {
    const options = prop != null ? prop : {}

    const targetClient = otherClient || client

    const finalVars = getFinalVariables(variables)

    const observable = targetClient.subscribe({
      query: gql(queryText),
      variables: finalVars,
      fetchPolicy: 'network-only',
      ...options,
    })

    const subscription = observable.subscribe({
      next: (res) => {
        let { dataList } = resObj.getUIObj()

        if (useUI === true) {
          getMappingRes(res, resObj)

          const { dataList: newDataList } = resObj.getUIObj()
          dataList = newDataList

          if (cbFunc != null) {
            cbFunc(res, dataList)
          }
        } else if (cbFunc != null) {
          cbFunc(res)
        }

        if (useUI === true) {
          resObj.setAllDataList(dataList, res.loading)
        }
      },
      error: () => {
        console.log('- observable error')
        resObj.setLoading(false)
      },
      complete: () => {
        resObj.setLoading(false)
      },
    })

    resObj.observable = observable
    resObj.subscription = subscription

    resObj.resetCache = () => {
      resObj.observable.resetLastResults()
    }

    resObj.close = () => {
      if (resObj.subscription != null) {
        resObj.subscription.unsubscribe()
      }
    }

    return observable
  }

  return resObj
}

function getEditCompObj(compList, isDetail, nodeInfo) {
  const ret = {
    fields: {},
    compList,
    nodeInfo,
  }

  ret.getNodes = () => {
    return Object.entries(ret.compList).map((values) => {
      return values[1].component
    })
  }

  ret.getSubNodes = (key) => {
    if (nodeInfo.map[key] != null) {
      return nodeInfo.map[key].node.getNodes()
    }
    return ''
  }

  ret.initFields = (values) => {
    if (values != null) {
      initWithResp(ret.fields, ret.compList, values)
    }
    return ret.fields
  }

  ret.validateForm = async () => {
    if (
      ret.refMap != null &&
      ret.refMap[ret.name] != null &&
      ret.refMap[ret.name].current != null
    ) {
      await ret.refMap[ret.name].current.validateFields()
    }
  }

  ret.validateSubForm = async (key) => {
    if (ret.refMap != null && ret.refMap[key] != null && ret.refMap[key].current != null) {
      await ret.refMap[key].current.validateFields()
    }
  }

  // form이 생성된 후 값을 관리.
  ret.setFormFields = (values) => {
    if (
      ret.refMap != null &&
      ret.refMap[ret.name] != null &&
      ret.refMap[ret.name].current != null
    ) {
      const fv = ret.initFields(values)
      ret.refMap[ret.name].current.setFieldsValue(fv)
    } else {
      throw new Error('FormRef does not exist.')
    }
  }

  const getFormFieldsInner = (compList0, ref) => {
    const fieldsValue = ref.current.getFieldsValue()

    const props = {}

    Object.entries(compList0).forEach(([key, value]) => {
      const { info } = value

      if (info != null && (info.origin.mode == null || info.origin.mode === 'none')) {
        return
      }

      let required = false

      if (info.config && info.config.rules) {
        required = info.config.rules.some((current) => {
          return current && current.required === true
        })
      }

      if (required === true) {
        props[key] = fieldsValue[key]
      } else if (
        fieldsValue[key] != null &&
        info.origin.mode !== 'read' &&
        info.origin.mode !== 'readonly'
      ) {
        props[key] = fieldsValue[key]
      }
    })

    return props
  }

  ret.getFormFields = (exceptReadOnly) => {
    let fields = {}
    if (
      ret.refMap != null &&
      ret.refMap[ret.name] != null &&
      ret.refMap[ret.name].current != null
    ) {
      if (exceptReadOnly !== false) {
        fields = getFormFieldsInner(ret.compList, ret.refMap[ret.name])
      } else {
        fields = ret.refMap[ret.name].current.getFieldsValue()
      }

      if (ngqlUIInfo.valueGetFilter != null) {
        Object.keys(fields).forEach((key) => {
          const { info } = ret.compList[key]
          fields[key] = ngqlUIInfo.valueGetFilter(true, info.type, fields[key])
        })
      }
    }
    return fields
  }

  ret.setSubFormFields = (key, values) => {
    if (
      ret.refMap != null &&
      ret.refMap[key] != null &&
      ret.refMap[key].current != null &&
      nodeInfo.map[key] != null
    ) {
      const fv = nodeInfo.map[key].node.compList.initFields(values)
      ret.refMap[key].current.setFieldsValue(fv)
    } else {
      throw new Error('FormRef does not exist.')
    }
  }

  ret.getSubFormFields = (key, exceptReadOnly) => {
    let fields = {}
    if (ret.refMap != null && ret.refMap[key] != null && ret.refMap[key].current != null) {
      if (exceptReadOnly !== false && nodeInfo.map[key] != null) {
        fields = getFormFieldsInner(nodeInfo.map[key].node.compList, ret.refMap[key])
      } else {
        fields = ret.refMap[key].current.getFieldsValue()
      }

      if (ngqlUIInfo.valueGetFilter != null) {
        const fCompList = nodeInfo.map[key].node.compList
        Object.keys(fields).forEach((fKey) => {
          const { info } = fCompList[fKey]
          fields[fKey] = ngqlUIInfo.valueGetFilter(true, info.type, fields[fKey])
        })
      }
    }
    return fields
  }

  return ret
}

function getDetailCompObj(compList, isDetail, nodeInfo) {
  const ret = {
    fields: {},
    compList,
    nodeInfo,
  }

  ret.getNodes = () => {
    return Object.entries(ret.compList).map((values) => {
      return values[1].detail
    })
  }

  ret.getSubNodes = (key) => {
    if (nodeInfo.map[key] != null) {
      return nodeInfo.map[key].node.getNodes()
    }
    return ''
  }

  return ret
}

function generateEditUI(fields, options, values, reactObj) {
  const compList = {}
  const nodeInfo = {
    list: [],
    map: {},
    memTableMap: {},
    options,
  }

  Object.entries(fields).forEach(([key, value]) => {
    if (value === null || (value.nineType !== 'field' && value.nineType !== 'node')) {
      return
    }

    const { uiProps, metaProps } = value

    if (value.nineType === 'node') {
      const subValues = values != null ? values[key] : null

      const opts = options != null ? { ...options } : {}

      if (value.uiProps != null) {
        opts.uiNodeProps = value.uiProps
      } else {
        delete opts.uiNodeProps
      }

      const compObj = generateEditUI(value.fields, opts, subValues, reactObj)
      const nodeData = {
        uiProps,
        node: compObj,
        isTable: value.isNodeConnection === true,
        key,
      }
      nodeInfo.map[key] = nodeData
      nodeInfo.list.push(nodeData)
      return
    }

    if (uiProps.modeFunc != null) {
      uiProps.mode = uiProps.modeFunc(key, value, uiProps, values, reactObj)
    }

    if (
      uiProps != null &&
      uiProps.mode !== 'all' &&
      uiProps.mode !== 'write' &&
      uiProps.mode !== 'read' &&
      uiProps.mode !== 'readonly'
    ) {
      return
    }

    if (options.uiNodeProps != null && options.uiNodeProps.i18nextKey != null) {
      if (uiProps.labelKey == null && uiProps.label == null) {
        uiProps.labelKey = `${options.uiNodeProps.i18nextKey}.field.${key}`
      }
    }

    let label = options.t && uiProps.labelKey ? options.t(uiProps.labelKey) : uiProps.label
    let editLabel =
      options.t && uiProps.editLabelKey ? options.t(uiProps.editLabelKey) : uiProps.editLabel
    let tableLabel =
      options.t && uiProps.tableLabelKey ? options.t(uiProps.tableLabelKey) : uiProps.tableLabel
    let desc = options.t && uiProps.descKey ? options.t(uiProps.descKey) : uiProps.desc
    let afterText =
      options.t && uiProps.afterTextKey ? options.t(uiProps.afterTextKey) : uiProps.afterText

    if (uiProps.getText != null) {
      label = uiProps.getText('label', label, uiProps, metaProps)
      editLabel = uiProps.getText('edit', editLabel, uiProps, metaProps)
      tableLabel = uiProps.getText('table', tableLabel, uiProps, metaProps)
      desc = uiProps.getText('desc', desc, uiProps, metaProps)
      afterText = uiProps.getText('afterText', afterText, uiProps, metaProps)
    }

    const name = uiProps.name ? uiProps.name : key
    const val = values != null ? values[name] : ''

    const compInfo = {
      name,
      type: uiProps.type ? uiProps.type.toLowerCase() : 'text',
      desc,
      afterText,
      label,
      editLabel: editLabel || label,
      tableLabel: tableLabel || label,
      origin: uiProps,
      config: uiProps.config,
      options,
      value: val,
    }

    if (values != null) {
      // gql로 읽어온 결과로 초기값 설정.
      compInfo.initValue = values[compInfo.name]
    }

    if (uiProps.config != null && uiProps.config.rules != null) {
      uiProps.config.rules.forEach((item) => {
        if (options.t && item.messageKey) {
          item.message = options.t(item.messageKey)
        }
        if (item.validatorFunc) {
          item.validator = item.validatorFunc(options.t)
        }
      })
    }

    const comp = ngqlUIInfo.createEditElem(compInfo, reactObj, val, values)

    if (comp != null) {
      compList[key] = {
        info: compInfo,
        component: comp,
        owner: reactObj,
      }
    }
  })

  return getEditCompObj(compList, false, nodeInfo)
}

function generateDetailUIByKey(key, value, values, options, reactObj, nodeInfo) {
  if (value === null || (value.nineType !== 'field' && value.nineType !== 'node')) {
    return null
  }

  const { uiProps } = value

  if (value.nineType === 'node') {
    const subValues = values != null ? values[key] : null

    const opts = options != null ? { ...options } : {}

    if (uiProps != null) {
      opts.uiNodeProps = uiProps
    } else {
      delete opts.uiNodeProps
    }

    const compObj = generateDetailUI(value.fields, opts, subValues, reactObj)
    const nodeData = {
      uiProps,
      node: compObj,
      isTable: value.isNodeConnection === true,
      key,
    }

    if (nodeInfo != null) {
      nodeInfo.map[key] = nodeData
      nodeInfo.list.push(nodeData)
    }
    return null
  }

  if (uiProps.modeFunc != null) {
    uiProps.mode = uiProps.modeFunc(key, value, uiProps, values, reactObj)
  }

  if (
    (uiProps != null &&
      uiProps.mode !== 'all' &&
      uiProps.mode !== 'read' &&
      uiProps.mode !== 'readonly') ||
    uiProps.type === 'hidden'
  ) {
    return null
  }

  if (options.uiNodeProps != null && options.uiNodeProps.i18nextKey != null) {
    if (uiProps.labelKey == null && uiProps.label == null) {
      uiProps.labelKey = `${options.uiNodeProps.i18nextKey}.field.${key}`
    }
  }

  const name = uiProps.name ? uiProps.name : key
  let val = values != null ? values[name] : ''

  if (ngqlUIInfo.valueSetFilter != null) {
    val = ngqlUIInfo.valueSetFilter(false, uiProps.type, val)
  }

  const compInfo = {
    name,
    type: uiProps.type ? uiProps.type.toLowerCase() : 'text',
    desc: options.t && uiProps.descKey ? options.t(uiProps.descKey) : uiProps.desc,
    afterText:
      options.t && uiProps.afterTextKey ? options.t(uiProps.afterTextKey) : uiProps.afterText,
    label: options.t && uiProps.labelKey ? options.t(uiProps.labelKey) : uiProps.label,
    origin: uiProps,
    config: uiProps.config,
    options,
    value: val,
  }

  const comp = ngqlUIInfo.createDetailElem(compInfo, reactObj, val, values)

  if (comp != null) {
    return {
      info: compInfo,
      detail: comp,
      owner: reactObj,
    }
  }
  return null
}

function generateDetailUI(fields, options, values, reactObj) {
  const compList = {}
  const nodeInfo = {
    list: [],
    map: {},
    memTableMap: {},
    options,
  }

  Object.entries(fields).forEach(([key, value]) => {
    const compObj = generateDetailUIByKey(key, value, values, options, reactObj, nodeInfo)

    if (compObj != null) {
      compList[key] = compObj
    }
  })

  return getDetailCompObj(compList, true, nodeInfo)
}

function initWithResp(fields, compList, node) {
  Object.entries(compList).forEach(([key, value]) => {
    if (node != null && node[key] != null) {
      if (ngqlUIInfo.valueSetFilter != null) {
        fields[key] = ngqlUIInfo.valueSetFilter(true, value.info.type, node[key])
      } else {
        fields[key] = node[key]
      }
    }
  })
}

function getFormFields(fields, formRef) {
  const fieldsValue = formRef.current.getFieldsValue()

  const props = {}

  Object.entries(fields).forEach(([key, value]) => {
    if (value === null || value.nineType !== 'field') {
      return
    }

    const { uiProps } = value

    if (uiProps != null && (uiProps.mode == null || uiProps.mode === 'none')) {
      return
    }

    let required = false

    if (uiProps.config && uiProps.config.rules) {
      required = uiProps.config.rules.some((current) => {
        return current && current.required !== false
      })
    }

    if (required === true) {
      props[key] = fieldsValue[key]
    } else if (
      fieldsValue[key] != null &&
      value.uiProps.mode !== 'read' &&
      value.uiProps.mode !== 'readonly'
    ) {
      props[key] = fieldsValue[key]
    }
  })

  return props
}

function MemTableObj(itemListData, gqlopt, reactObj) {
  this.isMemTable = true

  this.gqlopt = gqlopt || []
  this.reactObj = reactObj

  extendsReactObj(this.reactObj, this)

  this.setUIObj({
    itemList: itemListData || [],
  })

  this.close = () => {
    if (reactObj.mobileModeOff != null) {
      reactObj.mobileModeOff()
    }

    if (this.tableInfo != null && this.tableInfo.defaultFilters != null) {
      delete this.tableInfo.defaultFilters
    }
  }

  this.setData = (items) => {
    this.setUIObj({
      itemList: items,
    })
  }

  this.getData = () => {
    const { itemList } = this.getUIObj()
    return itemList
  }

  // this.movePage = page => {
  //   variables.pageNum = page

  //   reactObj.forceUpdate();
  //   this.refetch(variables)

  //   const obj = {}
  //   if (resObj.pageParamName != null) {
  //     obj[resObj.pageParamName] = page
  //     setSearchParams(obj)
  //   }
  // }

  this.mobileMode = false

  this.setMobileMode = (isMobileView) => {
    if (this.mobileMode !== isMobileView) {
      this.mobileMode = isMobileView
      // this.reactObj.forceUpdate();
    }
  }

  this.getDefaultVariable = () => {
    let defaultVariables = null
    if (this.tableInfo != null) {
      if (this.mobileMode === true && this.tableInfo.mobileInfo != null) {
        // eslint-disable-next-line prefer-destructuring
        defaultVariables = this.tableInfo.mobileInfo.defaultVariables
      } else {
        // eslint-disable-next-line prefer-destructuring
        defaultVariables = this.tableInfo.defaultVariables
      }
    }
    return defaultVariables || {}
  }

  this.getPaginationInfo = () => {
    const pageCtrl = this.getPageControl()
    const defaultV = this.getDefaultVariable()
    const pageNum = this.getArgument(defaultV, 'pageNum', 1)
    const pageSize = this.getArgument(defaultV, 'pageSize', 5)

    const { itemList } = this.getUIObj()

    const pagination = {
      current: pageNum,
      pageSize,
      onChange: (page) => {
        console.log(page)

        if (this.pageParamName != null) {
          const params = {}
          params[this.pageParamName] = page

          setSearchParams(params)
        }

        // if (reactObj != null) {
        //   reactObj.forceUpdate()
        // }
      },
      total: itemList.length,
      hideOnSinglePage: pageCtrl != null ? pageCtrl.hideOnSinglePage : false,
    }

    let prevPageNumber = pageNum

    if (this.pageParamName != null) {
      const sparams = getSearchParams()
      if (sparams[this.pageParamName] != null) {
        prevPageNumber = sparams[this.pageParamName]
      }
    }

    const totalPage = getTotalPageCnt(itemList.length, pagination.pageSize)

    if (totalPage > 0 && totalPage >= prevPageNumber) {
      pagination.current = prevPageNumber
    } else {
      pagination.current = totalPage
    }

    pagination.current = Math.max(1, pagination.current)

    return pagination
  }

  const getElemData = (options, elemInfo, itemFunc, retFunc) => {
    const { itemList } = this.getUIObj()

    let dataSource = itemList
    if (options != null && options.getDataSource != null) {
      dataSource = options.getDataSource(dataSource)
    }

    const itemInfo = itemFunc(this, options, elemInfo, this)

    return retFunc(this, options, elemInfo, itemInfo, dataSource, this)
  }

  makeArrayElemFunc(this, getElemData)
}

function getMemTableObj(itemList, gqlopt, reactObj) {
  return new MemTableObj(itemList, gqlopt, reactObj)
}

function makeArrayElemFunc(resObj, getElemData) {
  resObj.getPageControl = () => {
    let pageCtrl = null
    if (resObj.tableInfo != null) {
      if (resObj.mobileMode === true && resObj.tableInfo.mobileInfo != null) {
        // eslint-disable-next-line prefer-destructuring
        pageCtrl = resObj.tableInfo.mobileInfo.pageControl
      }
      if (pageCtrl == null) {
        pageCtrl = resObj.tableInfo.pageControl
      }
    }
    return pageCtrl
  }

  resObj.getTableInfo = (options) => {
    return getElemData(options, resObj.tableInfo, getColumn, ngqlUIInfo.createTableInfo)
  }

  resObj.getTableElem = (options) => {
    return getElemData(options, resObj.tableInfo, getColumn, ngqlUIInfo.createTableElem)
  }

  resObj.getListInfo = (options) => {
    return getElemData(options, resObj.tableInfo, getListItem, ngqlUIInfo.createListInfo)
  }

  resObj.getListElem = (options) => {
    return getElemData(options, resObj.tableInfo, getListItem, ngqlUIInfo.createListElem)
  }

  resObj.getArrayElem = (options, type) => {
    let curType = type
    if (curType == null && resObj.tableInfo != null) {
      if (resObj.mobileMode === true && resObj.tableInfo.mobileInfo != null) {
        curType = resObj.tableInfo.mobileInfo.viewType
      }
      if (curType == null) {
        curType = resObj.tableInfo.viewType
      }
      if (curType == null) {
        curType = 'table'
      }
    }

    if (curType.toLowerCase() === 'list') {
      return resObj.getListElem(options)
    }

    return resObj.getTableElem(options)
  }
}

function getColumn(node, props, tableInfo, resObj) {
  if (resObj.checkParams != null) {
    resObj.checkParams()
  }

  const columns = tableInfo.getColumns(props, resObj.reactObj)

  let i18nextKey0 = resObj.gqlopt != null ? resObj.gqlopt.i18nextKey : null

  if (
    i18nextKey0 == null &&
    node != null &&
    node.uiProps != null &&
    node.uiProps.i18nextKey != null
  ) {
    i18nextKey0 = node.uiProps.i18nextKey
  }

  if (i18nextKey0 != null) {
    columns.forEach((item) => {
      if (item.title == null && item.dataIndex.length === 2) {
        item.title = props.t(`${i18nextKey0}.column.${item.dataIndex[1]}`)
      }
    })
  }

  columns.forEach((item) => {
    if (item.dataIndex != null) {
      const itemKey = item.dataIndex.join('.')

      if (resObj.refetch != null) {
        const vars = resObj.getVariables()
        item.filteredValue = vars.filters != null ? vars.filters[itemKey] : null
      } else {
        item.filteredValue = item.filteredValue || item.defaultFilteredValue || null

        if (item.filteredValue != null && item.filteredValue.length > 0) {
          const vars = resObj.getVariables()

          // defaultVariables에서는 sort, filter에서 처리되지 않는 field의 초기값을 지정하고,
          // sort, filter에서 처리되는 field는 getColumns에서 초기값을 지정한다.
          const filterKey = item.dataIndex[item.dataIndex.length - 1]
          if (vars.filters == null) {
            vars.filters = {}
          }
          setInFilter(vars.filters, filterKey, item.filteredValue)
          resObj.setVariables(vars)
        }
      }

      if (tableInfo.defaultFilters != null) {
        if (tableInfo.defaultFilters[itemKey] != null) {
          item.filteredValue = tableInfo.defaultFilters[itemKey]
        }
      }

      if (item.filters != null) {
        if (resObj.filterMap == null) {
          resObj.filterMap = {}
          resObj.filterKeyList = []
        }

        if (resObj.filterMap[itemKey] == null) {
          const filterValues = []
          item.filters.forEach((filterItem) => {
            filterValues.push(filterItem.value)
          })
          resObj.filterMap[itemKey] = filterValues
        }

        if (resObj.filterKeyList.indexOf(itemKey) < 0) {
          resObj.filterKeyList.push(itemKey)
        }
      }
    }

    if (item.sorter != null && item.sorter !== false) {
      if (resObj.sortKeyList == null) {
        resObj.sortKeyList = []
      }

      let sortKey = item.key
      if (sortKey == null && item.dataIndex != null) {
        sortKey = item.dataIndex[item.dataIndex.length - 1]
        sortKey = getStrBycCamelName(sortKey)
      }

      if (resObj.sortMap != null) {
        item.sortOrder = resObj.sortMap[sortKey]
      } else {
        item.sortOrder = item.sortOrder || item.defaultSortOrder || null

        // defaultVariables에서는 sort, filter에서 처리되지 않는 field의 초기값을 지정하고,
        // sort, filter에서 처리되는 field는 getColumns에서 초기값을 지정한다.
        if (item.sortOrder != null && resObj.getVariables != null) {
          const vars = resObj.getVariables()

          const sortEnum = getOrderStrByName(sortKey, item.sortOrder === 'ascend')
          if (vars.sort != null) {
            removeSortKey(sortKey, vars.sort)
          } else {
            vars.sort = []
          }
          vars.sort.push(sortEnum)
          if (vars.sort.length === 0) {
            delete vars.sort
          }
          resObj.setVariables(vars)
        }
      }
      if (tableInfo.defaultSorts != null) {
        if (tableInfo.defaultSorts[sortKey] != null) {
          item.sortOrder = tableInfo.defaultSorts[sortKey] === true ? 'ascend' : 'descend'
        }
      }

      if (resObj.sortKeyList.indexOf(sortKey) < 0) {
        resObj.sortKeyList.push(sortKey)
      }
    }

    if (resObj.isMemTable !== true) {
      // memTable 이면 controlled parameter를 모두 해제.
      item.filteredValue = item.filteredValue || null
      item.sortOrder = item.sortOrder || null
    } else {
      delete item.filteredValue
      delete item.sortOrder
    }
  })

  const ret = {
    columns,
  }

  if (tableInfo.getRowSelection != null) {
    ret.rowSelection = tableInfo.getRowSelection(props, resObj.reactObj)
  }

  return ret
}

function getListItem(node, props, ListInfo, resObj) {
  const listItem = ListInfo.getListItem != null ? ListInfo.getListItem(props, resObj.reactObj) : {}

  let i18nextKey0 = resObj.gqlopt != null ? resObj.gqlopt.i18nextKey : null

  if (
    i18nextKey0 == null &&
    node != null &&
    node.uiProps != null &&
    node.uiProps.i18nextKey != null
  ) {
    i18nextKey0 = node.uiProps.i18nextKey
  }

  if (ListInfo.getColumns != null) {
    const columnInfo = getColumn(node, props, ListInfo, resObj)
    listItem.columns = columnInfo.columns
  }

  return listItem
}

function checkError(error, errCBFunc) {
  const errInfo = {}

  console.log(error.networkError)

  if (error.networkError != null) {
    if (error.networkError.result != null && error.networkError.result.errors != null) {
      console.log(error.networkError.result.errors)
      error.networkError.result.errors.some((err) => {
        try {
          const errProp = JSON.parse(err.message)
          if (errProp.code === 450 && errProp.field != null) {
            errInfo.code = errProp.code
            errInfo.field = errProp.field
          } else {
            return false
          }
        } catch (e) {
          return false
        }
        return true
      })
    }
  }

  if (errInfo.code == null) {
    errCBFunc('noti', error)
  } else {
    errCBFunc('error', error, errInfo)
  }
}

function queryTest(queryData, variables) {
  const gqlObj = GQLObj(queryData, variables)
  gqlObj
    .query()
    .then((res) => {
      console.log('----------- result -----------')
      console.log(res)
    })
    .catch((error) => {
      console.log('----------- error -----------')
      console.log(error)
    })
}

const NineGQL = {
  UIInfo: ngqlUIInfo,
  getOrderStrByName,
  getOrderStrBycCamelName,
  Field,
  extendField,
  Node,
  NodeExt,
  renamed,
  InlineFragment,
  Fields,
  Edge,
  NodeConnection,
  NodeConnection2,
  Var,
  QueryItem,
  Query,
  Mutation,
  Subscription,
  GQLObj,
  getMappingRes,
  getMappingMutationRes,
  generateGQLString,
  generateGQL,
  getGQLString,
  generateEditUI,
  generateDetailUI,
  generateDetailUIByKey,
  initWithResp,
  getFormFields,
  getMemTableObj,
  checkError,
  queryTest,
  // Client: client,
}

export default NineGQL
