import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dropdown, Avatar, Badge, Button } from 'antd'
import { UserOutlined, GlobalOutlined } from '@ant-design/icons'

import classNames from 'classnames'

import LanguageSwitcher from 'components/cleanui/layout/TopBar/LanguageSwitcher'
import MemberDetail from 'pages/chat/component/MemberView/MemberDetail'

import { getInitialAvatar } from 'pages/chat/component/chatView/data/cUtil'
import ChatUI from 'pages/chat/component/chatUI'

import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const { t } = useTranslation()
  const [count, setCount] = useState(0)

  const chatUI = new ChatUI(this)

  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    // setCount(count + 1)
    // count가 0이면 badge가 표시안됨.
    setCount(0)
  }

  const openSettings = () => {
    chatUI.openProfileSettings(user.id, user.avatar, user.name, user.email, () => {
      dispatch({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
    })
  }

  const onMenuClick = (e) => {
    const { key } = e
    if (key === 'settings') {
      console.log(' -- onMenuClick : ', key)
      openSettings()
    }
  }

  const role = user != null && user.workspace != null ? user.workspace.role : ''
  const email = user != null ? user.email : ''

  const userMember = {
    display_name: user.name,
    avatar_url: user.avatar,
    type: role,
    email,
  }

  const extraElem = (
    <React.Fragment>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          openSettings()
        }}
      >
        {t('topBar.profileMenu.settings')}
      </Button>
    </React.Fragment>
  )

  const items = [
    {
      label: <MemberDetail email={email} member={userMember} extraElem={extraElem} />,
      key: 'userInfo',
    },
    // {
    //   label: (
    //     <React.Fragment>
    //       <strong>
    //         {t('topBar.profileMenu.hello')}, {user.name || 'Anonymous'}
    //       </strong>
    //       {/*
    //       <div>
    //         <strong className="mr-1">
    //           {t('topBar.profileMenu.billingPlan')}:{' '}
    //         </strong>
    //         Professional
    //       </div>
    //       */}
    //     </React.Fragment>
    //   ),
    //   key: 'name',
    // }, // remember to pass the key prop
    // {
    //   label: (
    //     <React.Fragment>
    //       <div>
    //         <strong>{t('topBar.profileMenu.role')}: </strong>
    //         {role || '—'}
    //       </div>
    //     </React.Fragment>
    //   ),
    //   key: 'role',
    // },
    // {
    //   label: (
    //     <div>
    //       <strong>{t('topBar.profileMenu.email')}: </strong>
    //       {email || '—'}
    //       {/*
    //       <br />
    //       <strong>
    //         {t('topBar.profileMenu.phone')}:{' '}
    //       </strong>
    //       {user.phone || '—'}
    //       */}
    //     </div>
    //   ),
    //   key: 'email',
    // }, // which is required
    // {
    //   type: 'divider',
    // },
    // {
    //   label: <div>{t('topBar.profileMenu.settings')}</div>,
    //   key: 'settings',
    // }, // which is required
    {
      label: (
        <div className="menu-language">
          <GlobalOutlined className="mr-2" />
          <span className="label">{t('topBar.profileMenu.language')} : </span>
          <LanguageSwitcher />
        </div>
      ),
      key: 'language',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          {t('topBar.profileMenu.logout')}
        </a>
      ),
      key: 'logout',
    },
  ]

  // const menu = <Menu selectable={false} items={items} />
  const menu = {
    items,
    selectable: false,
    onClick: onMenuClick,
  }

  let avatarElem = null
  if (user == null) {
    avatarElem = <UserOutlined />
  } else {
    avatarElem = getInitialAvatar(user.name, user.email)
  }

  const clsProps = {
    menu,
    trigger: ['click'],
    onOpenChange: addCount,
    overlayClassName: classNames(styles.userMenu, 'floatingSubMenu', 'menuStyle1'),
  }

  return (
    <Dropdown {...clsProps}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar
            src={user.avatar}
            className={styles.avatar}
            shape="circle"
            size={40}
            icon={avatarElem}
          />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
