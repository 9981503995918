import React from 'react'
import { notification, Modal } from 'antd'
import store from 'store'
import i18n from 'i18n'
import moment from 'moment'
import { ClientJS } from 'clientjs'
// import { ApolloError } from '@apollo/client'

import localforage from 'localforage'
import { store as reduxStore } from 'index'
import { setEnvParam, getEnvParam, getRouterType } from 'env'

import { getInitialAvatar, getInitialAvatarSrc } from 'pages/chat/component/chatView/data/cUtil'
import ChatUI from 'pages/chat/component/chatUI'

import mailApi from 'services/mailApi'
// import WorkspaceSelector from 'components/WorkspaceSelector'

import {
  clientForRefreshToken,
  clientForLanding,
  restLandingClient,
  restClientWithNoToken,
} from 'myNet'
import { sendEncrypted, getDeviceUUID } from 'pUtils'

import dataMgr from 'pages/chat/component/chatView/data/dataManager'
import { startWS, stopWS } from 'services/websocket'
import { getMessagingToken, deleteMessagingToken } from 'services/firebase'

import imageDB from 'pages/conversations/db/imageDB'
import messageDB from 'pages/conversations/db/messageDB'
import mainDB from 'pages/conversations/db/mailDB'
import linkPreviewDB from 'pages/conversations/db/linkPreviewDB'

// eslint-disable-next-line import/no-cycle
import Ngql from 'gql/ninegql'
// import { parseJwt, processError } from 'utils'
import { emailAddress } from 'utils'

import {
  getMyInfo,
  getWorkspaceInfo,
  // getWorkspaceList
} from './api'

const routerType = getRouterType()

import NQuery from './query'

const mutationUpdataToken = Ngql.Mutation('refreshToken', {
  refreshToken: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      // refreshToken: Ngql.Var('refreshToken', 'String!'),
    },
  ),
})

const updateInfo = {
  isUpdating: false,
  currentQuery: null,
  waitingReq: [],
}

export async function updateToken() {
  const refreshToken = await localforage.getItem('app.user.refreshToken')
  let ret = null
  if (refreshToken != null) {
    if (updateInfo.isUpdating === false) {
      updateInfo.isUpdating = true
      // store.set('app.user.token', refreshToken)
      try {
        const res = await Ngql.GQLObj(mutationUpdataToken, {
          // vars: {
          //   refreshToken,
          // },
        }).mutate({}, clientForRefreshToken)

        if (
          res.data.refreshToken.token == null ||
          res.data.refreshToken.token.trim().length === 0
        ) {
          throw new Error('updateToken - empty token..!')
        }

        if (process.env.REACT_APP_TARGET !== 'production') {
          store.set('app.user.token', res.data.refreshToken.token)
          store.set('app.user.refreshExpiresIn', res.data.refreshToken.refreshExpiresIn)
          store.set('app.user.refreshToken', res.data.refreshToken.refreshToken)
        }

        await localforage.setItem('app.user.token', res.data.refreshToken.token)
        await localforage.setItem(
          'app.user.refreshExpiresIn',
          res.data.refreshToken.refreshExpiresIn,
        )
        await localforage.setItem('app.user.refreshToken', res.data.refreshToken.refreshToken)

        updateInfo.waitingReq.forEach((promise) => {
          promise.resolve(res.data.refreshToken.token)
        })
        updateInfo.waitingReq = []

        updateInfo.isUpdating = false
        updateInfo.currentQuery = null

        return res.data.refreshToken.token
      } catch (ex) {
        if (process.env.REACT_APP_TARGET !== 'production') {
          store.remove('app.user.token')
        }

        await localforage.removeItem('app.user.token')

        updateInfo.waitingReq.forEach((promise) => {
          promise.reject()
        })
        updateInfo.waitingReq = []

        updateInfo.isUpdating = false
        updateInfo.currentQuery = null

        reduxStore.dispatch({
          type: 'user/LOGOUT',
        })

        throw ex
      }
    } else {
      const retPromise = new Promise((resolve, reject) => {
        updateInfo.waitingReq.push({
          resolve,
          reject,
        })
      })
      ret = retPromise
    }
  }
  return ret
}

// export function customFetch(uri, options) {
//   console.log(' ==== customFetch ', uri, options, this)
//   // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
//   // Create initial fetch, this is what would normally be executed in the link without the override
//   const initialRequest = fetch(uri, options)

//   let responseData = null
//   // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
//   // Here we return the initialRequest promise
//   return initialRequest
//     .then((response) => {
//       const ret = response.clone().json()
//       console.log(' ==== fetch result ', response, ret)
//       responseData = response
//       return ret
//     })
//     .then((error) => {
//       if (error && error.code !== 200) {
//         let retPromise = null
//         let ret = false
//         processError(error, (code, message) => {
//           if (code === 402 || message.indexOf('Signature has expired') > -1) {
//             console.log(' !!! Signature has expired ')
//             const updateHandle = updateToken()
//             if (updateHandle != null) {
//               retPromise = new Promise((resolve, reject) => {
//                 updateHandle
//                   .then((token) => {
//                     console.log(' new token : ', token)
//                     if (token != null) {
//                       options.headers.authorization = `JWT ${token}`
//                       window.fetch(uri, options).then((res2) => {
//                         resolve(res2)
//                       })
//                     }
//                   })
//                   .catch(() => {
//                     reject()
//                   })
//               })
//               ret = true
//             } else {
//               notification.warning({
//                 message: code,
//                 description: message,
//               })
//             }
//             // } else if (message.indexOf('Unauthenticated token') > -1) {
//             //   if (window.parent === window) {
//             //     setLoginVisible(true)
//             //   } else {
//             //     window.parent.nPopup.setLoginVisible(true)
//             //   }
//             //   return true
//             // }
//           }
//           return ret
//         })

//         if (retPromise != null) {
//           return retPromise
//         }

//         // if (isError === true) {
//         //   const err = new ApolloError();
//         //   err.code = error.code;
//         //   err.errors = error.errors;
//         //   throw err;
//         // }
//       }

//       return responseData
//     })
// }
const deviceModel = 'x86_64'

const landingInfo = {
  ms365: {
    provider: 'msal',
    type: 'graph',
  },
  google: {
    provider: 'gmail',
    schema: 'workin-web',
  },
  imap: {
    provider: 'imap',
    type: null,
  },
  officemail: {
    provider: 'officemail',
    type: null,
  },
}

function getProviderLandingInfo() {
  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
        return landingInfo.ms365
      // case 'gmail'
      case 'google':
        return landingInfo.google
      case 'imap':
        return landingInfo.imap
      case 'officemail':
        return landingInfo.officemail
      default:
    }
  }

  return null
}

const registerInfo = {
  ms365: {
    ewsURI: 'https://outlook.office365.com/EWS/Exchange.asmx',
    authType: 'oauth',
    provider: 'ms365',
    protocol: 'graph', // graph or ews
  },
  google: {
    ewsURI: '',
    authType: 'oauth',
    provider: 'gmail',
    protocol: 'http',
  },
  imap: {
    // ewsURI: incomingServer.hostname,
    // port: incomingServer.post
    authType: 'basicauth',
    provider: 'imap',
    protocol: 'imap',
  },
  officemail: {
    ewsURI: '',
    authType: 'oauth',
    provider: 'officemail',
    protocol: 'imap',
  },
}

function getProviderRegisterInfo() {
  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
        return registerInfo.ms365
      // case 'gmail'
      case 'google':
        return registerInfo.google
      case 'imap':
        return {
          ...registerInfo.imap,
          ewsURI: autoDiscoverRes.incomingServer.hostname,
          port: autoDiscoverRes.incomingServer.port,
        }
      case 'officemail':
        return registerInfo.officemail
      default:
    }
  }

  return null
}

export function autoDiscover(email, saveEnv) {
  const promise = new Promise((resolve, reject) => {
    restLandingClient
      .get('/autodiscover/autoconfig', {
        email,
      })
      .then((res) => {
        if (res.data.code === 200) {
          if (saveEnv === true) {
            setEnvParam('providerInfo', res.data.data)
          }
          resolve(res)
        } else {
          setEnvParam('providerInfo', null)
          reject(new Error('error'))
        }
      })
      .catch((err) => {
        setEnvParam('providerInfo', null)
        reject(err)
      })
  })

  return promise
}

function selectWorkspace(email, onOk, onError) {
  // const selectInfo = {
  //   workspace: null,
  // }

  // const onSetWorkspace = (workspaceId, workspace) => {
  //   console.log(workspaceId, workspace)
  //   selectInfo.workspace = workspace
  // }

  // Modal.info({
  //   title: 'Select Workspace',
  //   content: <WorkspaceSelector type="owner" email={email} onSetWorkspace={onSetWorkspace} />,
  //   okText: 'Ok',
  //   onOk: () => {
  //     if (onOk != null) {
  //       onOk(selectInfo.workspace)
  //     }
  //   },
  // })

  restLandingClient
    .post('/provisioning/appconfig', {
      email,
    })
    .then((res) => {
      console.log(' res - ', res)
      const { data } = res
      if (data.code === 200) {
        const appconfigs = data.data.appconfig
        if (appconfigs.length > 0) {
          onOk(appconfigs[0])
        }
      } else if (data.message != null) {
        notification.warning({
          message: data.code,
          description: data.message,
        })
        onError(new Error(data.message))
      }
    })
    .catch((err) => {
      console.log(' err - ', err)
      onError(err)
    })
}

export function updateDevice(email0, prevFcmToken, byUser) {
  const promise = new Promise((resolve, reject) => {
    const innerUpdateDevice = (messagingToken, email, onEnd) => {
      const deviceUUID = getDeviceUUID(email)
      const workspaceId = store.get('app.user.workspace_id')

      const client = new ClientJS()

      let os = ''
      if (client.isMac()) {
        os = 'mac'
      } else if (client.isWindows()) {
        os = 'windows'
      } else {
        os = 'linux'
      }

      const appVersion = process.env.REACT_APP_VERSION

      Ngql.GQLObj(NQuery.mutationUpdateDevice, {
        vars: {
          deviceUuid: deviceUUID,
          workspaceId,
          deviceData: {
            deviceUuid: deviceUUID,
            fcmToken: messagingToken,
            type: 'web',
            name: deviceModel,
            os,
            osVersion: client.getOSVersion(),
            appVersion,
          },
        },
      })
        .mutate({}, clientForLanding)
        .then((res1) => {
          console.log(' updateDevice - ', res1)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (onEnd != null) {
            onEnd()
          }
        })
    }

    getMessagingToken(byUser)
      .then(
        (messagingToken) => {
          if (prevFcmToken == null || messagingToken !== prevFcmToken) {
            console.log('firebase token - ', messagingToken)

            // if (process.env.REACT_APP_TARGET !== 'production') {
            store.set('app.user.fcmToken', messagingToken)
            store.set('app.user.fcmToken_date', moment().valueOf())
            // }

            innerUpdateDevice(messagingToken, email0, () => {
              resolve(messagingToken)
            })
          } else {
            resolve(messagingToken)
          }
        },
        (ret) => {
          innerUpdateDevice(null, email0, () => {
            reject(ret)
          })
        },
      )
      .catch((err) => {
        console.log(err)
        innerUpdateDevice(null, email0, () => {
          reject(err)
        })
      })
  })

  return promise
}

// function authRegister(username, email) {
function authRegister(email) {
  const promise = new Promise((resolve, reject) => {
    const deviceUUID = getDeviceUUID(email)

    selectWorkspace(
      email,
      (appconfig) => {
        let info = getProviderRegisterInfo()
        if (info != null) {
          store.set('app.user.registorInfo', JSON.stringify(info))
        } else {
          const str = store.get('app.user.registorInfo')
          if (str != null) {
            info = JSON.parse(str)
          } else {
            reduxStore.dispatch({
              type: 'user/LOGOUT',
            })
            reject(new Error('no registor info.'))
          }
        }
        const params = {
          username: email, // display_name 아님. email을 사용해야 함.
          email,
          password: '',
          deviceModel,
          deviceUUID,
          appCategory: 'enterprise', // store
          workspaceId: appconfig.workspace_id,
          // ewsURI: 'https://outlook.office365.com/EWS/Exchange.asmx',
          // authType: 'oauth',
          // provider: 'ms365',
          // protocol: 'graph', // graph or ews
          ...info,
        }
        sendEncrypted(
          '/auth/register/web/',
          params,
          async (res) => {
            if (res.code === 200) {
              console.log('success ~~!!! ', res)

              store.set('app.user.connection_digest', res.data.connection_digest)
              store.set('app.user.workspace_id', res.data.workspace_id)
              store.set('app.user.deviceUUID', deviceUUID)

              if (process.env.REACT_APP_TARGET !== 'production') {
                store.set('app.user.token', res.data.pAccessToken)
                store.set('app.user.refreshToken', res.data.pRefreshToken)
              }

              await localforage.setItem('app.user.workspace_id', res.data.workspace_id)
              await localforage.setItem('app.user.token', res.data.pAccessToken)
              await localforage.setItem('app.user.refreshToken', res.data.pRefreshToken)

              // const workspace = {
              //   workspace_id: appconfig.workspace_id,
              //   icon_url: appconfig.brandingLogo,
              //   workspace_name: appconfig.appServicePublisher,
              //   product_name: appconfig.brandingName,
              // }

              // store.set('app.user.workspace', JSON.stringify(workspace))

              startWS()

              updateDevice(email)
                .catch(() => {})
                .finally(() => {
                  resolve(res)
                })
            } else {
              reject(res)
            }
          },
          (ex) => {
            console.log(ex)
            notification.warning({
              message: ex.code,
              description: ex.message,
            })
            reject(ex)
          },
        )
      },
      (err) => {
        reject(err)
      },
    )
  })

  return promise
}

async function landinglogin(email) {
  store.remove('guest')

  const promise = new Promise((resolve, reject) => {
    const onSuccessLanding = (e) => {
      console.log(' -- message : ', e)

      if (
        window.location.origin === e.origin ||
        (e.origin.indexOf('rework.so') < 0 && e.origin.indexOf('officemail.io') < 0)
      ) {
        return
      }

      const data = JSON.parse(e.data)

      if (data.params.result === 'OK') {
        console.log(' -- params : ', data.params)

        store.set('app.user.providerToken', data.params.accessToken)

        // store.set('app.user.email', email)

        setEnvParam('userEmail', email)
        localforage.setItem('app.user.email', email)

        // authRegister(data.params.username, email)
        authRegister(email)
          .then((res) => {
            window.removeEventListener('message', onSuccessLanding)
            resolve(res)
          })
          .catch((err) => {
            window.removeEventListener('message', onSuccessLanding)
            reject(err)
          })
      }

      if (window.loginChatWin != null) {
        clearInterval(window.loginChatTimer)
        window.loginChatWin.close()
        window.loginChatWin = null
      }
    }

    window.addEventListener('message', onSuccessLanding, false)

    setTimeout(async () => {
      const info = getProviderLandingInfo()

      const params = {
        email,
      }

      if (info.type != null) {
        params.type = info.type
      }

      if (info.schema != null) {
        params.schema = info.schema
      }

      try {
        const res = await restLandingClient.get(`/auth/landing/${info.provider}`, params)

        console.log('data - ', res.data)

        if (res.data.code === 200) {
          const url = res.data.data.auth_url
          window.loginChatWin.location.href = url

          const windowSize = {
            width: 500,
            height: 600,
          }
          // const windowLocation = {
          //   left: window.screen.availLeft + window.screen.availWidth / 2 - windowSize.width / 2,
          //   top: window.screen.availTop + window.screen.availHeight / 2 - windowSize.height / 2,
          // }

          // window.loginChatWin.moveTo(windowLocation.left, windowLocation.top);
          window.loginChatWin.resizeTo(windowSize.width, windowSize.height)

          window.loginChatTimer = setInterval(function () {
            if (window.loginChatWin.closed) {
              clearInterval(window.loginChatTimer)

              reduxStore.dispatch({
                type: 'user/LOGOUT',
              })
            }
          }, 1000)
        }
      } catch (error) {
        if (window.loginChatWin != null) {
          clearInterval(window.loginChatTimer)
          window.loginChatWin.close()
          window.loginChatWin = null
        }
        console.log('error - ', error)
        notification.warning({
          message: error.code,
          description: error.message,
        })
      }
    }, 10)
  })

  return promise
}

async function loginIMAP(email, password) {
  store.remove('guest')

  const promise = new Promise((resolve, reject) => {
    const autoDiscoverRes = getEnvParam('providerInfo')

    const params = {
      host: autoDiscoverRes.incomingServer.hostname,
      port: autoDiscoverRes.incomingServer.port,
      email,
      password,
    }

    restLandingClient
      .post(`/auth/verifying/imap`, params)
      .then((res) => {
        console.log('data - ', res.data)

        if (res.data.code === 200) {
          setEnvParam('userEmail', email)
          localforage.setItem('app.user.email', email)

          // authRegister(email, email)
          authRegister(email)
            .then((res2) => {
              resolve(res2)
            })
            .catch((err) => {
              reject(err)
            })
        } else if (res.data.code === 500) {
          notification.warning({
            message: res.data.code,
            description: res.data.message,
          })
        } else {
          reject(new Error('login failed'))
        }
      })
      .catch((error) => {
        console.log('error - ', error)
        notification.warning({
          message: error.code,
          description: error.message,
        })
        reject(error)
      })
  })

  return promise
}

export async function login(email, password) {
  store.remove('guest')

  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
      case 'google':
      case 'officemail':
        return landinglogin(email)
      case 'imap':
        return loginIMAP(email, password)
      default:
    }
  }

  return null
}

export async function guestLogin(key) {
  const promise = new Promise((resolve, reject) => {
    store.remove('guest')

    restClientWithNoToken
      .get(`/api/chat/guest/${key}`)
      .then(async (res) => {
        const { data } = res
        if (data.code === 200) {
          store.set('app.user.workspace_id', data.data.workspace_id)
          await localforage.setItem('app.user.workspace_id', data.data.workspace_id)

          if (data.data.access_token != null) {
            if (process.env.REACT_APP_TARGET !== 'production') {
              store.set('app.user.token', data.data.access_token)
            }

            await localforage.setItem('app.user.token', data.data.access_token)
          }

          if (data.data.refresh_token != null) {
            if (process.env.REACT_APP_TARGET !== 'production') {
              store.set('app.user.refreshToken', data.data.refresh_token)
            }

            await localforage.setItem('app.user.refreshToken', data.data.refresh_token)
          }

          const ret = {
            chatRoomId: data.data.chat_room_id,
            workspaceId: data.data.workspace_id,
            email: data.data.email,
          }

          store.set('guest', data.data.email)

          resolve(ret)

          // setTimeout(() => {
          //   store.set('authState', {
          //     state: 'redirect',
          //     time: Date.now()
          //   })
          // }, 1000);
        } else {
          reject(new Error('Key is invalid'))
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

  return promise
}

export async function register(/* email, password, name */) {
  function fakeRegister() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeRegister()
}

async function loadingCheck() {
  let checkCnt = 0
  const promise = new Promise((resolve, reject) => {
    const checkDB = () => {
      if (checkCnt > 50) {
        // 로딩에 5초이상 소요되면 reject.
        reject(new Error('DB loading fail'))
      }
      if (
        imageDB.isActive === true &&
        messageDB.isActive === true &&
        mainDB.isActive === true &&
        linkPreviewDB.isActive === true
      ) {
        console.log(' db loading count : ', checkCnt)
        resolve(true)
      } else {
        checkCnt += 1
        setTimeout(checkDB, 100)
      }
    }

    checkDB()
  })

  return promise
}

function isNeedAccountLoading() {
  let pathName = ''

  if (routerType === 'hash') {
    const { hash } = window.location
    if (hash != null && hash.length > 0 && hash.indexOf('#') === 0) {
      pathName = hash.substring(1)
    }
  } else {
    pathName = window.location.pathname
  }

  console.log(' --- getParams : ', pathName)

  if (pathName.indexOf('/auth/') === 0) {
    return false
  }

  return true
}

export async function currentAccount() {
  if (isNeedAccountLoading() === false) {
    return null
  }

  const workspaceId = store.get('app.user.workspace_id')
  // const deviceUUID = store.get('app.user.deviceUUID');

  if (workspaceId == null) {
    const token = await localforage.getItem('app.user.token')

    if (token != null) {
      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })
    }

    return null
  }
  // const str = store.get('app.user.chatInfo')

  // const myInfo = str != null ? JSON.parse(str) : {}

  // console.log(myInfo)

  // const client = new ClientJS();

  // console.log('device - ', client.getDevice());
  // console.log('deviceType - ', client.getDeviceType());
  // console.log('deviceVendor - ', client.getDeviceVendor());

  // console.log('cpu - ', client.getCPU());
  // console.log('os - ', client.getOS());
  // console.log('osVersion - ', client.getOSVersion());
  // console.log('getBrowser - ', client.getBrowser());
  // console.log('getBrowserVersion - ', client.getBrowserVersion());

  let user = null
  let myInfo = null

  const guest = store.get('guest')

  if (guest == null) {
    const token = store.get('app.user.providerToken')
    if (token && token.length > 0) {
      const mailApiInstance = mailApi.init()

      if (mailApiInstance == null) {
        // provider 가 없는 상태. 오류임.
        reduxStore.dispatch({
          type: 'user/LOGOUT',
        })

        return null
      }

      user = await mailApi.getCurrentAccount()
    }

    if (user != null) {
      // selectWorkspace(user.mail, () => {
      //   console.log('test');
      // })

      const email = user.mail || user.userPrincipalName || ''

      // login한 직후에만 존재하는 정보임. 로그인에 사용한 이메일 주소와 office에서 받은 이메일이 같은지 확인이 필요.
      const loginEmail = getEnvParam('userEmail')

      // const loginEmail = store.get('app.user.email')

      setEnvParam('userEmail', email)
      await localforage.setItem('app.user.email', email)

      if (loginEmail != null && loginEmail !== email) {
        console.log(' email is different from login email.', loginEmail, email)
        setEnvParam('userEmail', loginEmail)
        await localforage.setItem('app.user.email', loginEmail)

        const errorTxt = i18n.t('login.error.emailInfoInvalid')
        const config = {
          title: 'Error',
          content: <div dangerouslySetInnerHTML={{ __html: errorTxt }} />,
          // afterClose: () => {
          //   reduxStore.dispatch({
          //     type: 'user/LOGOUT',
          //   })
          // }
        }

        Modal.error(config)

        reduxStore.dispatch({
          type: 'user/LOGOUT',
        })
        return null
      }
    }

    try {
      myInfo = await getMyInfo()
    } catch (e) {
      notification.error({
        message: e.code,
        description: e.message,
      })
      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })
      return null
    }
  } else {
    const email = guest

    const emailObj = emailAddress(email)
    myInfo = {
      id: email,
      email,
      display_name: emailObj.user,
      avatar_url: getInitialAvatarSrc(emailObj.user, email, null),
    }
  }

  let displayName = myInfo.display_name

  // displayName이 정의되지 않은 경우. 기본값을 설정한다.
  if (myInfo.display_name == null || myInfo.display_name.trim().length === 0) {
    if (user != null && user.displayName != null && user.displayName.length > 0) {
      displayName = user.displayName
    } else {
      const pos = myInfo.email.indexOf('@')
      displayName = myInfo.email.substring(0, pos)
    }

    const tmpChatUI = new ChatUI(null)

    const sendData = { displayName }

    await tmpChatUI.updateAccountInfo(myInfo.id, sendData, null, true)
  }

  setEnvParam('userEmail', myInfo.email)
  await localforage.setItem('app.user.email', myInfo.email)

  startWS()

  if (guest == null) {
    /* @todo - 2달(?) 이상 넘어가면 토큰이 만료됨. 이에 대한 처리는? 일단 놔두자. 로그인할 때 다시 발급함. */
    const fcmTokenTime = store.get('app.user.fcmToken_date')

    if (fcmTokenTime == null || fcmTokenTime.length === 0) {
      getMessagingToken().catch((err) => {
        console.log(err)
      })
    } else {
      const curTime = moment()
      const prevTime = moment(fcmTokenTime)
      const diffDay = curTime.diff(prevTime, 'days', true)

      // const username = myInfo.display_name != null ? myInfo.display_name : myInfo.email
      if (diffDay > 30) {
        updateDevice(myInfo.email).catch(() => {})
      } else {
        const prevFcmToken = store.get('app.user.fcmToken')
        updateDevice(myInfo.email, prevFcmToken).catch(() => {})
      }
    }
  }

  // const res = await getWorkspaceList()

  // console.log('myInfo - ', myInfo, res.data.workspace_list)

  // const workspace = res.data.workspace_list.find((item) => {
  //   if (item.workspace_id === workspaceId) {
  //     return true
  //   }
  //   return false
  // })

  // const workspaceStr = store.get('app.user.workspace')

  // const workspace =
  //   workspaceStr != null ? JSON.parse(workspaceStr) : { workspace_id: workspaceId }
  let workspaceInfo = null

  if (guest == null) {
    try {
      workspaceInfo = await getWorkspaceInfo(workspaceId)
    } catch (e) {
      console.log(' - getWorkspaceInfo error : ', e)
      notification.error({
        message: e.code,
        description: e.message,
      })
      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })
      return null
    }
  } else {
    workspaceInfo = {
      workspaceEmail: myInfo.email,
      workspaceName: '',
      iconUrl: null,
      role: 'guest',
    }
  }

  console.log(' workspaceInfo - ', workspaceInfo)

  const workspace = {
    workspace_name: workspaceInfo.workspaceName,
    workspace_email: workspaceInfo.workspaceEmail,
    icon_url: workspaceInfo.iconUrl,
    role: workspaceInfo.role,
  }

  if (workspace.icon_url == null) {
    if (workspace.workspace_name != null) {
      workspace.iconElem = getInitialAvatar(workspace.workspace_name, workspace.workspace_email)
    }
  }

  if (myInfo.avatar_url == null) {
    const officeAvatarBlob = await mailApi.getMyProfilePhoto()
    // console.log(' office avatar - ', officeAvatar);
    if (officeAvatarBlob != null) {
      myInfo.avatar_url = window.URL.createObjectURL(officeAvatarBlob)
    }
  }

  const avatarUrl = myInfo.avatar_url != null ? myInfo.avatar_url : ''

  await loadingCheck()

  const userInfo = {
    id: myInfo.id,
    name: displayName || '',
    email: myInfo.email,
    // timeFormat: user.mailboxSettings?.timeFormat || '',
    // timeZone: user.mailboxSettings?.timeZone || 'UTC'
    uid: '',
    avatar: avatarUrl,
    role: workspaceInfo.role,
    lastLogin: '',
    workspace,
  }

  dataMgr.setMeData(userInfo)

  return userInfo
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(false)
  //   }, 200)
  // })
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)

  async function fakeLogout() {
    store.remove('app.user.providerToken')
    store.remove('app.user.connection_digest')
    store.remove('app.user.chatInfo')
    store.remove('app.user.workspace_id')
    store.remove('app.user.deviceUUID')
    store.remove('app.user.fcmToken')
    store.remove('app.user.fcmToken_date')
    store.remove('app.user.registorInfo')

    store.remove('app.user.email')
    store.remove('guest')

    // 사용하지 않는 key 이지만, 이전의 데이터를 지우기 위해 남겨놓음.
    store.remove('app.user.workspace')

    if (process.env.REACT_APP_TARGET !== 'production') {
      store.remove('app.user.token')
      store.remove('app.user.refreshToken')
      store.remove('app.user.workspace_id')
      store.remove('app.user.refreshExpiresIn')
    }

    await localforage.removeItem('app.user.token')
    await localforage.removeItem('app.user.refreshToken')
    await localforage.removeItem('app.user.workspace_id')
    await localforage.removeItem('app.user.refreshExpiresIn')
    await localforage.removeItem('app.user.email')

    deleteMessagingToken()

    dataMgr.clear()

    stopWS()

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return await fakeLogout()
}

const apis = {
  // customFetch,
  login,
  logout,
}

export default apis
