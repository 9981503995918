import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'router'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
// import { history } from 'index'
import SimpleBar from 'simplebar-react'

import styles from './style.module.scss'

function getIcon(data) {
  if (data == null) {
    return null
  }
  if (typeof data === 'string') {
    return <span className={`${data} ${styles.icon} icon-collapsed-hidden`} />
  }
  return <span className={`${styles.icon} icon-collapsed-hidden`}>{data}</span>
}

const mapStateToProps = ({ menu, settings, user, dispatch }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isSidebarOpen: settings.isSidebarOpen,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  // leftMenuWidth: settings.leftMenuWidth,
  leftMenuWidth: 78,
  menuColor: settings.menuColor,
  // logo: settings.logo,
  role: user.role,
  unreadCnt: user.unreadCnt,
  dispatch,
})

class MenuLeft extends React.Component {
  // const MenuLeft = ({
  //   dispatch,
  //   menuData = [],
  //   location: { pathname },
  //   isMenuCollapsed,
  //   isSidebarOpen,
  //   isMobileView,
  //   isMenuUnfixed,
  //   isMenuShadow,
  //   leftMenuWidth,
  //   menuColor,
  //   logo,
  //   role,
  // }) => {

  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || ['dashboardnine'],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  menuKeyMap = []

  mouseEnterTimer = null

  componentDidMount() {
    this.applySelectedKeys()
  }

  componentDidUpdate(prevProps /* , prevState */) {
    const { location, menuData } = this.props
    if (prevProps.menuData !== menuData || prevProps.location.pathname !== location.pathname) {
      this.applySelectedKeys()
    }
  }

  applySelectedKeys = () => {
    const {
      location: { pathname },
      menuData,
    } = this.props

    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    // const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    const menuItems = flattenItems(menuData, 'children')
    let selectedItem = find(menuItems, (menu) => {
      if (menu.url === pathname) {
        return true
      }
      return false
    })

    if (selectedItem == null) {
      let curMenu = null
      let mCnt = 0
      const pathArr = pathname.split('/')
      menuItems.forEach((menu) => {
        if (menu.url != null) {
          const urlArr = menu.url.split('/')
          const len = Math.min(urlArr.length, pathArr.length)
          // 0번째는 전부 ''이므로 비교할 필요없음.
          for (let i = 1; i < len; i += 1) {
            if (pathArr[i] === urlArr[i]) {
              if (mCnt < i + 1) {
                mCnt = i + 1
                curMenu = menu
              }
            } else {
              break
            }
          }
        }
      })

      if (curMenu != null) {
        selectedItem = curMenu
      }
    }

    const keys = selectedItem ? [selectedItem.key] : []
    store.set('app.menu.selectedKeys', keys)

    this.setState({
      selectedKeys: keys,
    })
  }

  onCollapse = (value, type) => {
    console.log(value, type)
    // const { dispatch, isMenuCollapsed } = this.props

    // if (type === 'responsive' && isMenuCollapsed) {
    //   return
    // }
    // dispatch({
    //   type: 'settings/CHANGE_SETTING',
    //   payload: {
    //     setting: 'isMenuCollapsed',
    //     value: !isMenuCollapsed,
    //   },
    // })
    // this.setState({
    //   openedKeys: [],
    // })
  }

  onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    this.setState({
      openedKeys: keys,
    })
  }

  handleClick = (e) => {
    const {
      dispatch,
      isSidebarOpen,
      // isMobileView,
      history,
    } = this.props
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }

    this.setState({
      selectedKeys: [e.key],
    })

    dispatch({
      type: 'menu/NOTIFY_LISTENER',
      payload: {
        key: e.key,
      },
    })

    if (this.mouseEnterTimer != null) {
      clearTimeout(this.mouseEnterTimer)
    }

    this.mouseEnterTimer = setTimeout(() => {
      console.log(' --- viewType test 5 - ', e.key)
      this.handleMouseEnter({ key: e.key })
      this.mouseEnterTimer = null
    }, 100)

    if (this.menuKeyMap[e.key] != null) {
      const { url } = this.menuKeyMap[e.key]
      if (url != null) {
        const { location } = this.props

        console.log(' --- viewType test 6 - ', e.key, url, location)

        // 너무 자주 호출되지 않도록 막을 것. 100~200ms 마다 한번만 호출.
        if (location.pathname.indexOf(url) !== 0) {
          console.log(' --- viewType test 7 - ', e.key, url, this.menuKeyMap[e.key].url)

          history.push({
            pathname: this.menuKeyMap[e.key].url,
            // state: {
            //   toggleOn: this.menuKeyMap[e.key].toggleOn === true,
            // },
          })
        }
        // this.menuKeyMap[e.key].toggleOn = this.menuKeyMap[e.key].toggleOn !== true
      }
    }

    // if (isMobileView === true) {
    //   dispatch({
    //     type: 'settings/CHANGE_SETTING',
    //     payload: {
    //       setting: 'isMobileMenuOpen',
    //       value: false,
    //     },
    //   })
    // }
  }

  handleMouseEnter = (e) => {
    console.log('hover - ', e)
    const { selectedKeys } = this.state
    if (e.key != null && e.key === selectedKeys[0] && e.key.indexOf('menu-') === 0) {
      const rootElem = document.body.querySelector('.root')
      if (rootElem != null) {
        const clsName = `menu-hover`

        if (rootElem.classList.contains(clsName) === false) {
          rootElem.classList.add(clsName)
        }
      }
    }
  }

  handleMouseLeave = (e) => {
    console.log('hover - ', e)
    const { selectedKeys } = this.state
    if (e.key != null && e.key === selectedKeys[0] && e.key.indexOf('menu-') === 0) {
      const rootElem = document.body.querySelector('.root')
      if (rootElem != null) {
        const clsName = `menu-hover`

        if (rootElem.classList.contains(clsName) === true) {
          rootElem.classList.remove(clsName)
        }
      }
    }
  }

  generateMenuItems = () => {
    const { menuData, role, unreadCnt } = this.props

    this.menuKeyMap = {}

    const generateItem = (item) => {
      const { key, title, icon, disabled, count } = item
      let titleText = title

      if (item.key != null) {
        this.menuKeyMap[item.key] = item
      }

      if (item.category) {
        return {
          type: 'group',
          label: titleText,
          key: Math.random(),
        }
      }

      if (item.divider === true) {
        return {
          type: 'divider',
        }
      }

      if (item.key === 'menu-conversation') {
        titleText = (
          <React.Fragment>
            {titleText} <span className={styles.count}>{unreadCnt}</span>
          </React.Fragment>
        )
      }

      return {
        label: <span className={styles.title}>{titleText}</span>,
        className: `cls-${key}`,
        key,
        disabled,
        icon: (
          <React.Fragment>
            {count && <span className="badge badge-success ml-2">{count}</span>}
            {getIcon(icon)}
          </React.Fragment>
        ),
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
      }
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const titleText = menuItem.title
          return {
            label: <span className={styles.title}>{titleText}</span>,
            key: menuItem.key,
            icon: (
              <React.Fragment>
                {menuItem.count && (
                  <span className="badge badge-success ml-2">{menuItem.count}</span>
                )}
                {getIcon(menuItem.icon)}
              </React.Fragment>
            ),
            children: generateSubmenu(menuItem.children),
          }
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const titleText = menuItem.title
        return {
          label: <span className={styles.title}>{titleText}</span>,
          className: `cls-${menuItem.key}`,
          key: menuItem.key,
          icon: (
            <React.Fragment>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {getIcon(menuItem.icon)}
            </React.Fragment>
          ),
          children: generateSubmenu(menuItem.children),
        }
      }
      return generateItem(menuItem)
    })
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const {
      // isMobileView,
      leftMenuWidth,
      isMenuCollapsed,
      menuColor,
      isMenuUnfixed,
      isMenuShadow,
      // logo,
    } = this.props

    const isMobileView = false

    const menuSettings = isMobileView
      ? {
          width: leftMenuWidth,
          collapsedWidth: leftMenuWidth,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: leftMenuWidth,
          collapsedWidth: leftMenuWidth,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menuItems = this.generateMenuItems()

    return (
      <Layout.Sider
        {...menuSettings}
        className={classNames(styles.menu, 'left-sider-menu', {
          [styles.white]: menuColor === 'white',
          [styles.gray]: menuColor === 'gray',
          [styles.dark]: menuColor === 'dark',
          [styles.unfixed]: isMenuUnfixed,
          [styles.shadow]: isMenuShadow,
        })}
      >
        <div
          className={styles.menuOuter}
          style={{
            width: isMenuCollapsed && !isMobileView ? 78 : leftMenuWidth,
            height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
          }}
        >
          {/*
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <img src="resources/images/logo.svg" className="mr-2" alt="Re:Work Admin" />
              <div className={styles.name}>{logo}</div>
            </div>
          </div>
          */}
          <SimpleBar style={{ height: '100%' }}>
            <Menu
              onClick={this.handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={this.onOpenChange}
              mode="inline"
              className={styles.navigation}
              inlineIndent="15"
              items={menuItems}
            />
            {/*
            <div className={styles.banner}>
              <p>More components, more style, more themes, and premium support!</p>
              <a
                href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-success btn-rounded px-3"
              >
                Buy Bundle
              </a>
            </div>
            */}
          </SimpleBar>
        </div>
      </Layout.Sider>
    )
  }
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
