class TempAPI {
  // constructor() {}

  async getCurrentAccount() {
    return null
  }

  async getMyProfilePhoto(size) {
    console.log(size)
    return null
  }
}

const tempAPI = new TempAPI()

export default tempAPI
