import moment from 'moment'

import { getEnvParam } from 'env'

export function creactReactObjMgr() {
  const reactMgr = {}
  reactMgr.reactObjList = []
  reactMgr.forceUpdate = () => {
    reactMgr.reactObjList.forEach((reactObj) => {
      if (reactObj.checkInfo != null) {
        reactObj.checkInfo()
      } else {
        reactObj.forceUpdate()
      }
    })
  }

  reactMgr.run = (runFunc) => {
    reactMgr.reactObjList.forEach((reactObj) => {
      runFunc(reactObj)
    })
  }

  reactMgr.addReactObj = (reactObj) => {
    if (reactMgr.reactObjList.indexOf(reactObj) < 0) {
      reactMgr.reactObjList.push(reactObj)
    }
  }

  reactMgr.removeReactObj = (reactObj) => {
    const pos = reactMgr.reactObjList.indexOf(reactObj)
    if (pos >= 0) {
      reactMgr.reactObjList.splice(pos, 1)
    }
  }

  return reactMgr
}

export function creactCallBackMgr() {
  const cbMgr = {}
  cbMgr.cbList = []

  cbMgr.notify = (...args) => {
    cbMgr.cbList.forEach((cbFunc) => {
      cbFunc.apply(this, args)
    })
  }

  cbMgr.add = (cbFunc) => {
    if (cbMgr.cbList.indexOf(cbFunc) < 0) {
      cbMgr.cbList.push(cbFunc)
    }
  }

  cbMgr.remove = (cbFunc) => {
    const pos = cbMgr.cbList.indexOf(cbFunc)
    if (pos >= 0) {
      cbMgr.cbList.splice(pos, 1)
    }
  }

  return cbMgr
}

// api queue는 세개가 존재함. (중복 호출 방지 목적.)
// 1. up 방향 기존 데이터 로딩 용. - create time 기준으로 호출.
// 2. down 방향 기존 데이터 로딩 용. - create time 기준으로 호출.
// 3. down 방향 최신 데이터 로딩 용. - update time 기준으로 호출.

const defaultGetList = (res) => {
  return res.data.data
}

const defaultGetKeyValue = (item) => {
  return item.message_id
}

const isNormalMsg = (msg) => {
  return msg.type !== 'date' && msg.id != null
}

export function MsgApiQueue(options, initKeyValue) {
  const self = this

  self.options = {
    limit: options.limit || 20,
    interval: options.interval || 50,
    failInterval: options.failInterval || 2000,
    infinite: options.infinite === true,
    infiniteDelay: options.infiniteDelay || 1000, // ms
    readToEnd: options.readToEnd === true,
    apiForQuery: options.apiForQuery,
    sort: options.sort,
    filters: options.filters,
    getRangeFilter: options.getRangeFilter, // gt, lt, gte, lte 등 검색 영역에 따른 filter 생성 함수.
    getList: options.getList || defaultGetList,
    getValueForSort: options.getValueForSort,
    getKeyValue: options.getKeyValue || defaultGetKeyValue,
    isAsc: options.isAsc || true,
    onLoadData: options.onLoadData,
    onLoadingChange: options.onLoadingChange,
  }

  self.isRunning = false

  self.isLoading = false

  self.hasMore = true

  self.queue = []

  self.lastKeyValue = null

  self.lastCalledTime = null

  // api data
  self.addReq = (req) => {
    // 중간에 데이터가 끊어지는 경우는 없음. key값을 기준으로 모두 이어지는 데이터가 되어야 함.
    // 데이터가 끊어지는 경우는, block이 분리됨.
    // firstMessageId 또는 firstMessage의 createTime 필요. ???
    let isEnable = true
    if (self.lastKeyValue == null) {
      if (self.options.infinite !== true && self.hasMore === false) {
        isEnable = false
      } else if (req.more != null) {
        // 처음 데이터 로딩은 more 불가능.
        isEnable = false
      } else {
        const keyReq = self.queue.find((item) => {
          return item.keyValue != null
        })

        isEnable = keyReq == null
      }
    } else if (self.lastKeyValue != null) {
      if (self.options.infinite !== true && self.hasMore === false) {
        isEnable = false
      } else if (req.keyValue != null) {
        // 처음 로딩이 아니면 모두 more.
        isEnable = false
      } else {
        const moreReq = self.queue.find((item) => {
          return item.more != null
        })

        isEnable = moreReq == null
      }
    }

    // 무제한 모드에서 다음 페이지가 없는 경우, 1초 이내로 연속 요청은 무시됨.
    if (self.options.infinite === true && self.hasMore === false && self.lastCalledTime != null) {
      const time = moment().valueOf() - self.lastCalledTime
      if (self.options.infiniteDelay > time) {
        isEnable = false
        // 1초 이내 요청이면 1초 후 다시 요청.
        setTimeout(() => {
          self.more()
        }, 1000)
      }
    }

    if (isEnable === true) {
      self.queue.push(req)
    }

    // 데이터의 크기가 1보다 커지면 start.
    if (self.queue.length > 0) {
      self.start()
    }
  }

  self.start = () => {
    if (self.isRunning === true) {
      return
    }

    self.isRunning = true

    if (self.options.infinite === true) {
      self.hasMore = true
    }

    self.run()
  }

  self.failCnt = 0

  self.stop = () => {
    self.isRunning = false
    if (self.runTimer != null) {
      clearTimeout(self.runTimer)
    }
  }

  self.run = () => {
    if (self.isRunning === true) {
      if (self.queue.length === 0) {
        self.stop()
        return
      }

      const req = self.queue.pop()

      self
        .request(req)
        .then(() => {
          // 성공 시, interval 이후 다음 요청 실행.
          self.failCnt = 0
          this.runTimer = setTimeout(() => {
            self.run()
          }, self.options.interval)
        })
        .catch(() => {
          // 실패 시. 2번 더 시도 후 계속 실패하면 정지.
          self.failCnt += 1
          console.log(' err count - ', self.failCnt, req)
          if (self.failCnt < 3) {
            self.queue.unshift(req) // 다시 queue에 올림.
            this.runTimer = setTimeout(() => {
              self.run()
            }, self.options.failInterval)
          } else {
            self.stop()
          }
        })
    }
  }

  self.request = (req) => {
    const {
      limit,
      sort,
      filters: defaultFilters,
      isAsc,
      apiForQuery,
      getRangeFilter,
      getList,
      getValueForSort,
      getKeyValue,
      onLoadData,
      onLoadingChange,
    } = self.options

    const queries = {
      limit,
    }

    if (sort != null) {
      queries.sort = sort
    }

    let filters = []
    if (defaultFilters != null) {
      filters = [...defaultFilters]
    }

    if (req.readToEnd === true && req.hasMore === true) {
      self.queue.shift({
        readToEnd: true,
        more: true,
      })
    }

    let keyFilter = null
    if (req.more === true) {
      keyFilter = getRangeFilter(false, self.lastKeyValue)
    } else {
      keyFilter = getRangeFilter(true, req.keyValue)
    }

    if (keyFilter != null) {
      filters.push(keyFilter)
    }

    if (filters.length > 0) {
      queries.filters = filters
    }

    const retPromise = new Promise((resolve, reject) => {
      if (onLoadingChange != null) {
        onLoadingChange(true)
      }

      self.lastCalledTime = moment().valueOf()

      apiForQuery(queries)
        .then((res) => {
          const { code, message } = res.data
          if (code === 200) {
            let list = getList(res)

            if (list.length === 0) {
              self.hasMore = false
            }

            if (getValueForSort != null) {
              list = list.sort((itemA, itemB) => {
                const a = getValueForSort(itemA)
                const b = getValueForSort(itemB)

                let ret = 0
                if (a > b) {
                  ret = isAsc === true ? 1 : -1
                } else if (b > a) {
                  ret = isAsc === true ? -1 : 1
                }

                return ret
              })
            }

            if (list.length > 0) {
              if (isAsc === true) {
                self.lastKeyValue = getKeyValue(list[list.length - 1])
              } else {
                self.lastKeyValue = getKeyValue(list[0])
              }
            } else if (req.keyValue != null) {
              self.lastKeyValue = req.keyValue
            }

            if (onLoadData != null) {
              onLoadData(list)
            }

            if (onLoadingChange != null) {
              onLoadingChange(false)
            }

            resolve(list)
          } else {
            console.log(' -- error.code : ', code, message)
            const err = new Error(message)

            if (onLoadingChange != null) {
              onLoadingChange(false)
            }
            reject(err)
          }
        })
        .catch((e) => {
          console.log(' -- error : ', e)
          if (onLoadingChange != null) {
            onLoadingChange(false)
          }
          reject(e)
        })
    })

    return retPromise
  }

  self.more = () => {
    self.addReq({
      more: true,
    })
  }

  self.init = () => {
    self.addReq({
      keyValue: initKeyValue,
      readToEnd: self.options.readToEnd,
    })
  }
}

export function MsgBlock(api, initKeyValue, limit, manager) {
  const self = this

  self.api = api
  self.limit = limit || 20

  self.keyValue = initKeyValue

  self.prevBlock = null
  self.nextBlock = null

  self.messageList = []

  self.prevLoading = false
  self.nextLoading = false

  const prevOptions = {
    limit: self.limit,
    interval: 50,
    failInterval: 2000,
    infinite: false,
    //   infiniteDelay: options.infiniteDelay || 1000, // ms
    apiForQuery: self.api,
    sort: '_id desc', // prev는 역순..
    //   filters: options.filters,
    getRangeFilter: (isFirst, keyValue) => {
      // gt, lt, gte, lte 등 검색 영역에 따른 filter 생성 함수.
      // messageId를 filter 값으로 사용. 이전의 message를 얻어와야 함.
      if (isFirst === true) {
        if (keyValue != null) {
          return `_id lte ${keyValue}`
        }
        return null
      }
      return `_id lt ${keyValue}`
    },
    getList: (res) => {
      return res.data.data || []
    },
    getValueForSort: null,
    getKeyValue: (item) => {
      if (manager.view != null) {
        return manager.view.getKeyValue(item, 'id')
      }
      return null
    },
    isAsc: false,
    onLoadData: (data) => {
      // console.log(data)
      if (manager.view != null) {
        const end = manager.view.convertMessages(data)
        self.makeViewData(null, end, true)
      }
    },
    onLoadingChange: (val) => {
      self.prevLoading = val
      self.onLoadingChange()
    },
  }

  self.prevQueue = new MsgApiQueue(prevOptions, initKeyValue)
  if (initKeyValue != null) {
    const nextOptions = {
      limit: self.limit,
      interval: 50,
      failInterval: 2000,
      infinite: false,
      //   infiniteDelay: options.infiniteDelay || 1000, // ms
      apiForQuery: self.api,
      sort: '_id asc', // next는 순방향..
      //   filters: options.filters,
      getRangeFilter: (isFirst, keyValue) => {
        // gt, lt, gte, lte 등 검색 영역에 따른 filter 생성 함수.
        // messageId를 filter 값으로 사용. 다음의 message를 얻어와야 함.
        // next를 요청하는 경우 initKeyValue가 null인 경우는 없음.
        return `_id gt ${keyValue}`
      },
      getList: (res) => {
        return res.data.data || []
      },
      getValueForSort: null,
      getKeyValue: (item) => {
        if (manager.view != null) {
          return manager.view.getKeyValue(item, 'id')
        }
        return null
      },
      isAsc: true,
      onLoadData: (data) => {
        // console.log(data)
        if (manager.view != null) {
          const front = manager.view.convertMessages(data)
          self.makeViewData(front, null, true)
        }
      },
      onLoadingChange: (val) => {
        self.nextLoading = val
        self.onLoadingChange()
      },
    }

    self.nextQueue = new MsgApiQueue(nextOptions, initKeyValue)
  }

  self.makeViewData = (front, end, sort, skipMerge) => {
    manager.makeViewData(self, front, end, sort, skipMerge)
  }

  self.onLoadingChange = () => {
    const loading = this.nextLoading === true || this.prevLoading === true

    manager.onLoadingChange(loading)
  }

  self.getFirstMsg = () => {
    if (self.messageList.length > 0) {
      let idx = -1
      let tmp = null
      for (let i = 0; i < self.messageList.length; i += 1) {
        tmp = self.messageList[i]
        if (isNormalMsg(tmp) === true) {
          idx = i
          break
        }
      }

      if (idx > -1) {
        return self.messageList[idx]
      }
    }

    return null
  }

  self.getLastMsg = () => {
    if (self.messageList.length > 0) {
      let idx = -1
      let tmp = null
      for (let i = self.messageList.length - 1; i >= 0; i -= 1) {
        tmp = self.messageList[i]
        if (isNormalMsg(tmp) === true) {
          idx = i
          break
        }
      }

      if (idx > -1) {
        return self.messageList[idx]
      }
    }

    return null
  }

  self.getExistMsg = (msg) => {
    const existItem = this.messageList.find((item) => {
      if (item.id === msg.id) {
        return true
      }
      return false
    })

    return existItem
  }

  self.more = (dir) => {
    if (dir == null || dir === true || dir === 'up') {
      self.prevQueue.more()
    }

    if (self.nextQueue != null) {
      if (dir == null || dir === false || dir === 'down') {
        self.nextQueue.more()
      }
    }
  }

  self.stop = () => {
    self.prevQueue.stop()
    if (self.nextQueue != null) {
      self.nextQueue.stop()
    }
  }

  self.hasMore = (dir) => {
    let ret = false
    if (dir == null || dir === true || dir === 'up') {
      ret = self.prevQueue.hasMore
    }

    if (self.nextQueue != null) {
      if (dir == null || dir === false || dir === 'down') {
        ret = ret || self.nextQueue.hasMore
      }
    }

    return ret
  }

  self.init = () => {
    self.prevQueue.init()

    if (self.nextQueue != null) {
      self.nextQueue.init()
    }
  }
}

export function MsgBlockMgr(view, api) {
  // lastSendMsg, lastRecvMsg 등을 관리.
  // makeViewData 함수 관리.
  // 현재 선택된 block에 대한 wrapper 함수 필요.
  // message 영역 merge - 제거되는 block은 prev, next chain - null로 설정.
  // 등등등..

  const self = this

  self.api = api

  self.view = view

  // 현재 시간부터 update_time을 기준으로 로딩
  // 각 block에 존재하는 message는 update하고 없으면 처음 block에 추가.
  const recentOptions = {
    limit: 20,
    //   failInterval: options.failInterval || 2000,
    infinite: true,
    //   infiniteDelay: options.infiniteDelay || 1000, // ms
    apiForQuery: self.api,
    sort: 'update_time asc',
    //   filters: options.filters,
    getRangeFilter: (isFirst, keyValue) => {
      // gt, lt, gte, lte 등 검색 영역에 따른 filter 생성 함수.
      if (keyValue != null) {
        return `update_time gte ${keyValue}`
      }
      return null
    },
    getValueForSort: null,
    getKeyValue: (item) => {
      if (this.view != null) {
        return this.view.getKeyValue(item, 'time')
      }
      return null
    },
    isAsc: true,
    onLoadData: (data) => {
      // console.log(data)
      if (self.view != null) {
        const front = self.view.convertMessages(data)
        self.firstBlock.makeViewData(front, null, true, true)

        self.moreRecentQueue(false)
      }
    },
    onLoadingChange: () => {},
  }

  const curTime = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')

  self.recentQueue = new MsgApiQueue(recentOptions, curTime)

  self.lastSendMsg = null

  self.lastReadMsg = null

  self.firstBlock = new MsgBlock(self.api, null, null, self)

  self.curBlock = self.firstBlock

  self.recentQueueInterval = 1 * 60 * 1000

  self.close = () => {
    this.view = null
    self.recentQueue.stop()
    if (self.recentQueueTimer != null) {
      clearTimeout(self.recentQueueTimer)
    }
  }

  self.moreRecentQueue = (now) => {
    if (self.recentQueueTimer != null) {
      clearTimeout(self.recentQueueTimer)
    }
    if (now === true) {
      self.recentQueue.more()
    } else {
      self.recentQueueTimer = setTimeout(() => {
        self.recentQueue.more()
        self.recentQueueTimer = null
      }, self.recentQueueInterval)
    }
  }

  self.setRecentQueueInterval = (interval) => {
    self.recentQueueInterval = interval
  }

  self.findBlock = (messageId) => {
    if (messageId == null) {
      return this.firstBlock
    }

    let temp = this.firstBlock
    let { nextBlock } = temp

    // let firstMsg = temp.getFirstMsg()
    let lastMsg = null
    let lastMsgId = null
    let firstMsg = temp.getFirstMsg()
    let firstMsgId = firstMsg != null ? firstMsg.id : temp.keyValue
    do {
      lastMsg = temp.getLastMsg()
      lastMsgId = lastMsg != null ? lastMsg.id : temp.keyValue
      if (messageId <= firstMsgId && messageId >= lastMsgId) {
        return temp // 이미 존재하는 block 반환.
      }

      if (nextBlock == null) {
        break
      } else {
        firstMsg = nextBlock.getFirstMsg()
        firstMsgId = firstMsg != null ? firstMsg.id : nextBlock.keyValue

        if (messageId > firstMsgId) {
          break
        }
      }

      temp = temp.nextBlock
      nextBlock = temp.nextBlock
    } while (temp != null)

    return null
  }

  const setCurBlock = (block, isNew) => {
    if (self.curBlock !== block) {
      if (isNew !== true) {
        if (self.view != null) {
          self.view.initScroll()
          self.curBlock = block
          if (block.messageList.length > 0) {
            self.makeViewData(self.curBlock, null, null, true, true)
          }
        }
      } else {
        self.curBlock = block
      }
    }
  }

  self.moveToBlock = (messageId) => {
    if (messageId == null) {
      setCurBlock(this.firstBlock, false)
    } else {
      // 이미 block이 존재하는지 검사.
      let temp = this.firstBlock
      let { nextBlock } = temp

      // let firstMsg = temp.getFirstMsg()
      let lastMsg = null
      let lastMsgId = null
      let firstMsg = null
      let firstMsgId = null
      do {
        lastMsg = temp.getLastMsg()
        lastMsgId = lastMsg != null ? lastMsg.id : temp.keyValue
        if (messageId >= lastMsgId) {
          setCurBlock(temp, false)
          return temp // 이미 존재하는 block 반환.
        }

        if (nextBlock == null) {
          break
        } else {
          firstMsg = nextBlock.getFirstMsg()
          firstMsgId = firstMsg != null ? firstMsg.id : nextBlock.keyValue

          if (messageId > firstMsgId) {
            break
          }
        }

        temp = temp.nextBlock
        nextBlock = temp.nextBlock
      } while (temp != null)

      // 새로 추가되는 block.
      const newBlock = new MsgBlock(self.api, messageId, null, self)
      newBlock.prevBlock = temp
      temp.nextBlock = newBlock
      if (nextBlock != null) {
        newBlock.nextBlock = nextBlock
        nextBlock.prevBlock = newBlock
      }

      if (self.view != null) {
        self.view.initScroll()
        newBlock.init()

        setCurBlock(newBlock, true)
      }
    }

    return self.curBlock
  }

  self.mergeBlock = (block) => {
    if (self.firstBlock == null || block === self.firstBlock) {
      return
    }

    const firstMsg = block.getFirstMsg()
    const firstMsgId = firstMsg != null ? firstMsg.id : block.keyValue

    let tmpBlock = block
    let lastMsg = tmpBlock.prevBlock.getLastMsg()
    let lastMsgId = lastMsg != null ? lastMsg.id : tmpBlock.keyValue
    let changeCurBlock = false

    while (lastMsgId <= firstMsgId) {
      tmpBlock = tmpBlock.prevBlock
      if (self.curBlock === tmpBlock) {
        changeCurBlock = true
      }
      if (tmpBlock.prevBlock != null) {
        lastMsg = tmpBlock.prevBlock.getLastMsg()
        lastMsgId = lastMsg != null ? lastMsg.id : tmpBlock.keyValue
      } else {
        break
      }
    }

    if (self.view != null && tmpBlock !== block) {
      self.view.msgView.isScrollPosBottom = false
      self.makeViewData(block, tmpBlock.messageList, null, true, true)

      block.prevBlock = tmpBlock.prevBlock
      if (tmpBlock.prevBlock != null) {
        tmpBlock.prevBlock.nextBlock = block
      } else {
        self.firstBlock = block
      }

      if (changeCurBlock === true) {
        self.curBlock = block
      }
    }
  }

  self.makeViewData = (block, front, end, sort, skipMerge) => {
    const { messageList: viewData } = block
    if (self.view == null) {
      return
    }

    if (front != null) {
      front.forEach((msg) => {
        let pos = -1
        const existItem = viewData.find((item, idx) => {
          if (item.id != null && item.id === msg.id) {
            pos = idx
            return true
          }
          if (
            item.id == null &&
            item.origin != null &&
            msg.origin != null &&
            item.origin.content_id === msg.origin.content_id
          ) {
            pos = idx
            self.view.removeSendObj(item.origin.content_id)
            return true
          }
          return false
        })
        if (existItem == null) {
          viewData.unshift(msg)
          self.view.addDateMessage(msg, true)
        } else if (existItem.updateTime != null) {
          if (existItem.updateTime.valueOf() < msg.updateTime.valueOf()) {
            viewData.splice(pos, 1, msg)
          }
        }
      })
    }

    if (end != null) {
      end.forEach((msg) => {
        let pos = -1
        const existItem = viewData.find((item, idx) => {
          if (item.id != null && item.id === msg.id) {
            pos = idx
            return true
          }
          if (
            item.id == null &&
            item.origin != null &&
            msg.origin != null &&
            item.origin.content_id === msg.origin.content_id
          ) {
            pos = idx
            self.view.removeSendObj(item.origin.content_id)
            return true
          }
          return false
        })
        if (existItem == null) {
          self.view.addDateMessage(msg, false)
          viewData.push(msg)
        } else if (existItem.updateTime != null) {
          if (existItem.updateTime.valueOf() < msg.updateTime.valueOf()) {
            viewData.splice(pos, 1, msg)
          }
        }
      })
    }

    if (sort === true) {
      viewData.sort((a, b) => {
        if (a.id == null && b.id != null) {
          return -1
        }
        if (a.id != null && b.id == null) {
          return 1
        }
        if (a.timeMS > b.timeMS) {
          return -1
        }
        if (a.timeMS < b.timeMS) {
          return 1
        }
        return 0
      })
    }

    if (skipMerge !== true) {
      self.mergeBlock(block)
    }

    // console.log(' -- message list length : ', viewData.length)

    // 첫번째 block인 경우, send/read message 처리 필요.
    if (self.firstBlock === block) {
      const userEmail = getEnvParam('userEmail')
      const roomObj = self.view.chatObj.getRoomObj()
      self.lastSendMsg = null
      viewData.some((item) => {
        if (userEmail === item.senderId) {
          if (self.lastSendMsg == null && item.id != null) {
            self.lastSendMsg = item
          }
          if (roomObj.firstReadMsgId >= item.id) {
            self.lastReadMsg = item
            return true
          }
        }
        return false
      })

      if (viewData.length > 0) {
        const recentMsg = viewData.find((item) => {
          // if (item.type !== 'date' && item.type !== 'system' && item.id != null) {
          if (item.type !== 'date' && item.id != null) {
            // system message도 read를 보내야 함.
            return true
          }
          return false
        })

        if (recentMsg != null && recentMsg !== this.recentMessage) {
          self.recentMessage = recentMsg

          if (self.view != null) {
            self.view.notifyLastMessage(self.recentMessage, roomObj.ChatRoom.id)
          }
        }
      }
    }

    if (block === self.curBlock) {
      const viewDataObj = {
        chats: viewData,
        lastSent: self.lastSendMsg,
        lastRead: self.lastReadMsg,
      }
      self.view.onViewDataChanged(viewDataObj)
    }
  }

  self.forceUpdate = () => {
    if (self.view != null && self.curBlock != null) {
      const { messageList: viewData } = self.curBlock
      const viewDataObj = {
        chats: viewData,
        lastSent: self.lastSendMsg,
        lastRead: self.lastReadMsg,
      }
      self.view.onViewDataChanged(viewDataObj)
    }
  }

  self.onLoadingChange = (loading) => {
    console.log(' -- onLoadingChange ', loading)
    if (self.view != null) {
      self.view.setLoading(loading)
    }
  }

  self.more = (dir) => {
    self.curBlock.more(dir)
  }

  self.stop = () => {
    self.curBlock.stop()
  }

  self.hasMore = (dir) => {
    return self.curBlock.hasMore(dir)
  }

  self.isFirstBlock = () => {
    return self.curBlock === self.firstBlock
  }

  self.init = () => {
    if (self.view != null && self.view.onViewDataChanged) {
      const viewDataObj = {
        chats: [],
        lastSent: self.lastSendMsg,
        lastRead: self.lastReadMsg,
      }
      self.view.onViewDataChanged(viewDataObj)
    }

    self.firstBlock.init()
    self.recentQueue.init()
  }

  self.init()
}

const retObj = {
  creactReactObjMgr,
  creactCallBackMgr,
}

export default retObj
