import actions from './actions'

const initialState = {
  uiData: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_UI_DATA: {
      const newState = { ...state }
      newState.uiData = { ...newState.uiData, ...action.payload }
      return { ...newState }
    }
    default:
      return state
  }
}
