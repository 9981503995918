import NgqlUI from 'gql/ninegqlUI'

const ngqlUIInfo = NgqlUI

const envParam = {}

export function getRouterType() {
  return 'browser' // hash, browser
}

export function getDefaultPage() {
  return '/conversation'
}

export function setEnvParam(key, value) {
  envParam[key] = value
}

export function getEnvParam(key) {
  return envParam[key]
}

// export function setNgqlUIInfo(prop) {
//   ngqlUIInfo = prop;
// }
// setNgqlUIInfo(NgqlUI);

function DefaultArrayIFace() {
  this.nextToken = null

  this.getNodes = (resData) => {
    this.nextToken = resData != null ? resData.nextToken : null
    if (resData != null) {
      const arrayData = Object.entries(resData).find((item) => {
        if (Array.isArray(item[1]) === true) {
          return true
        }
        return false
      })

      if (arrayData != null) {
        return arrayData[1]
      }
      return [{ node: resData }]
    }
    return []
  }

  this.getArgument = (vars, key, defaultVal) => {
    if (key === 'after') {
      return vars.nextToken
    }
    return vars[key] != null ? vars[key] : defaultVal
  }

  this.setArgument = (vars, key, val) => {
    if (key === 'after') {
      vars.nextToken = val
      return
    }
    vars[key] = val
  }

  this.getMoreCursor = (/* resData, lastItemData */) => {
    // if (resData != null) {
    //   return resData.nextToken;
    // }
    // return null;
    return this.nextToken
  }

  this.getTotalCount = (/* resData */) => {
    return 0
  }
}

export function getDefaultArrayIFace() {
  return new DefaultArrayIFace()
}

export function getNgqlUIInfo() {
  return ngqlUIInfo
}

export function getNgqlConfig() {
  return {
    tableClickRange: 0,
  }
}
