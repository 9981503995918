import React from 'react'

import classNames from 'classnames'

import { getHighlightedText, getMemberInfo } from 'pages/chat/component/chatView/data/cUtil'

export function getMemberElem(emailTxt, member, styles, hideEmail) {
  const memberInfo = getMemberInfo(member, emailTxt)

  const { avatarSrc, name } = memberInfo

  let emailElem = ''

  const nameProps = {}

  if (hideEmail === true) {
    nameProps[styles.noEmail] = true
  } else {
    emailElem = (
      <div className={styles.email}>
        <span>{emailTxt}</span>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={styles.avatar}>
        <img src={`${avatarSrc}`} alt={name} key={emailTxt} />
      </div>
      {emailElem}
      <div className={classNames(styles.name, nameProps)}>
        <span>{name}</span>
      </div>
    </React.Fragment>
  )
}

export function getHighlightMemberElem(emailTxt, member, styles, highlightText) {
  const memberInfo = getMemberInfo(member, emailTxt, null)

  const { avatarSrc, name } = memberInfo

  const email = getHighlightedText(member.email, highlightText)
  const displayName = getHighlightedText(name, highlightText)

  return (
    <React.Fragment>
      <div className={styles.avatar}>
        <img src={`${avatarSrc}`} alt={name} key={member.email} />
      </div>
      <div className={styles.email}>
        <span>{email}</span>
      </div>
      <div className={styles.name}>
        <span>{displayName}</span>
      </div>
    </React.Fragment>
  )
}

const memberUtil = {
  getMemberElem,
  getHighlightMemberElem,
}

export default memberUtil
