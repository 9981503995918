import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import classNames from 'classnames'
import { connect } from 'react-redux'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  menuLayoutType: settings.menuLayoutType,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  // leftMenuWidth: settings.leftMenuWidth,
})

let touchStartPrev = 0
let touchStartLocked = false

const Menu = ({
  dispatch,
  isMobileMenuOpen,
  menuLayoutType,
  isMobileView,
  // leftMenuWidth
}) => {
  const [mobileMenuState, setMobileMenuState] = useState(isMobileMenuOpen)

  useEffect(() => {
    applyMobileMenuState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileMenuOpen])

  const applyMobileMenuState = () => {
    setMobileMenuState(isMobileMenuOpen)
  }

  useEffect(() => {
    // mobile menu touch slide opener
    const unify = (e) => {
      return e.changedTouches ? e.changedTouches[0] : e
    }
    document.addEventListener(
      'touchstart',
      (e) => {
        const x = unify(e).clientX
        touchStartPrev = x
        touchStartLocked = x > 70
      },
      { passive: false },
    )
    document.addEventListener(
      'touchmove',
      (e) => {
        const x = unify(e).clientX
        const prev = touchStartPrev
        if (x - prev > 50 && !touchStartLocked) {
          toggleMobileMenu()
          touchStartLocked = true
        }
      },
      { passive: false },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleMobileMenu = () => {
    setMobileMenuState(!mobileMenuState)
  }

  const setMobileMenuOpen = (value) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value,
        // value: false,
      },
    })
  }

  const GetMenu = () => {
    if (isMobileView) {
      // menuWidth = 80 + 376;

      const drawerAttrs = {
        closable: false,
        open: mobileMenuState,
        afterOpenChange: (open) => {
          setMobileMenuOpen(open)
        },
        placement: 'left',
        className: styles.mobileMenu,
        onClose: () => {
          setMobileMenuOpen(false)
        },
        maskClosable: true,
        getContainer: null,
        // width: menuWidth
      }

      return (
        <div>
          <div
            className={styles.handler}
            onClick={toggleMobileMenu}
            onFocus={(e) => {
              e.preventDefault()
            }}
            onKeyDown={toggleMobileMenu}
            role="button"
            tabIndex="0"
          >
            <div className={styles.handlerIcon} />
          </div>
          <Drawer {...drawerAttrs}>
            <MenuLeft />
            <div className={classNames('menuPortal')} id="portalLeftSideMenu" />
          </Drawer>
        </div>
      )
    }
    if (menuLayoutType === 'top') {
      return <MenuTop />
    }
    if (menuLayoutType === 'nomenu') {
      return null
    }
    return <MenuLeft />
  }

  return GetMenu()
}

export default connect(mapStateToProps)(Menu)
