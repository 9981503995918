import React from 'react'
import Viewer from 'viewerjs'

import 'viewerjs/dist/viewer.css'

class ImageViewer extends React.Component {
  viewer = null

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //   }
  // }

  componentDidMount() {}

  // componentDidUpdate(prevProps /* , prevState */) {
  // }

  componentWillUnmount() {
    if (this.viewer == null) {
      this.viewer.destroy()
    }
  }

  onSetRef = (ref) => {
    const { onClose } = this.props

    if (ref !== this.prevRef) {
      if (this.prevRef != null && this.viewer != null) {
        this.viewer.destroy()
      }

      if (ref != null) {
        this.viewer = new Viewer(ref, {
          inline: false,
          viewed() {
            this.viewer.zoomTo(1)
          },
        })

        ref.addEventListener('hidden', () => {
          if (onClose != null) {
            onClose()
          }
        })

        this.viewer.show()
      }

      this.prevRef = ref
    } else if (this.viewer != null) {
      this.viewer.update()
    }
  }

  render() {
    const { images } = this.props

    const children = images.map((item) => {
      return (
        <li key={item.src}>
          <img src={item.src} alt={item.alt} />
        </li>
      )
    })

    return <ul ref={this.onSetRef}>{children}</ul>
  }
}

export default ImageViewer
