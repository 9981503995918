import moment from 'moment'
import {
  getRoomList2,
  getRoom,
  getRoomMembers,
  // getRoomMessages,
} from 'pages/conversations/component/roomUtil'

// import mailApi from 'services/mailApi'

import { addWSListener, removeWSListener } from 'services/websocket'
import { store as reduxStore } from 'index'

import cUtil from './cUtil'

// cbFunc(roomList, state, dataManager)
// state - 0 - initial, 1 - loading, 2 - all loaded, 3 - updated,
function RoomListLoader(dataMgr, cbFunc, readToLast) {
  const self = this

  self.dataMgr = dataMgr
  self.cbFunc = cbFunc
  self.readToLast = readToLast !== false

  self.limit = 20

  self.firstUpdateTime = null
  self.calledFirstUpdateTime = undefined
  self.lastUpdateTime = moment().toISOString()
  self.calledlastUpdateTime = undefined
  self.hasMoreData = self.readToLast !== true

  self.isStarted = false

  self.start = () => {
    if (self.isStarted !== true) {
      innerGetRoomList()
    }
  }

  self.moreRoomList = () => {
    console.log('-- dataMgr : moreRoomList ')
    if (self.hasMoreData === true) {
      innerGetRoomList()
    }
  }

  self.hasMore = () => {
    return self.hasMoreData
  }

  self.getRoom = (roomId) => {
    const promise = new Promise((resolve, reject) => {
      getRoom(roomId)
        .then((res) => {
          const { code, message, data } = res.data
          if (code === 200) {
            self.processRoomInfo(data, true)
            resolve(data)
          } else {
            reject(new Error(message))
          }
        })
        .catch((err) => {
          reject(err)
        })
    })

    return promise
  }

  self.processRoomInfo = (item, useNotify) => {
    const { roomMap, roomList } = self.dataMgr

    const roomId = item.ChatRoom.id
    if (roomMap[roomId] == null) {
      roomMap[roomId] = item
      roomList.push(item)

      // self.dataMgr.getRoomMemberList(roomId)

      // getRoomMessages(roomId, null, null, 1).then((res2) => {
      //   console.log(res2)

      //   const dataList = res2.data.data

      //   if (dataList.length > 0) {
      //     const msgItem = cUtil.getMsgObjFromRoomObj(dataList[0])
      //     self.dataMgr.setLastMsg(roomId, msgItem)
      //   }
      // })
      roomMap[roomId].loadedTime = Date.now()
    } else {
      // object는 그대로 유지, 내부의 데이터만 교체. lastMsg가 유지되고, roomList가 유지되도록 함.
      Object.entries(item).forEach(([key, value]) => {
        roomMap[roomId][key] = value
      })
      // roomMap[roomId].ChatRoom = item.ChatRoom
      roomMap[roomId].ChatRoomKey = item.ChatRoomKey // chatRoomKey == null 인 경우도 있음.
      // @todo - 화면 update에 관련된 데이터가 변경된 경우에만 loadedTime이 변경되도록 수정하자.
      roomMap[roomId].loadedTime = Date.now()
    }

    if (item.ChatRoomKey != null) {
      roomMap[roomId].pmId64 = item.ChatRoomKey.primary_id
    }

    if (item.ChatRoom.last_message != null) {
      const msgItem = cUtil.getMsgObjFromRoomObj(item)
      self.dataMgr.setLastMsg(roomId, msgItem)
    }

    if (item.ChatRoom.members != null) {
      self.dataMgr.applyMemberList(roomId, item.ChatRoom.members)
    }

    if (useNotify === true && self.cbFunc != null) {
      self.cbFunc(roomList, 2)
    }
  }

  const innerGetRoomList = () => {
    // 이미 호출된 경우에는 다시 호출하지 않음.
    if (self.calledFirstUpdateTime !== self.firstUpdateTime) {
      self.calledFirstUpdateTime = self.firstUpdateTime
      self.isStarted = true
    } else {
      return
    }

    const queries = {
      skip: 0, // updateTime이 변경되므로 skip은 항상 0.
      limit: self.limit,
    }

    queries.filters = []
    queries.sort = `updated_at desc`

    if (self.firstUpdateTime != null) {
      queries.filters.push(`updated_at lte ${self.firstUpdateTime}`)
    }

    getRoomList2(queries).then((res) => {
      const { data: originData } = res.data

      const { roomList } = self.dataMgr

      const data = originData.filter((item) => {
        return item.ChatRoom.status === 1
      })

      if (data != null && data.length > 0) {
        self.firstUpdateTime = data[data.length - 1].ChatRoom.updated_at

        if (self.lastUpdateTime == null || self.lastUpdateTime < data[0].ChatRoom.updated_at) {
          self.lastUpdateTime = data[0].ChatRoom.updated_at
        }

        data.forEach((item, idx) => {
          self.processRoomInfo(item, idx === data.length - 1)
        })
      }

      // 데이터가 하나라도 있으면 한번더 호출해보도록 함.
      self.hasMoreData = data.length > 0 // data.length === self.limit

      console.log('check : data Length --- ', data.length)

      if (self.hasMoreData === true) {
        if (self.cbFunc != null) {
          self.cbFunc(roomList, 1)
        }
        if (self.readToLast !== false) {
          setTimeout(() => {
            innerGetRoomList()
          }, 50)
        }
      } else if (self.cbFunc != null) {
        self.cbFunc(roomList, 2)
      }
    })
  }

  self.getRecentRoomList = () => {
    if (this.recentRoomListTimer != null) {
      clearTimeout(this.recentRoomListTimer)
    }

    this.recentRoomListTimer = setTimeout(() => {
      self.innerGetRecentRoomList()
      this.recentRoomListTimer = null
    }, 300)
  }

  self.innerGetRecentRoomList = () => {
    const queries = {
      skip: 0, // updateTime이 변경되므로 skip은 항상 0.
      limit: self.limit,
    }

    queries.filters = []
    queries.sort = `updated_at asc`

    if (self.lastUpdateTime != null) {
      queries.filters.push(`updated_at gte ${self.lastUpdateTime}`)
    }

    getRoomList2(queries).then((res) => {
      // const { data: originData } = res.data
      const { data } = res.data

      const { roomList } = self.dataMgr

      // 지워진 room은 status가 0이 됨. 변화 상태를 알아야 하므로, 여기서는 filtering 이 되며 안됨.
      // 실제 리스트를 그릴 때, filtering이 필요함.
      // const data = originData.filter((item) => {
      //   return item.ChatRoom.status === 1
      // })

      if (data != null && data.length > 0) {
        if (
          self.lastUpdateTime == null ||
          self.lastUpdateTime < data[data.length - 1].ChatRoom.updated_at
        ) {
          self.lastUpdateTime = data[data.length - 1].ChatRoom.updated_at
        }

        data.forEach((item, idx) => {
          self.processRoomInfo(item, idx === data.length - 1)
        })
      }

      if (data.length === self.limit) {
        setTimeout(() => {
          self.getRecentRoomList()
        }, 50)
      } else if (self.cbFunc != null) {
        self.cbFunc(roomList, 2)
      }
    })
  }

  // if (self.readToLast === true) {
  //   innerGetRoomList()
  // }
  // innerGetRoomList()
}

// cbFunc(roomId, memberList, state, dataManager)
// roomId가 있으면, room에 포함된 memberList를 반환, roomId가 없으면 전체 memberList를 반환.
// state - 0 - initial, 1 - loading, 2 - all loaded, 3 - updated,
function MemberListLoader(dataMgr, roomId, cbFunc, readToLast) {
  const self = this

  self.roomId = roomId
  self.dataMgr = dataMgr
  self.cbFunc = cbFunc
  self.readToLast = readToLast

  // self.limit = 20;

  // self.lastUpdateTime = null;
  // self.hasMoreData = false;

  // self.moreRoomList = () => {
  //   if (self.hasMoreData === true) {
  //     innerGetRoomMemberList();
  //   }
  // }

  // self.hasMore = () => {
  //   return self.hasMoreData
  // }

  const innerGetRoomMemberList = () => {
    getRoomMembers(self.roomId).then((res) => {
      const { data } = res.data

      self.dataMgr.applyMemberList(self.roomId, data, self.cbFunc)
    })
  }

  const { roomMap } = self.dataMgr

  // room member 정보 초기화. (방에 처음 들어갈 때, 정보 다시 읽어오도록 함.)
  if (roomMap[self.roomId] == null) {
    roomMap[self.roomId] = {}
  }

  initRoomObj(roomMap[self.roomId])

  innerGetRoomMemberList()
}

export function getFirstReadMessgeId(members) {
  let msgId = null

  members.forEach((member) => {
    if (msgId == null) {
      msgId = member.last_message_id
    } else {
      msgId = msgId < member.last_message_id ? msgId : member.last_message_id
    }
  })

  return msgId
}

export function initRoomObj(roomObj) {
  if (roomObj.roomMemberMap == null) {
    if (roomObj.ChatRoom != null && roomObj.ChatRoom.members != null) {
      roomObj.roomMemberList = [...roomObj.ChatRoom.members]
      roomObj.roomMemberMap = {}
      roomObj.roomMemberList.forEach((member) => {
        roomObj.roomMemberMap[member.email] = member
      })
      roomObj.firstReadMsgId = getFirstReadMessgeId(roomObj.roomMemberList)
      roomObj.pmId64 = roomObj.ChatRoomKey.primary_id
      roomObj.loadedTime = Date.now()
    } else {
      roomObj.roomMemberMap = {}
      roomObj.roomMemberList = []
    }
  }
}

function DataManager() {
  const self = this

  self.listenerList = []

  self.roomList = []
  self.roomMap = {}

  const processRoomObj = (roomId, roomObj, onResult, loadMember, reloadMember) => {
    let isEnd = false

    if (roomObj.roomMemberMap == null) {
      initRoomObj(roomObj)

      if (loadMember !== false) {
        self.getRoomMemberList(roomId, onResult)
        isEnd = true
      }
    } else if (reloadMember === true) {
      if (loadMember !== false) {
        self.getRoomMemberList(roomId, onResult)
        isEnd = true
      }
    }

    if (isEnd === true) {
      return
    }

    if (onResult != null) {
      onResult(roomObj)
    }
  }

  self.roomInfoListenerMap = {}

  self.getRoomObj = (roomId, onResult, loadMember, reloadMember, reloadRoom) => {
    let roomObj = self.roomMap[roomId]
    if (roomObj == null) {
      roomObj = {}
      self.roomMap[roomId] = roomObj
      self.roomList.push(roomObj)

      self.updateRoomList(false)

      self.roomLoader.getRoom(roomId).then((room) => {
        processRoomObj(
          roomId,
          room,
          () => {
            if (onResult != null) {
              onResult(room)
            }
            if (self.roomInfoListenerMap[roomId] != null) {
              self.roomInfoListenerMap[roomId].forEach((func) => {
                func()
              })
              delete self.roomInfoListenerMap[roomId]
            }
          },
          loadMember,
          reloadMember,
        )
      })

      return roomObj
    }

    if (reloadRoom === true) {
      self.roomLoader.getRoom(roomId).then((room) => {
        processRoomObj(
          roomId,
          room,
          () => {
            if (onResult != null) {
              onResult(room)
            }
          },
          loadMember,
          reloadMember,
        )
      })
    } else {
      const processFunc = () => {
        processRoomObj(
          roomId,
          roomObj,
          () => {
            if (onResult != null) {
              onResult(roomObj)
            }
          },
          loadMember,
          reloadMember,
        )
      }

      if (roomObj.ChatRoom == null) {
        // 로딩이 아직 안된 상태이지만 로딩 요청 중...
        if (self.roomInfoListenerMap[roomId] == null) {
          self.roomInfoListenerMap[roomId] = []
        }
        self.roomInfoListenerMap[roomId].push(processFunc)
      } else {
        processFunc()
      }
    }

    return roomObj
  }

  // memberList와 memberMap에서 관리하는 member 정보는 실제 member obj를 그대로 넣는 것이 아니라, 사본을 넣어야 함.
  // 원본을 관리하게 되면, roomMap, roomList에서 관리하는 실제 object가 변경되어버리는 일이 발생함.
  self.globalMemberList = []
  self.globalMemberMap = {}

  self.roomListListeners = []
  self.roomMemberListeners = []

  self.clearMemberList = () => {
    self.globalMemberList = []
    self.globalMemberMap = {}
  }

  self.setMeData = (user) => {
    this.myMemberData = {
      display_name: user.name,
      avatar_url: user.avatar,
      email_address: user.email,
      email: user.email,
    }

    if (self.globalMemberMap[this.myMemberData.email] == null) {
      self.globalMemberList.push(this.myMemberData)
      self.globalMemberMap[this.myMemberData.email] = this.myMemberData
    }
  }

  self.clearMemberList() // memberList 초기화. 자기 자신의 이메일을 추가해야 함..

  self.clear = () => {
    self.clearRoomList()
    self.clearMemberList()
  }

  self.clearRoomList = () => {
    self.roomList = []
    self.roomMap = {}

    delete self.roomLoader
    self.roomLoader = null

    self.lastRoomUpdateTime = null
    self.varHasMoreRoom = false
  }

  self.getRoomListLoader = (readToLast, remake) => {
    if (remake === true && self.roomLoader != null) {
      delete self.roomLoader
      self.roomLoader = null
    }

    if (self.roomLoader == null) {
      self.roomLoader = new RoomListLoader(
        self,
        (roomList, state) => {
          self.notifyRoomListListener(roomList, state)
        },
        readToLast,
      )
    }

    return self.roomLoader
  }

  self.updateRoomList = (readToLast) => {
    if (self.roomLoader == null) {
      self.getRoomListLoader(readToLast)
    }
    self.roomLoader.start()
    self.roomLoader.getRecentRoomList()
  }

  self.notifyRoomListListener = (roomList, state, updatedRoom) => {
    // console.log(' ---- notifyRoomListListener ', roomList)

    let cnt = 0
    roomList.forEach((room) => {
      if (room.Member != null && room.Member.unread_message_exist > 0) {
        cnt += 1 // room.Member.unread_message_exist;
      }
    })

    reduxStore.dispatch({
      type: 'user/SET_STATE',
      payload: {
        unreadCnt: cnt,
      },
    })

    self.roomListListeners.forEach((listener) => {
      listener(roomList, state, updatedRoom, self)
    })
  }

  self.addRoomListListener = (listener) => {
    if (self.roomListListeners.indexOf(listener) < 0) {
      self.roomListListeners.push(listener)

      // initial call
      listener(self.roomList, 0, null, self)
    }
  }

  self.removeRoomListListener = (listener) => {
    const pos = self.roomListListeners.indexOf(listener)
    if (pos >= 0) {
      self.roomListListeners.splice(pos, 1)
    }
  }

  self.getRoomMemberList = (chatRoomId, onResult) => {
    if (self.memberLoader != null) {
      delete self.memberLoader
    }

    self.memberLoader = new MemberListLoader(self, chatRoomId, (roomId, memberList, state) => {
      const { roomMap } = self
      if (roomId != null && roomMap[roomId] != null) {
        roomMap[roomId].firstReadMsgId = getFirstReadMessgeId(memberList)
      }

      if (onResult != null) {
        onResult(memberList, chatRoomId, state)
      }
    })

    return self.memberLoader
  }

  self.exceptKeys = [
    'chat_room_id',
    'last_message_id',
    'type',
    // 'loadedTime'
  ]

  self.applyMemberList = (roomId, data, cbFunc) => {
    const { roomMap, globalMemberMap, globalMemberList } = self

    const newList = []
    const changedList = []
    if (data.length > 0) {
      self.lastUpdateTime = data[data.length - 1].update_at

      data.forEach((item) => {
        const memberId = item.email
        if (globalMemberMap[memberId] == null) {
          const time = Date.now()
          const globalMemberItem = {
            ...item,
            loadedTime: time,
          }

          if (globalMemberItem.type === 'guest') {
            globalMemberItem.isGuest = true
          }

          self.exceptKeys.forEach((key) => {
            delete globalMemberItem[key]
          })
          self.globalLoadedTime = time
          delete globalMemberItem.owner // owner 정보는 room 마다 달라지므로 여기에서는 제거.
          globalMemberMap[memberId] = globalMemberItem
          // if (item.avatar_url === null) {
          //   // 다른 사람의 정보는 access할 수 없음.
          //   mailApi.getProfilePhoto(item.email).then((officeAvatarBlob) => {
          //     // console.log(' office avatar - ', officeAvatar);
          //     item.avatar_url = window.URL.createObjectURL(officeAvatarBlob);
          //   });
          // }
          globalMemberList.push(globalMemberItem)
        } else {
          // object는 그대로 유지, 내부의 데이터만 교체.
          let isChanged = false

          if (globalMemberMap[memberId].type === 'guest') {
            globalMemberMap[memberId].isGuest = true
          }

          Object.entries(item).forEach(([key, value]) => {
            if (self.exceptKeys.indexOf(key) < 0) {
              if (globalMemberMap[memberId][key] !== value) {
                globalMemberMap[memberId][key] = value
                isChanged = true
              }
            }
          })

          if (isChanged === true) {
            const time = Date.now()
            globalMemberMap[memberId].loadedTime = time
            self.globalLoadedTime = time
          }
        }

        // member map의 정보를 그대로 roomMap에 적용하면 room마다 구분되는 고유 정보에 문제가 발생함.
        // ex) owner는 방마다 달라짐.
        // const oldObj = globalMemberMap[memberId];

        const oldObj = item

        if (roomMap[roomId] == null) {
          roomMap[roomId] = {}
        }

        if (item.type === 'owner') {
          roomMap[roomId].owner = oldObj // 소유자 member obj.
        }

        initRoomObj(roomMap[roomId])

        if (roomMap[roomId].roomMemberMap[memberId] == null) {
          const time = Date.now()
          const memberObj = {
            ...oldObj,
            loadedTime: time,
          }
          roomMap[roomId].roomMemberMap[memberId] = memberObj
          roomMap[roomId].roomMemberList.push(memberObj)
          roomMap[roomId].loadedTime = time

          newList.push(memberObj)
        } else {
          let isChanged = false
          Object.entries(item).forEach(([key, value]) => {
            if (key !== 'loadedTime') {
              if (roomMap[roomId].roomMemberMap[memberId][key] !== value) {
                roomMap[roomId].roomMemberMap[memberId][key] = value
                isChanged = true
              }
            }
          })

          if (isChanged === true) {
            roomMap[roomId].firstReadMsgId = getFirstReadMessgeId(roomMap[roomId].roomMemberList)

            const time = Date.now()
            roomMap[roomId].roomMemberMap[memberId].loadedTime = time
            roomMap[roomId].loadedTime = time

            changedList.push(roomMap[roomId].roomMemberMap[memberId])
          }
        }
      })
    }

    let changeProps = null
    if (newList.length > 0 || changedList.length > 0) {
      changeProps = {
        newList,
        changedList,
      }
    }

    self.notifyRoomMemberListener(roomId, roomMap[roomId].roomMemberList, 2, changeProps)
    if (cbFunc != null) {
      cbFunc(roomId, roomMap[roomId].roomMemberList, 2, changeProps)
    }
  }

  self.applyMemberRead = (data) => {
    const { chat_room_id: roomId, message_id: messageId, who: memberId } = data

    self.getRoomObj(roomId, (roomObj) => {
      if (roomObj.roomMemberMap[memberId] != null) {
        const member = roomObj.roomMemberMap[memberId]
        const time = Date.now()
        member.last_message_id = messageId
        member.loadedTime = time

        const changeProps = {
          changedList: [member],
        }

        roomObj.firstReadMsgId = getFirstReadMessgeId(roomObj.roomMemberList)

        self.notifyRoomMemberListener(roomId, roomObj.roomMemberList, 2, changeProps)
      }
    })
  }

  self.notifyRoomMemberListener = (roomId, memberList, state, changeProps) => {
    self.roomMemberListeners.forEach((listener) => {
      if (listener.roomId != null) {
        if (listener.roomId === roomId) {
          self.getRoomObj(listener.roomId, (roomObj) => {
            listener.cbFunc(listener.roomId, roomObj.roomMemberList, state, self, changeProps)
          })
        }
      } else if (typeof listener === 'function') {
        listener(null, self.globalMemberList, 0, self, changeProps)
      } else if (listener.cbFunc != null) {
        listener.cbFunc(null, self.globalMemberList, 0, self, changeProps)
      }
    })
  }

  self.addRoomMemberListener = (listener) => {
    if (self.roomMemberListeners.indexOf(listener) < 0) {
      self.roomMemberListeners.push(listener)

      // initial call
      if (listener.roomId != null) {
        self.getRoomObj(listener.roomId, (roomObj) => {
          initRoomObj(roomObj)
          listener.cbFunc(listener.roomId, roomObj.roomMemberList, 0, self)
        })
      } else if (typeof listener === 'function') {
        listener(null, self.globalMemberList, 0, self)
      } else if (listener.cbFunc != null) {
        listener.cbFunc(null, self.globalMemberList, 0, self)
      }
    }
  }

  self.removeRoomMemberListener = (listener) => {
    const pos = self.roomMemberListeners.indexOf(listener)
    if (pos >= 0) {
      self.roomMemberListeners.splice(pos, 1)
    }
  }

  if (self.onmessage != null) {
    removeWSListener(self.onmessage)
  }

  self.setLastMsg = (roomId, msgItem) => {
    // lastMsg는 해당 chat room이 열리지 않아도 호출될 수 있으므로, 이 때 member를 새로 로딩하는 것은 낭비임.
    self.getRoomObj(
      roomId,
      (roomObj) => {
        // const {
        //   create_time: time,
        //   update_time: updateTime
        // } = msgItem.origin;

        // if (time !== updateTime) { // update된 메시지인 경우. 마지막 문자열이 아니다.
        //   // 마지막 메시지와 id가 같으면 마지막 메시지임.
        //   if (roomObj.lastMsg != null && roomObj.lastMsg.id !== msgItem.id) {
        //     return;
        //   }
        // }

        roomObj.lastMsg = msgItem
        if (msgItem != null) {
          roomObj.ChatRoom.last_message = msgItem.origin
        }

        self.notifyRoomListListener(self.roomList, 3, roomObj)
      },
      false,
    )
  }

  self.addListener = () => {
    if (self.onmessage != null) {
      removeWSListener(self.onmessage)
    }

    self.onmessage = {
      // filters: {
      //   cmd: 'message',
      //   chat_room_id: self.roomInfo.chatRoomId,
      // },
      cbFunc: (cmd, data) => {
        // your subscription stuff
        console.log(' - dataMgr : onmessage - ', cmd, data)

        switch (cmd) {
          case 'message':
            {
              const { chat_room_id: roomId } = data

              const msgItem = cUtil.getMsgObjFromWS(null, data)
              self.setLastMsg(roomId, msgItem)
            }
            break
          case 'system': {
            const { system } = data
            switch (system.type) {
              case 'add_member':
              case 'enter_member':
              case 'leave_member':
              case 'update_chat_room': {
                self.updateRoomList()
                self.getRoomMemberList(data.chat_room_id)

                // system message는 chat list에 표시하지 말자.
                const msgItem = cUtil.getMsgObjFromWS(null, data)
                self.setLastMsg(data.chat_room_id, msgItem)
                break
              }
              default:
            }
            break
          }
          case 'chat_room': {
            // chat_room:{"workspace_id": 1, "chat_room_id": 275, "unread_message_exist": 1}
            // message의 setLastMsg 에서 동일한 처리를 하게 됨.
            const { chat_room_id: roomId, unread_message_exist: unreadCnt } = data

            console.log(' - room unread state - ', roomId, unreadCnt)

            // last_message_id 확인을 위해 member도 다시 로딩되어야 함.
            self.getRoomObj(
              roomId,
              (roomObj) => {
                if (roomObj.Member != null) {
                  roomObj.Member.unread_message_exist = unreadCnt
                }
                self.notifyRoomListListener(self.roomList, 3, roomObj)
              },
              true,
              true,
              false,
            )
            break
          }
          case 'read': {
            self.applyMemberRead(data)
            break
          }
          case 'comment':
            // if (data.parent_object_type === 'message' && data.parent_object_id != null) {
            // }
            break
          case 'common':
            // {
            //   const { type, code, message: msg } = data
            //   console.log(' -- websocket - ', type, code, msg)
            //   if (type === 'socket' && code === 1) {
            //     // socket opened.
            //     self.getRecentMsg()
            //   }
            //   // } else if (data.code === 0) { // socket closed.
            //   //   console.log(' -- websocket - ', data.message);
            //   // }
            // }
            break
          default:
            console.log('unknown cmd - ', cmd)
        }
      },
    }

    addWSListener(self.onmessage)
  }

  self.selectedObj = null
  self.prevObj = null
  self.selectObjListeners = []

  self.setSelectedObj = (obj, commentId) => {
    self.selectedObj = obj

    self.notifySelectObjChanged(obj, commentId)

    if (obj != null) {
      obj.reactMgr.forceUpdate()
    }

    if (self.prevObj != null) {
      self.prevObj.reactMgr.forceUpdate()
    }

    self.prevObj = obj
  }

  self.getSelectedObj = () => {
    return self.selectedObj
  }

  self.notifySelectObjChanged = (selectedObj, commentId) => {
    self.selectObjListeners.forEach((listener) => {
      listener(selectedObj, commentId, self)
    })
  }

  self.addSelectObjListener = (listener) => {
    if (self.selectObjListeners.indexOf(listener) < 0) {
      self.selectObjListeners.push(listener)

      listener(self.selectedObj, self)
    }
  }

  self.removeSelectObjListener = (listener) => {
    const pos = self.selectObjListeners.indexOf(listener)
    if (pos >= 0) {
      self.selectObjListeners.splice(pos, 1)
    }
  }

  self.addListener()
}

const dataManager = new DataManager()

export default dataManager
