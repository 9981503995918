import React from 'react'
import { List, Spin, Tooltip } from 'antd'

import FTIcon from 'components/FTIcon'

import classNames from 'classnames'
import dataMgr from 'pages/chat/component/chatView/data/dataManager'

import { timeFormat, formatBytes } from 'utils'

import styles from '../style.module.scss'

class ImageItemDef extends React.Component {
  state = {}

  componentDidMount() {
    const { attachment, chatObj } = this.props

    attachment.reactMgr.addReactObj(this)

    attachment.loadThumbFile(chatObj)
  }

  componentWillUnmount() {
    const { attachment } = this.props

    attachment.reactMgr.removeReactObj(this)
  }

  setFileElem = (ref) => {
    if (ref != null) {
      // console.log(' ---- ref ---- ', ref, ref.innerText)
      const elems = ref.querySelectorAll('span')
      if (elems.length > 0) {
        setTimeout(() => {
          // const w = elems[1].offsetWidth // + 1
          let w = elems[1].offsetWidth // + 1;
          const wStr = window.getComputedStyle(elems[1], null).width
          if (wStr.substring(wStr.length - 2) === 'px') {
            const num = wStr.substring(0, wStr.length - 2)
            w = window.parseFloat(num)
          }
          if (ref.offsetWidth > w) {
            elems[1].style.maxWidth = `${w}px`
            elems[0].style.maxWidth = `calc(100% - ${w}px)`
          }
        }, 100)
      }
    }
  }

  onClick = () => {
    const { attachment, chatObj, fileUtil } = this.props

    const { info } = attachment
    const { filedigest, filename, filesize, mimetype, dimension } = info

    if (mimetype != null) {
      if (mimetype.indexOf('image') === 0) {
        fileUtil.showImage(chatObj, filedigest, filename, filesize, dimension, mimetype)
      } else if (mimetype.indexOf('video') === 0 || mimetype.indexOf('audio') === 0) {
        fileUtil.showVideo(chatObj, filedigest, filename, filesize, dimension, mimetype)
      } else {
        fileUtil.confirmDownload(chatObj, filedigest, filename)
      }
    }
  }

  render = () => {
    const { thumbUrl, thumbLoading } = this.state

    const { attachment, setRef } = this.props

    if (attachment == null) {
      return ''
    }

    const { origin: item } = attachment.info

    const memberMap = dataMgr.globalMemberMap

    const txt = item.file_name

    let pos = txt.lastIndexOf('.')

    if (pos < 0) {
      pos = txt.length
    }

    const front = txt.substring(0, pos)
    const end = txt.substring(pos)

    const filenameElem = (
      <div
        ref={(ref) => {
          this.setFileElem(ref)
        }}
        className={classNames(styles.fileName)}
      >
        <span className={styles.front}>{front}</span>
        <span className={styles.end}>{end}</span>
      </div>
    )

    const dateStr = timeFormat(item.create_time, 'MMM D')

    const owner = memberMap[item.sender] != null ? memberMap[item.sender].display_name : item.sender

    const descElem = (
      <div className={styles.fileDesc}>
        <span>{dateStr}</span>&nbsp;&#x2022;&nbsp;
        <span>{formatBytes(item.file_size, 0)}</span>&nbsp;&#x2022;&nbsp;
        <span>{owner}</span>
      </div>
    )

    let fileIcon = null

    if (thumbUrl != null) {
      fileIcon = (
        <div ref={setRef} className={styles.fileIcon}>
          <img src={thumbUrl} alt={txt} />
        </div>
      )
    } else if (thumbLoading === true) {
      fileIcon = (
        <div ref={setRef} className={styles.fileIcon}>
          <Spin />
        </div>
      )
    } else {
      fileIcon = (
        <div ref={setRef} className={styles.fileIcon}>
          <FTIcon colorType="multiColor" fileName={item.file_name} />
        </div>
      )
    }

    const toolTipElem = (
      <React.Fragment>
        {filenameElem}
        {descElem}
      </React.Fragment>
    )

    return (
      <List.Item key={item.file_digest} onClick={this.onClick}>
        <Tooltip title={toolTipElem}>
          <List.Item.Meta avatar={fileIcon} title={filenameElem} description={descElem} />
        </Tooltip>
      </List.Item>
    )
  }
}

export default ImageItemDef
