// import { TokenCredential } from "@azure/core-auth";
import { TokenCredential, AccessToken } from '@azure/core-auth'
import {
  TokenCredentialAuthenticationProvider,
  TokenCredentialAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/azureTokenCredentials'
import { store as reduxStore } from 'index'
import store from 'store'
import moment from 'moment'

import { clientForLanding } from 'myNet'

import Ngql from 'gql/ninegql'
// import { parseJwt, processError } from 'utils'
import { parseJwt, makeSearchString } from 'utils'

import {
  Client,
  // GraphRequestOptions,
  PageIteratorCallback,
  PageCollection,
  PageIterator,
} from '@microsoft/microsoft-graph-client'

/* eslint max-classes-per-file: ["error", 2] */

class StaticTokenCredential implements TokenCredential {
  // AccessToken is an object with two properties:
  // - A "token" property with a string value.
  // - And an "expiresOnTimestamp" property with a numeric unix timestamp as its value.
  constructor(private accessToken: AccessToken) {
    this.accessToken = accessToken
  }

  async getToken(): Promise<AccessToken> {
    return this.accessToken
  }

  setToken(accessToken: AccessToken): void {
    this.accessToken = accessToken
  }
}

const mutationObtainAccessToken = Ngql.Mutation('ObtainAccessToken', {
  obtainAccessToken: Ngql.Node(
    {
      ok: true,
      accessToken: true,
      expiresAt: true,
    },
    null,
    false,
    {
      connectionDigest: Ngql.Var('connectionDigest', 'String'),
      scopes: Ngql.Var('scopes', '[String!]'),
    },
  ),
})

const updateInfo = {
  isUpdating: false,
  currentQuery: null,
  waitingReq: [],
}

function updateToken() {
  const connectionDigest = store.get('app.user.connection_digest')
  let ret = null
  if (connectionDigest != null) {
    if (updateInfo.isUpdating === false) {
      updateInfo.isUpdating = true
      // store.set('app.user.token', refreshToken)
      const resObj: any = Ngql.GQLObj(mutationObtainAccessToken, {
        vars: {
          connectionDigest,
          scopes: [
            'https://graph.microsoft.com/Mail.Read',
            'https://graph.microsoft.com/User.Read',
            // 'https://graph.microsoft.com/User.ReadWrite',
            // 'https://graph.microsoft.com/User.Read.All',
          ],
        },
      })

      updateInfo.currentQuery = resObj
        .mutate({}, clientForLanding)
        .then((res) => {
          const { accessToken } = res.data.obtainAccessToken
          console.log('new provider token : ', accessToken)
          store.set('app.user.providerToken', accessToken)

          console.log('updateInfo.waitingReq : ', updateInfo.waitingReq)
          updateInfo.waitingReq.forEach((promise) => {
            promise.resolve(accessToken)
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null
          return accessToken
        })
        .catch(() => {
          updateInfo.waitingReq.forEach((promise) => {
            promise.reject()
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null

          reduxStore.dispatch({
            type: 'user/LOGOUT',
          })
        })

      ret = updateInfo.currentQuery
    } else {
      const retPromise = new Promise((resolve, reject) => {
        updateInfo.waitingReq.push({
          resolve,
          reject,
        })
      })
      ret = retPromise
    }
  }
  return ret
}

class GraphAPI {
  token: AccessToken

  graphClient: Client

  credential: StaticTokenCredential

  authProvider: TokenCredentialAuthenticationProvider

  constructor() {
    this.token = null
    this.graphClient = null
    this.credential = null
  }

  init(token: string) {
    const result = parseJwt(token)

    this.token = {
      token,
      expiresOnTimestamp: result.exp,
    }

    if (this.credential == null) {
      this.credential = new StaticTokenCredential(this.token)

      const options: TokenCredentialAuthenticationProviderOptions = {
        scopes: [
          'user.read',
          'Mail.ReadBasic.All',
          'Mail.Read',
          'mailboxsettings.read',
          'calendars.readwrite',
        ],
      }

      this.authProvider = new TokenCredentialAuthenticationProvider(this.credential, options)

      if (this.graphClient == null) {
        this.graphClient = Client.initWithMiddleware({
          authProvider: this.authProvider,
        })
      }
    } else {
      this.credential.setToken(this.token)
    }
  }

  callGraph = async (api, select) => {
    let ret = null
    let req = null

    try {
      req = this.graphClient.api(api)

      if (select != null) {
        req.select(select)
      }

      ret = await req.get()
    } catch (error) {
      console.log(' error - callGraph : ', api, select)
      console.log(' error - code : ', error.code)
      console.log(' error - statusCode : ', error.statusCode)
      if (error.statusCode === 401 && error.code === 'InvalidAuthenticationToken') {
        let retPromise = null
        const updateHandle = updateToken()
        if (updateHandle != null) {
          retPromise = new Promise((resolve, reject) => {
            updateHandle
              .then((token) => {
                console.log('mail api - new token : ', token)
                if (token != null) {
                  this.init(token)

                  req
                    .get()
                    .then((res) => {
                      resolve(res)
                    })
                    .catch((err) => {
                      console.log('updateToken err - ', err)
                      // update된 token에서 에러발생 - logout.
                      reduxStore.dispatch({
                        type: 'user/LOGOUT',
                      })
                      reject(err)
                    })
                }
              })
              .catch((err) => {
                console.log('updateToken err - ', err)
                // update된 token에서 에러발생 - logout.
                reduxStore.dispatch({
                  type: 'user/LOGOUT',
                })
                reject()
              })
          })
        }

        if (retPromise != null) {
          return retPromise
        }
      }
    }

    return ret
  }

  async getCurrentAccount() {
    const user = await this.callGraph('/me', 'displayName,mail')
    return user
  }

  // size - 48, 64, 96, 120, 240, 360, 432, 504
  async getMyProfilePhoto(size) {
    const sizeStr = size != null ? `${size}x${size}` : '48x48'
    const api = `/me/photos/${sizeStr}/$value`
    const user = await this.callGraph(api, null)
    return user
  }

  // size - 48, 64, 96, 120, 240, 360, 432, 504
  // email - id | userPrincipalName
  // async getProfilePhoto(email, size) {
  //   const sizeStr = (size != null) ? `${size}x${size}` : '48x48';
  //   const api = `/users/${email}/photos/${sizeStr}/$value`;
  //   const user = await this.callGraph(api, null)
  //   return user
  // }

  async getMailFolders() {
    const result = await this.callGraph('/me/mailFolders', null)
    return result
  }

  async getMessage(mailId) {
    const id = encodeURIComponent(mailId).replace(/%2F/g, '%252F')
    const result = await this.callGraph(`/me/messages/${id}`, null)
    return result
  }

  async getMessage2(internetMessageId) {
    const id = encodeURIComponent(internetMessageId).replace(/%2F/g, '%252F')
    const result = await this.callGraph(`/me/messages?$filter=internetMessageId eq '${id}'`, null)
    return result
  }

  async getMessages(folderId, select, size) {
    const pageSize = size || 10
    const params: any = {
      $top: pageSize,
      $count: 'true',
    }

    if (select != null && Array.isArray(select) === true) {
      params.$select = select.join(',')
    }

    const paramStr = makeSearchString(params)

    const fId = encodeURIComponent(folderId).replace(/%2F/g, '%252F')
    const result = await this.callGraph(`/me/mailFolders/${fId}/messages?${paramStr}`, null)
    return result
  }

  // startData: YYYY-MM-DD
  async getSortedMessagesByCId(select, size) {
    const pageSize = size || 10
    const params: any = {
      $top: pageSize,
      $count: 'true',
      // $orderBy: 'conversationId desc',
      $orderBy: 'receivedDateTime desc',
    }

    if (select != null && Array.isArray(select) === true) {
      params.$select = select.join(',')
    }

    const paramStr = makeSearchString(params)

    const result = await this.callGraph(`/me/messages?${paramStr}`, null)
    return result
  }

  // startData: YYYY-MM-DD
  async getMessagesByCId(conversationId, select, size, startDate, endDate, isDesc) {
    const pageSize = size || 10
    const params: any = {
      $top: pageSize,
      $count: 'true',
      $filter: `(conversationId eq '${conversationId}')`,
    }

    if (select != null && Array.isArray(select) === true) {
      params.$select = select.join(',')
    }

    let filterStr = ''

    const cIdFilter = `conversationId eq '${conversationId}'`
    if (startDate != null) {
      const sTime = moment.isMoment(startDate) === true ? startDate : moment(startDate)
      const sTimeStr = sTime.format('YYYY-MM-DDTHH:mm:ssZ')
      filterStr += `(receivedDateTime gt ${sTimeStr})`
    }
    if (endDate != null) {
      const eTime = moment.isMoment(endDate) === true ? endDate : moment(endDate)
      const eTimeStr = eTime.format('YYYY-MM-DDTHH:mm:ssZ')
      if (filterStr.length > 0) {
        filterStr += ' and '
      }
      filterStr += `(receivedDateTime lt ${eTimeStr})`
    }

    if (filterStr.length > 0) {
      filterStr += ` and (${cIdFilter})`
    } else {
      filterStr += `${cIdFilter}`
    }

    params.$filter = filterStr

    if (isDesc === true) {
      params.$orderBy = 'receivedDateTime desc'
    }

    const paramStr = makeSearchString(params)

    const result = await this.callGraph(`/me/messages?${paramStr}`, null)
    return result
  }

  async getMessageInfo(messageId) {
    console.log(messageId)
    const id = encodeURIComponent(messageId).replace(/%2F/g, '%252F')
    const result = await this.callGraph(`/me/messages/${id}/$value`, null)
    return result
  }

  // recvCB(data, isEnd) - 하나씩 데이터를 전달하는 callback -
  // data - 전달되는 데이터
  // isLoopEnd - 마지막 데이터인지를 전달. (전체 데이터의 마지막이 아니라, 이번 query에서 요청된 마지막을 체크.)
  // isTotalEnd - 마지막 데이터인지를 전달. (전체 데이터의 마지막)
  getIterator(response: PageCollection, recvCB, readToEnd) {
    const iteratorObj = {
      tCount: 0,
      total: response['@odata.count'],
      count: 0,
      size: 10,
      isFirst: true,
      readToEnd,
      pageIterator: null,
      start: null,
      more: null,
      hasNext: null,
    }

    const callback: PageIteratorCallback = (data) => {
      console.log(data)

      iteratorObj.count += 1
      if (iteratorObj.total != null) {
        iteratorObj.tCount += 1
        if (iteratorObj.tCount === iteratorObj.total) {
          recvCB(data, true, true)
          return false
        }
      }
      if (iteratorObj.readToEnd !== true && iteratorObj.count === iteratorObj.size) {
        iteratorObj.count = 0
        recvCB(data, true, false)
        return false
      }

      recvCB(data, false, false)
      return true
    }

    iteratorObj.pageIterator = new PageIterator(this.graphClient, response, callback)

    iteratorObj.start = async () => {
      await iteratorObj.pageIterator.iterate()
    }

    iteratorObj.more = async () => {
      let ret = null
      try {
        if (!iteratorObj.pageIterator.isComplete()) {
          ret = await iteratorObj.pageIterator.resume()
        }
      } catch (error) {
        console.log(' -- iterator err ', error)
      }
      return ret
    }

    iteratorObj.hasNext = () => {
      return iteratorObj.pageIterator.isComplete() === false
    }

    return iteratorObj
  }

  test = () => {
    updateToken()
  }
}

const graphAPI = new GraphAPI()

export default graphAPI
