import React from 'react'
import { Tag, Input } from 'antd'
// import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar-z'

import classNames from 'classnames'
import i18n from 'i18n'

import { getHighlightMemberElem } from 'pages/chat/component/MemberView/memberUtil'

import iconSearchSVG from 'assets/images/icon-search.svg'

import dataManager from '../chatView/data/dataManager'

import styles from '../style.module.scss'

class RoomMemberListDef extends React.Component {
  state = {
    searchResults: [],
    searchStr: null,
  }

  searchTimer = null

  selectedList = []

  selectedMap = {}

  tempMemberMap = {}

  scrollRef = React.createRef()

  resultRef = React.createRef()

  componentDidMount() {
    const { roomObj } = this.props

    setTimeout(() => {
      this.setRoomObj(roomObj, '')
    }, 10)
  }

  componentDidUpdate(prevProps) {
    const { roomObj } = this.props

    if (prevProps.roomObj !== roomObj) {
      this.setRoomObj(roomObj, this.lastSearchTxt)
    }
  }

  componentWillUnmount() {}

  onToggleMember = () => {}

  innerSearchMember = (str, onGetList) => {
    const txt = str || ''

    let resultMembers = []

    if (txt.trim().length === 0) {
      resultMembers = this.roomObj.roomMemberList.filter((member) => {
        return member.status === 1
      })
    } else {
      resultMembers = this.roomObj.roomMemberList.filter((member) => {
        if (member.status !== 1) {
          return false
        }
        if (member.email.indexOf(txt) >= 0) {
          return true
        }
        if (member.display_name != null && member.display_name.indexOf(txt) >= 0) {
          return true
        }

        return false
      })
    }

    if (onGetList != null) {
      onGetList(resultMembers)
    }
  }

  onSearch = (str) => {
    const roomId = this.roomObj.ChatRoom.id
    this.lastSearchTxt = str

    dataManager.getRoomObj(
      roomId,
      (newRoomObj) => {
        this.setRoomObj(newRoomObj, str)
      },
      false,
      false,
      true,
    )
  }

  setRoomObj = (roomObj, str) => {
    this.roomObj = roomObj

    this.innerSearchMember(str, (list) => {
      console.log(' - innerSearchMember : ', list)

      const resultList = list

      this.setState({
        searchResults: resultList,
        searchStr: str,
      })

      if (this.resultRef != null && this.resultRef.current != null) {
        this.resultRef.current.element.scrollTop = 0
        this.resultRef.current.update()
      }
    })
  }

  render = () => {
    const { searchResults, searchStr } = this.state

    const resultElems = searchResults.map((member) => {
      const elem = getHighlightMemberElem(member.email, member, styles, searchStr)
      return (
        <Tag.CheckableTag
          className="edit-tag member selectable"
          key={member.email}
          onChange={() => {
            this.onToggleMember(member)
          }}
          checked={this.selectedMap[member.email] === true}
        >
          {elem}
        </Tag.CheckableTag>
      )
    })

    return (
      <div className={classNames(styles.selectMember, styles.type2)}>
        <Input.Search
          placeholder="Search"
          onSearch={this.onSearch}
          enterButton={<img src={iconSearchSVG} alt="search" />}
        />
        <div className={styles.subTitle}>{i18n.t('chat.setting.chooseMember')}</div>
        <PerfectScrollbar
          refScroll={this.resultRef}
          className={classNames(styles.membersWrap, 'border')}
        >
          {resultElems}
        </PerfectScrollbar>
      </div>
    )
  }
}

export default RoomMemberListDef
