import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  // Dropdown,
  Avatar,
  Badge,
} from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({
  user,
})

class WorkspaceMenu extends React.Component {
  state = {
    count: 0,
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { count } = this.state
    const { user } = this.props

    const { workspace } = user

    const iconUrl = workspace != null ? workspace.icon_url : null

    const iconElem =
      workspace != null && workspace.iconElem != null ? workspace.iconElem : <TeamOutlined />

    const workspaceElem = (
      <Badge count={count}>
        <Avatar src={iconUrl} className={styles.avatar} shape="circle" size={40} icon={iconElem} />
      </Badge>
    )

    // const workspaceName =
    //   workspace != null ? workspace.workspace_name : ''

    // if (workspaceName != null && workspaceName.trim().length > 0) {
    //   const items = [
    //     {
    //       label: (
    //         <React.Fragment>
    //           <strong>{workspaceName}</strong>
    //           {/*
    //           <div>
    //             <strong className="mr-1">
    //               {t('topBar.profileMenu.billingPlan')}:{' '}
    //             </strong>
    //             Professional
    //           </div>
    //           */}
    //           {/*
    //           <div>
    //             <strong>{t('topBar.profileMenu.role')}: </strong>
    //             {user.role || '—'}
    //           </div>
    //           */}
    //         </React.Fragment>
    //       ),
    //       key: 'name',
    //     },
    //   ]

    //   const menu = {
    //     items,
    //     selectable: false,
    //   }

    //   return (
    //     <Dropdown menu={menu} trigger={['click']}>
    //       <div className={styles.dropdown}>{workspaceElem}</div>
    //     </Dropdown>
    //   )
    // }

    return <div className={styles.noDropdown}>{workspaceElem}</div>
  }
}

export default withTranslation()(connect(mapStateToProps)(WorkspaceMenu))
