import React from 'react'
import { Input, List, Button, notification } from 'antd'

import { withTranslation } from 'react-i18next'

import { getEnvParam } from 'env'
import PerfectScrollbar from 'react-perfect-scrollbar-z'
import classNames from 'classnames'

// import dataMgr from 'pages/chat/component/chatView/data/dataManager'
import { getChannels } from 'pages/conversations/component/roomUtil'
// import { addWSListener, removeWSListener } from 'services/websocket'

// import cUtil from 'pages/chat/component/chatView/data/cUtil'
import { getTitleElem } from 'pages/chat/component/chatUI'
import RoomIcon from 'pages/chat/component/RoomIcon'

import iconSearchSVG from 'assets/images/icon-search.svg'

import styles from './style.module.scss'

class ListDef extends React.Component {
  state = {
    itemList: [],
  }

  itemMap = {}

  itemList = []

  scrollRef = React.createRef()

  hasNext = true

  isMessageFull = false

  pageNum = 0

  pageSize = 20

  prevParamData = {}

  componentDidMount() {
    this.loadItem(1, null)
  }

  componentWillUnmount() {}

  resetItemList = () => {
    this.isMessageFull = false
    this.hasNext = true
    this.itemList = []
    this.itemMap = {}
    this.prevParamData = {
      pageNum: 1,
    }
    this.setState({
      itemList: [],
    })
    if (this.scrollRef.current != null) {
      const elem = this.scrollRef.current.element
      elem.scrollTop = 0
      this.scrollRef.current.update()
    }
  }

  loadItem = (pageNum, txt, onLoaded) => {
    this.pageNum = pageNum != null ? pageNum : 1

    this.prevParamData = {
      pageNum: this.pageNum,
      // sort,
      // searchFileName,
    }

    const params = {
      // page: this.pageNum,
      // per_page: this.pageSize,
      skip: (this.pageNum - 1) * this.pageSize,
      limit: this.pageSize,
      // filters: ['status ne 0'],
      // sort: ['update_time desc'],
    }

    params.filters = []

    if (txt != null && txt.trim().length > 0) {
      this.prevParamData.searchText = txt
      const textList = txt.split(' ')

      textList.forEach((item) => {
        const pTxt = item.trim()
        if (pTxt.length > 0) {
          params.filters.push([`display_name like ${pTxt}`])
        }
      })
    }

    params.filters.push('status eq 1')
    // archive 된 것은 표시하지 않음.
    params.filters.push('archive eq 0')

    getChannels(params)
      .then((res) => {
        console.log(' itemList - ', res)
        if (res.data.code === 200) {
          const { data } = res.data

          data.forEach((item) => {
            const { ChatRoom } = item
            if (this.itemMap[ChatRoom.id] == null) {
              this.itemMap[ChatRoom.id] = item
              this.itemList.push(item)
            }
          })

          this.setState({
            itemList: [...this.itemList],
            loading: false,
          })

          // response의 item수가 꽉차서 오면 다음 데이터가 있는지 확인 필요.
          this.hasNext = data.length === this.pageSize

          if (this.hasNext === false) {
            this.setState({
              loading: false,
            })
          } else {
            // 화면이 꽉 찼는지 검사해서, 부족하면 더 로딩하도록 함.
            this.checkScreenFull(
              () => {
                this.moveNext()
              },
              () => {
                this.setState({
                  loading: false,
                })
              },
            )
          }
        } else {
          this.setState({
            loading: false,
          })
        }

        if (onLoaded != null) {
          onLoaded(true) // success
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        })
        notification.error({
          message: err.code,
          description: err.message,
        })
        if (onLoaded != null) {
          onLoaded(false) // fail
        }
      })
  }

  onSearch = (str) => {
    console.log(' -- search -- ', str)

    this.resetItemList()

    this.loadItem(1, str.trim())
  }

  moveNext = () => {
    const { loading } = this.state

    if (this.hasNext === true && loading !== true) {
      const { pageNum, searchText } = this.prevParamData

      let pageNum1 = pageNum != null ? pageNum : 1

      pageNum1 += 1

      this.setState({
        loading: true,
      })

      this.loadItem(pageNum1, searchText)
    }
  }

  checkScreenFull = (onNeedMoreFunc, onLoadedFullFunc) => {
    setTimeout(() => {
      console.log('scrollRef : ', this.scrollRef)
      if (this.scrollRef.current != null) {
        const elem = this.scrollRef.current.element

        // 기본 화면 높이보다 content가 적으면 다음 페이지 요청.
        if (this.helperElem != null && this.isMessageFull !== true) {
          let needMoreData = false
          const itemList = this.helperElem.querySelectorAll('.ant-row > div')

          if (itemList.length > 0) {
            const itemPerLine = Math.floor(this.helperElem.offsetWidth / itemList[0].offsetWidth)
            const lastFullLine = Math.floor(itemList.length / itemPerLine)
            let fullHeight = 0
            this.itemHeight = itemList[0].offsetHeight
            if (lastFullLine > 0) {
              const lastFullItem = itemList[itemPerLine * lastFullLine - 1]
              fullHeight = lastFullItem.offsetTop + lastFullItem.offsetHeight
            }
            needMoreData = fullHeight <= elem.offsetHeight
          } else {
            needMoreData = this.helperElem.offsetHeight <= elem.offsetHeight
          }

          if (needMoreData === true) {
            if (onNeedMoreFunc != null) {
              onNeedMoreFunc()
            }
          } else {
            this.isMessageFull = true
            if (onLoadedFullFunc != null) {
              onLoadedFullFunc()
            }
          }
        }

        this.scrollRef.current.update()
      }
    }, 100)
  }

  onScrollY = (e) => {
    console.log('onScrollY : ', e)
    console.log(' --- ', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)

    const bottomPosRange = 20

    const { scrollTop, scrollHeight, offsetHeight } = e.target

    if (scrollHeight - bottomPosRange < scrollTop + offsetHeight) {
      this.moveNext()
    }
    // else if (this.itemHeight > 0) {
    //   if (scrollHeight - this.itemHeight < scrollTop + offsetHeight) {
    //     this.moveNext();
    //   }
    // }

    // if (this.hasNext === true && scrollHeight - scrollTop === offsetHeight) {
    //   setTimeout(() => {
    //     if (e.target.scrollTop === scrollTop) {
    //       e.target.scrollTop = scrollTop - 1;
    //     }
    //   }, 200);
    // }
  }

  onYReachEnd = () => {
    // this.moveNext()
  }

  onClick = (e, item) => {
    const { onItemClick } = this.props
    console.log(' - onClick - ', e, item)

    if (onItemClick != null) {
      onItemClick(item)
    }

    e.preventDefault()
  }

  renderItem = (item) => {
    const { t } = this.props
    const { ChatRoom: room } = item

    // console.log(' ---- render Item - ', lastMsg, room)

    const avatarElem = (
      <RoomIcon roomId={room.id} roomObj={item} digest={room.photo_digest} styles={styles} />
    )

    const titleElem = getTitleElem(item)

    const userEmail = getEnvParam('userEmail')

    const isJoined = room.members.some((member) => {
      if (member.email === userEmail) {
        return true
      }
      return false
    })

    const joinedElem =
      isJoined === true ? <span className="joined">{t('chat.member.joined')} · </span> : ''
    const memberCountElem =
      room.members.length > 1
        ? t('chat.member.people', { count: room.members.length })
        : t('chat.member.person', { count: room.members.length })
    const countElem = <span>{memberCountElem}</span>

    return (
      <List.Item key={room.id} tempid={room.id}>
        <div className={classNames(styles.roomItem)}>
          {avatarElem}
          <div className={styles.content}>
            <div className={styles.roomName}>{titleElem}</div>
            <div className={styles.members}>
              {joinedElem}
              {countElem}
            </div>
            <Button
              className={styles.joinBtn}
              type="default"
              size="large"
              htmlType="button"
              onClick={(e) => {
                this.onClick(e, item)
              }}
            >
              {t('chat.button.join')}
            </Button>
          </div>
        </div>
      </List.Item>
    )
  }

  render() {
    const { itemList } = this.state

    const mainProps = {}

    return (
      <div className={classNames(styles.channelList, 'channelList', mainProps)}>
        <div className={classNames(styles.channelSearch)}>
          <Input.Search
            placeholder="Search"
            onSearch={this.onSearch}
            enterButton={<img src={iconSearchSVG} alt="search" />}
          />
        </div>
        <PerfectScrollbar
          className={classNames(styles.channelsWrap, 'border')}
          refScroll={this.scrollRef}
          onScrollY={this.onScrollY}
          onYReachEnd={this.onYReachEnd}
        >
          <div
            ref={(ref) => {
              this.helperElem = ref
            }}
            className={classNames(styles.scrollHelper)}
          >
            <List
              className={styles.listContainer}
              dataSource={itemList}
              renderItem={this.renderItem}
            />
          </div>
        </PerfectScrollbar>
      </div>
    )
  }
}

const ListComp = withTranslation()(ListDef)

export default ListComp
