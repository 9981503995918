import React from 'react'
import { store as reduxStore } from 'index'
import { Dropdown } from 'antd'
import styles from './style.module.scss'

const langMap = {
  en: 'English',
  ko: '한국어',
}

const LanguageSwitcher = () => {
  const { dispatch } = reduxStore

  const reduxState = reduxStore.getState()

  const { settings } = reduxState
  const { locale } = settings

  const changeLanguage = (e) => {
    const { key } = e

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })

    e.domEvent.stopPropagation()
  }

  const onClick = (e) => {
    e.stopPropagation()
  }

  const langCode = locale.substr(0, 2)

  const language = langMap[langCode.toLowerCase()]

  const menu = {
    selectedKeys: [locale],
    selectable: true,
    onClick: changeLanguage,
    items: [
      {
        key: 'ko-KR',
        label: (
          <React.Fragment>
            <span className="text-uppercase font-size-12 mr-2">KO</span>
            한국어
          </React.Fragment>
        ),
      },
      {
        key: 'en-US',
        label: (
          <React.Fragment>
            <span className="text-uppercase font-size-12 mr-2">EN</span>
            English
          </React.Fragment>
        ),
      },
      // Disable the laguages below - FR, RU, CN
      // {
      //   key: 'fr-FR',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">FR</span>
      //       French
      //     </React.Fragment>
      //   )
      // },
      // {
      //   key: 'ru-RU',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">RU</span>
      //       Русский
      //     </React.Fragment>
      //   )
      // },
      // {
      //   key: 'zh-CN',
      //   label: (
      //     <React.Fragment>
      //       <span className="text-uppercase font-size-12 mr-2">CN</span>
      //       简体中文
      //     </React.Fragment>
      //   )
      // },
    ],
  }

  return (
    <Dropdown
      className={styles.languageSwitcher}
      overlayClassName="menuStyle1"
      menu={menu}
      trigger={['click']}
      placement="bottomRight"
      onClick={onClick}
    >
      <div className={styles.dropdown}>
        <span className="text">{language}</span>
      </div>
    </Dropdown>
  )
}

export default LanguageSwitcher
