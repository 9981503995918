import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { getEnvParam } from 'env'

import { getSearchParams } from 'utils'

// import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import * as landing from 'services/landing'
import actions from './actions'

const mapAuthProviders = {
  // firebase: {
  //   login: firebase.login,
  //   register: firebase.register,
  //   currentAccount: firebase.currentAccount,
  //   logout: firebase.logout,
  // },
  jwt: {
    login: jwt.login,
    guestLogin: null,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
  landing: {
    login: landing.login,
    guestLogin: landing.guestLogin,
    register: landing.register,
    currentAccount: landing.currentAccount,
    logout: landing.logout,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      loginProcessing: true,
    },
  })
  const { authProvider: autProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    const params = getSearchParams()
    const target = params.path != null ? window.decodeURIComponent(params.path) : '/'

    yield history.push(target)
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        loginProcessing: false,
      },
    })
  }
}

export function* GUEST_LOGIN({ payload }) {
  // yield put({
  //   type: 'user/LOAD_CURRENT_ACCOUNT',
  // })
  const { key } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      loginProcessing: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const ret = yield call(mapAuthProviders[authProvider].guestLogin, key)

  if (ret != null) {
    const { chatRoomId, workspaceId, target } = ret

    yield history.replace('/plain/waiting')

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    if (target != null && target.length > 0) {
      yield history.replace(target)
    } else {
      yield history.replace(`/plain/guest/${workspaceId}/${chatRoomId}`)
    }
  } else {
    yield history.replace('/')

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        loginProcessing: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, email, name, avatar, role, workspace, serverType } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
        workspace,
        serverType,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT({ payload }) {
  const { authProvider } = yield select((state) => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      workspace: null,
      loading: false,
      loginProcessing: false,
    },
  })

  console.log(' --- location --- ', history.location)

  let targetURL = '/auth/login'
  if (payload != null) {
    const { target } = payload
    if (target != null) {
      targetURL = target
    }
  } else if (history.location.pathname.indexOf('/outlook/') === 0) {
    // outlook plugin 상태임.

    const outlookUser = getEnvParam('userEmail')
    const curPath = window.encodeURIComponent(history.location.pathname + history.location.search)
    targetURL = `/auth/login?email=${outlookUser}&path=${curPath}`
  } else if (history.location.pathname.indexOf('/auth/login') === 0) {
    targetURL = null
  }

  // console.log(targetURL)

  if (targetURL != null) {
    yield history.push(`${targetURL}`)
  }

  // yield history.push({
  //   pathname: targetURL,
  //   state: {
  //     step: 0,
  //   },
  // });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.GUEST_LOGIN, GUEST_LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
