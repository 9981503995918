/*
 * messageDB 데이터 구조.
 * key : messageID or commentID -> contentId
 * subKey: ${RoomID} or ${RoomID}_${messageID}
 * type: 'waiting', 'input'
 * }
 */

function DataDB(name) {
  const self = this

  self.dbName = name
  self.isActive = false
  self.db = null
  self.loaded = false

  self.expireDuration = 1 * 24 * 60 * 60 * 1000 // 1일 후 만료.

  // 내부에 메일 배열을 가지고 있도록 하여, 없는 것만 새로 DB에 추가하도록 처리(배열에도 함께 추가.).
  // 데이터를 가져가는 것은 배열에서 가져가도록 할 것.
  // 표시되는 메세지의 날짜에 맞춰서 적당히 가져가도록~!!
  self.dataMap = {}

  self.recordMap = {}

  self.getMessageInputKey = (roomId) => {
    return {
      key: `Room_${roomId}`,
      subKey: `Room_${roomId}`,
    }
  }

  self.getMessageKey = (messageObj) => {
    return {
      key: `message_${messageObj.data.content_id}`,
      subKey: `Room_${messageObj.chatRoomId}`,
    }
  }

  self.getCommentInputKey = (parentObjId) => {
    return {
      key: `Parent_${parentObjId}`,
      subKey: `Parent_${parentObjId}`,
    }
  }

  self.getCommentKey = (commentObj) => {
    return {
      key: `comment_${commentObj.data.content_id}`,
      subKey: `Parent_${commentObj.objectId}`,
    }
  }

  self.init = () => {
    const DBOpenRequest = window.indexedDB.open(self.dbName, 2)

    DBOpenRequest.onerror = (error) => {
      console.log('error - db open failed', error)
      self.isActive = false
    }

    DBOpenRequest.onsuccess = (event) => {
      console.log('success - db open success.')

      self.isActive = true

      self.db = event.target.result

      self.load()
    }

    DBOpenRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        console.log('error - db upgrade.', error)
        self.isActive = false
      }

      if (event.oldVersion === 1) {
        db.deleteObjectStore(self.dbName)
      }

      const objectStore = db.createObjectStore(self.dbName, { keyPath: 'key' })
      // const objectStore = db.createObjectStore(self.dbName, { keyPath: 'url' })

      // objectStore.createIndex('conversationId', 'conversationId', { unique: true })
      objectStore.createIndex('subKey', 'subKey', { unique: false })
      objectStore.createIndex('expireTime', 'expireTime', { unique: false })
      // objectStore.createIndex('id', 'id', { unique: true })
    }

    self.isActive = true
  }

  self.close = () => {
    if (self.isActive === true) {
      self.db.close()
    }
    self.isActive = false
  }

  self.load = () => {
    if (self.isActive !== true) {
      return null
    }

    self.dataMap = {}

    self.recordMap = {}

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database load finished.', tEvent)
      }

      transaction.onerror = (tEvent) => {
        console.log('Transaction not opened due to error.', tEvent)
        reject(tEvent)
      }

      const objectStore = transaction.objectStore(self.dbName)
      // const req = objectStore.openCursor()
      const index = objectStore.index('expireTime')

      // const keyRangeValue = IDBKeyRange.only(conversationId)
      const keyRangeValue = IDBKeyRange.upperBound(Date.now())
      const req = index.openCursor(keyRangeValue)
      req.onsuccess = (event) => {
        const cursor = event.target.result

        // 만료된 데이터 제거.
        if (cursor) {
          cursor.delete()
          cursor.continue()
        }

        self.loaded = true
        resolve(true)
      }

      req.onerror = (err) => {
        console.log('cursor read error.', err)
        reject(err)
      }
    })

    return promise
  }

  self.addData = (key, subKey, type, objData) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const data = {
        ...objData,
      }

      delete data.msgObj
      delete data.isFail

      const newItem = {
        key,
        subKey,
        type,
        data,
        expireTime: Date.now() + self.expireDuration,
      }

      const objectStoreRequest = objectStore.add(newItem)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Added successfully.', event)

        self.recordMap[key] = {
          ...newItem,
        }

        if (self.dataMap[subKey] == null) {
          self.dataMap[subKey] = {}
        }

        const subObj = self.dataMap[subKey]

        if (type === 'waiting') {
          if (subObj.waiting == null) {
            subObj.waiting = {}
          }

          subObj.waiting[key] = {
            ...newItem.data,
          }
        } else if (type === 'input') {
          subObj.input = {
            ...newItem.data,
          }
        }

        resolve(self.dataMap[subKey])
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to add.', error)
        reject(error)
      }
    })

    return promise
  }

  self.putData = (key, subKey, type, objData) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const data = {
        ...objData,
      }

      delete data.msgObj
      delete data.isFail

      const newItem = {
        key,
        subKey,
        type,
        data,
        expireTime: Date.now() + self.expireDuration,
      }

      const objectStoreRequest = objectStore.put(newItem)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Added successfully.', event)

        self.recordMap[key] = {
          ...newItem,
        }

        if (self.dataMap[subKey] == null) {
          self.dataMap[subKey] = {}
        }

        const subObj = self.dataMap[subKey]

        if (type === 'waiting') {
          if (subObj.waiting == null) {
            subObj.waiting = {}
          }

          subObj.waiting[key] = {
            ...newItem.data,
          }
        } else if (type === 'input') {
          subObj.input = {
            ...newItem.data,
          }
        }

        resolve(self.dataMap[subKey])
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to add.', error)
        reject(error)
      }
    })

    return promise
  }

  self.deleteData = (key) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      let record = null
      if (self.recordMap[key] != null) {
        record = self.recordMap[key]

        const { subKey, type } = record

        if (self.dataMap[subKey] != null) {
          const subObj = self.dataMap[subKey]
          if (type === 'waiting') {
            if (subObj.waiting != null) {
              delete subObj.waiting[key]
            }
          } else if (type === 'input') {
            if (subObj.input != null) {
              delete subObj.input
            }
          }
        }

        delete self.recordMap[key]
      }

      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const objectStoreRequest = objectStore.delete(key)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Deleted successfully.', event)

        resolve(key)
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to delete.', error)
        reject(error)
      }
    })

    return promise
  }

  self.getDataForRoom = (subKey) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      if (self.dataMap[subKey] != null) {
        resolve(self.dataMap[subKey])
      } else {
        const transaction = self.db.transaction([self.dbName], 'readwrite')

        transaction.oncomplete = (tEvent) => {
          console.log('Transaction completed: database get finished.', tEvent)
        }

        transaction.onerror = (error) => {
          console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
          reject(error)
        }

        const objectStore = transaction.objectStore(self.dbName)

        const index = objectStore.index('subKey')

        // const keyRangeValue = IDBKeyRange.only(conversationId)
        const keyRangeValue = IDBKeyRange.bound(subKey, subKey)
        const req = index.openCursor(keyRangeValue)
        req.onsuccess = (event) => {
          const cursor = event.target.result

          if (self.dataMap[subKey] == null) {
            self.dataMap[subKey] = {}
          }

          const subObj = self.dataMap[subKey]

          if (cursor) {
            const { key, type, data } = cursor.value
            if (type === 'waiting') {
              if (subObj.waiting == null) {
                subObj.waiting = {}
              }

              subObj.waiting[key] = {
                ...data,
              }
            } else if (type === 'input') {
              subObj.input = {
                ...data,
              }
            }

            cursor.continue()
          } else {
            resolve(self.dataMap[subKey])
          }
        }

        req.onerror = (err) => {
          console.log('failed to get.', err)
          reject(err)
        }
      }
    })

    return promise
  }

  self.isLoaded = () => {
    return self.loaded
  }
}

const dataDB = new DataDB('messageDB')
dataDB.init()

export default dataDB
