import React from 'react'
import { Tabs } from 'antd'

import classNames from 'classnames'
import i18n from 'i18n'

import FileView from 'pages/chat/component/fileView'

// import messageSVG from 'assets/images/icon-message.svg'

import styles from '../style.module.scss'

class ContentViewDef extends React.Component {
  state = {
    isInitialized: false,
    defaultTab: null,
  }

  componentDidMount() {
    const { defaultTab } = this.props

    const newState = {
      isInitialized: true,
    }

    if (defaultTab != null) {
      newState.defaultTab = defaultTab

      this.onTabChanged(defaultTab)
    } else {
      this.onTabChanged('media')
    }

    this.setState(newState)
  }

  componentWillUnmount() {}

  onTabChanged = (key) => {
    const { onTabChanged } = this.props
    if (onTabChanged != null) {
      const title = this.getTitle(key)
      onTabChanged(key, title)
    }
  }

  getTitle = (key) => {
    let str = ''
    switch (key) {
      case 'media':
        str = i18n.t('chat.content.media')
        break
      case 'file':
        str = i18n.t('chat.content.file')
        break
      case 'link':
        str = i18n.t('chat.content.link')
        break
      default:
        str = ''
    }
    return str
  }

  getTabItem = () => {
    const { roomObj, chatObj, options } = this.props

    // const {
    // } = this.state

    const items = [
      {
        label: this.getTitle('media'),
        key: 'media',
        children: (
          <FileView
            roomObj={roomObj}
            chatObj={chatObj}
            options={options}
            type="image"
            grid={{ column: 3 }}
            mimeTypeRegEx="^image|^video"
            active
          />
        ),
      },
      {
        label: this.getTitle('file'),
        key: 'file',
        children: (
          <FileView
            roomObj={roomObj}
            chatObj={chatObj}
            options={options}
            mimeTypeRegEx="^(?!image)(?!video)"
            active
          />
        ),
      },
      {
        label: this.getTitle('link'),
        key: 'link',
        children: (
          <div className={styles.linkList}>
            {/*
            <FileView roomObj={roomObj} chatObj={chatObj} active />
            */}
          </div>
        ),
      },
    ]

    return items
  }

  render = () => {
    const { isInitialized, defaultTab } = this.state

    if (isInitialized !== true) {
      return <div />
    }

    this.items = this.getTabItem()

    return (
      <Tabs
        className={classNames('.contentList')}
        items={this.items}
        defaultActiveKey={defaultTab}
        onChange={this.onTabChanged}
      />
    )
  }
}

export default ContentViewDef
