import React from 'react'
import {
  Spin,
  // Tooltip
} from 'antd'

import classNames from 'classnames'

import {
  // getMembersName,
  // getPhoto,
  getAvatar,
} from 'pages/chat/component/chatView/data/cUtil'

import styles from '../style.module.scss'

class RoomIcon extends React.Component {
  state = {
    loading: false,
    imgElem: null,
  }

  loadedTime = 0

  componentDidMount() {
    const { roomId, roomObj, digest } = this.props

    this.loadImage(roomId, roomObj, digest)
  }

  componentDidUpdate(prevProps) {
    const { roomId, roomObj, digest } = this.props

    if (roomObj != null && this.loadedTime < roomObj.loadedTime) {
      this.loadedTime = roomObj.loadedTime
      this.loadImage(roomId, roomObj, digest)
    } else if (
      roomId !== prevProps.roomId ||
      roomObj !== prevProps.roomObj ||
      digest !== prevProps.digest
    ) {
      this.loadImage(roomId, roomObj, digest)
    }
  }

  componentWillUnmount() {}

  loadImage = (roomId, roomObj /* , digest */) => {
    // if (digest != null) {
    //   this.setState({
    //     loading: true
    //   });
    //   getPhoto(roomId, digest).then((photoUrl) => {
    //     const tooltipTxt = getMembersName(roomId);

    //     const elem = (
    //       <Tooltip title={tooltipTxt}>
    //         <div
    //           className={classNames('roomIcon', 'kit__utils__avatar')}
    //         >
    //           <img className="photo" src={photoUrl} alt="room icon" />
    //         </div>
    //       </Tooltip>
    //     );
    //     this.setState({
    //       loading: false,
    //       imgElem: elem
    //     });
    //   })
    // } else {
    const elem = getAvatar(roomId, roomObj)
    this.setState({
      loading: false,
      imgElem: elem,
    })
    // }
  }

  render = () => {
    const { loading, imgElem } = this.state

    return (
      <React.Fragment>
        <Spin className={classNames(styles.roomIconSpin)} spinning={loading} />
        {imgElem}
      </React.Fragment>
    )
  }
}

export default RoomIcon
