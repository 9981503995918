import React from 'react'
import i18n from 'i18n'

import chatMenuSVG from 'assets/images/menu-conversations.svg'
import archiveMenuSVG from 'assets/images/menu-archives.svg'
import mentionMenuSVG from 'assets/images/menu-mentions.svg'
import commentMenuSVG from 'assets/images/menu-comments.svg'

export default async function getMenuData() {
  const menuList = [
    // {
    //   title: i18n.t('user.title.list'),
    //   // title: 'User List',
    //   key: 'userList',
    //   url: '/users/list',
    //   icon: 'icmn fe fe-users',
    // },
    {
      title: i18n.t('menu.chat'),
      // title: 'User List',
      key: 'menu-conversation',
      url: '/conversation/chat',
      // icon: 'icmn fe fe-message-circle',
      icon: <img src={chatMenuSVG} alt="chat" />,
    },
    {
      title: i18n.t('menu.mention'),
      // title: 'User List',
      key: 'menu-mention',
      url: '/conversation/mention',
      icon: <img src={mentionMenuSVG} alt="mention" />,
    },
    {
      title: i18n.t('menu.comment'),
      key: 'menu-comment',
      url: '/conversation/comment',
      icon: <img src={commentMenuSVG} alt="comment" />,
    },
    {
      title: i18n.t('menu.archive'),
      key: 'menu-archive',
      url: '/conversation/archive',
      icon: <img src={archiveMenuSVG} alt="archive" />,
    },
    // {
    //   title: i18n.t('menu.chatlist'),
    //   key: 'chat',
    //   url: '/chat/list',
    //   icon: 'icmn fe fe-message-circle',
    // },
  ]

  return menuList
}
