// import { notification } from 'antd'
import store from 'store'
import localforage from 'localforage'
import conf from 'conf'
import { store as reduxStore } from 'index'
import { getEnvParam } from 'env'

import { updateToken } from 'services/landing'

const webSocketObj = {
  listenerList: [],
  addListener: (listener) => {
    if (webSocketObj.listenerList.indexOf(listener) < 0) {
      webSocketObj.listenerList.push(listener)
    }
  },
  removeListener: (listener) => {
    const pos = webSocketObj.listenerList.indexOf(listener)
    if (pos > -1) {
      webSocketObj.listenerList.splice(pos, 1)
    }
  },
  onOnline: (e) => {
    console.log(' websocket - window.onOnline ', e)

    if (webSocketObj.websocket != null) {
      const { websocket } = webSocketObj
      if (websocket.socket.readyState > 1) {
        // CLOSING or CLOSED
        websocket.reconnect()
      }
    }
  },
  onOffline: (e) => {
    console.log(' websocket - window.onOffline ', e)
    // setTimeout(() => {
    //   console.log(' --- window.onBlur - after ', document.hidden);
    // }, 500);
    if (webSocketObj.websocket != null) {
      const { websocket } = webSocketObj
      if (websocket.socket.readyState <= 1) {
        // CONNECTING or OPEN
        websocket.close()
      }
    }
  },
}

window.addEventListener('online', webSocketObj.onOnline)
window.addEventListener('offline', webSocketObj.onOffline)

function MainWebSocket() {
  const self = this
  self.pingInterval = 1 * 60 * 1000 // 1분 마다 ping
  self.timoutInterval = 10 * 1000 // 10초 동안 ping의 응답이 없으면 timeout.

  self.email = getEnvParam('userEmail')
  self.workspaceId = store.get('app.user.workspace_id')

  // const params = {
  //   email: user.email,
  //   chat_room_id: this.chatRoomId
  // }

  self.reconnectCnt = 0

  const reconnect = () => {
    clearTimeout(self.timerId)
    clearTimeout(self.checkTimerId)

    if (window.navigator.onLine === true) {
      let interval = 3 * 1000 // 처음 재연결 시에는 3초 후 연결 요청.
      if (self.reconnectCnt > 3) {
        interval = 30 * 1000 // 3회 이상 재연결 실패 시에는 30초마다 연결 시도.
      } else if (self.reconnectCnt > 0) {
        interval = 10 * 1000 // 1회 이상 재연결 실패 시에는 10초마다 연결 시도.
      }

      self.timerId = setTimeout(() => {
        // self.close()
        self.open()

        self.checkTimerId = setTimeout(() => {
          if (self.socket.readyState > 1) {
            // CLOSING or CLOSED
            self.reconnectCnt += 1
            reconnect()
          } else {
            self.reconnectCnt = 0
          }
        }, 1000)
      }, interval)
    }
  }

  self.reconnect = reconnect

  const startReconnect = () => {
    reconnect() // 재연결 시도.
  }

  const stopReconnect = () => {
    clearTimeout(self.timerId)
    clearTimeout(self.checkTimerId)
  }

  self.open = async () => {
    self.alive = true

    const token = await localforage.getItem('app.user.token')

    const protocol = conf.SERVER.restURL.indexOf('https') > -1 ? 'wss://' : 'ws://'

    // const deviceUUID = getDeviceUUID(email)

    // let api = `ws://JWT:${token}@${conf.SERVER.wsAddress}/api/chat/open/`;
    // const api = `${protocol}${conf.SERVER.wsAddress}/api/chat/open/${self.workspaceId}/${self.email}/${deviceUUID}`
    const api = `${protocol}${conf.SERVER.wsAddress}/api/chat/open/${self.workspaceId}/${self.email}`

    self.socket = new WebSocket(api)

    self.socket.onmessage = (event) => {
      // your subscription stuff
      // console.log('websocket - onmessage - ', event)

      self.parseMsg(event.data)
    }

    self.socket.onerror = (err) => {
      console.log('websocket - error - ', err)

      self.socket.close()
    }

    self.socket.onopen = (evt) => {
      console.log('websocket - onopen - ', Date().toString(), evt)

      stopReconnect()

      const jwtToken = `JWT:${token}`

      self.socket.send(jwtToken)

      self.notifyListener('common', { type: 'socket', code: 1, message: 'opened' })
    }

    self.socket.onclose = (evt) => {
      console.log('websocket - onclose - ', Date().toString(), evt)

      if (self.alive === true) {
        startReconnect()
      }
      self.notifyListener('common', { type: 'socket', code: 0, message: 'closed' })
    }
  }

  self.close = () => {
    if (self.timeoutTimer != null) {
      clearTimeout(self.timeoutTimer)
    }
    if (self.pingTimer != null) {
      clearTimeout(self.pingTimer)
    }
    stopReconnect()
    self.alive = false
    self.socket.close()
  }

  self.send = (str) => {
    if (self.alive === true) {
      self.socket.send(str)
    }
  }

  self.ping = () => {
    if (self.alive === true) {
      if (self.wsVersion != null) {
        if (self.timeoutTimer != null) {
          clearTimeout(self.timeoutTimer)
        }
        self.timeoutTimer = setTimeout(() => {
          self.timeoutTimer = null
          self.socket.close()
        }, self.timoutInterval)
      }

      self.socket.send('ping:{}')
    }
  }

  self.checkPing = (callByPong) => {
    if (self.alive === true && self.wsVersion != null) {
      if (callByPong === true) {
        if (self.timeoutTimer != null) {
          clearTimeout(self.timeoutTimer)
        }
      }
      if (self.pingTimer != null) {
        clearTimeout(self.pingTimer)
      }
      self.pingTimer = setTimeout(() => {
        self.ping()
      }, self.pingInterval)
    }
  }

  self.parseMsg = (msg) => {
    const pos = msg.indexOf(':')
    let cmd = ''
    let json = ''
    if (pos > -1) {
      cmd = msg.substring(0, pos)
      json = msg.substring(pos + 1)
    }

    const data = JSON.parse(json)

    this.notifyListener(cmd, data)
  }

  self.notifyListener = (cmd, data) => {
    console.log(' - websocket - notifyListener - ', cmd, data)

    // Signature has expired.
    if (cmd === 'error' && data.code === 401) {
      self.close()

      updateToken()
        .then((token) => {
          console.log(' new token : ', token)
          if (token != null) {
            self.open()
          }
        })
        .catch((err) => {
          console.log(' error : ', err)
          reduxStore.dispatch({
            type: 'user/LOGOUT',
          })
        })

      return
    }

    // if (cmd === 'system') {
    //   console.log(' system : ', data);
    //   notification.warning({
    //     message: 'chatting system message',
    //     description: data,
    //   })
    // }

    if (cmd === 'success') {
      if (data.ws_version != null) {
        self.wsVersion = data.ws_version

        self.checkPing(false)
      }
      return
    }

    if (cmd === 'pong') {
      self.checkPing(true)
      return
    }

    webSocketObj.listenerList.forEach((listener) => {
      if (listener.filters != null) {
        const checkFilter = Object.entries(listener.filters).some(([key, value]) => {
          let ret = true
          if (key === 'cmd' && value === cmd) {
            ret = false
          } else if (data[key] === value) {
            ret = false
          }
          return ret
        })

        if (cmd === 'common' || checkFilter === false) {
          listener.cbFunc(cmd, data)
        }
      } else if (listener.cbFunc != null) {
        listener.cbFunc(cmd, data)
      } else if (typeof listener === 'function') {
        listener(cmd, data)
      }
    })
  }

  self.open()
}

export function sendWS(str) {
  if (webSocketObj.websocket != null) {
    webSocketObj.websocket.send(str)
  }
}

export function startWS() {
  if (webSocketObj.websocket == null) {
    webSocketObj.websocket = new MainWebSocket()
  }
}

export function stopWS() {
  if (webSocketObj.websocket != null) {
    webSocketObj.websocket.close()
    webSocketObj.websocket = null
  }
}

export function isConnectedWS() {
  if (webSocketObj.websocket != null) {
    return webSocketObj.websocket.alive
  }
  return false
}

export function addWSListener(listener) {
  if (webSocketObj != null) {
    webSocketObj.addListener(listener)
  }
}

export function removeWSListener(listener) {
  if (webSocketObj != null) {
    webSocketObj.removeListener(listener)
  }
}

export default webSocketObj
