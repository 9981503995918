import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { withRouter } from 'router'
// import { withRouter } from 'router'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
import MainLayout2 from './Main2'
import EmptyLayout from './Empty'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  main2: MainLayout2,
  empty: EmptyLayout,
  outlook: EmptyLayout,
}

const Main2List = ['/conversation', '/email/list', '/chat/list']

const mapStateToProps = ({ user }) => ({ user })
// let previousPath = ''
let previousPathname = ''

const Layout = ({ user, children, location }) => {
  // const Layout = ({ children, location }) => {
  // NProgress & ScrollTop Management
  // const { pathname, search } = location;
  const { pathname } = location

  // const currentPath = pathname + search
  if (previousPathname !== pathname) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    // previousPath = currentPath;
    previousPathname = pathname
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    // if (pathname.indexOf('/downloadFile') === 0) {
    //   return 'empty'
    // }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }

    if (pathname.indexOf('/plain') === 0) {
      return 'empty'
    }
    if (pathname.indexOf('/outlook') === 0) {
      return 'outlook'
    }
    if (pathname.indexOf('/conversation') === 0) {
      return 'main2'
    }
    if (Main2List.indexOf(pathname) >= 0) {
      return 'main2'
    }
    return 'main'
  }

  const layout = getLayout()
  const Container = Layouts[layout]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = layout === 'auth'
  const isOutlookLayout = layout === 'outlook'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isOutlookLayout && !isAuthLayout && !isUserAuthorized) {
      if (pathname != null && pathname !== '/' && pathname.indexOf('/auth') < 0) {
        return <Navigate to={`/auth/login?path=${pathname}`} />
      }
      return <Navigate to="/auth/login" />
    }

    if (
      isUserAuthorized &&
      pathname.indexOf('/auth/login') === 0 &&
      pathname.indexOf('/auth/login/guest/') !== 0
    ) {
      return <Navigate to="/conversation/chat" />
    }

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      {/* <Helmet titleTemplate="OfficeMail Messenger | %s" title="OfficeMail Messenger" /> */}
      <Helmet titleTemplate="OfficeMail Messenger" title="OfficeMail Messenger" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
