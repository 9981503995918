import React from 'react'
import { Input, Button } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import classNames from 'classnames'

import iconSearchSVG from 'assets/images/icon-search.svg'

import FileList from './FileList'

import styles from '../style.module.scss'
import './style.module.scss'

class FileViewDef extends React.Component {
  state = {
    coverBtnClicked: false,
  }

  searchInput = React.createRef()

  componentDidMount() {}

  // componentDidUpdate(prevProps) {
  // }

  componentWillUnmount() {}

  onClickSearchBox = () => {
    if (this.searchInput.current != null) {
      this.searchInput.current.input.focus()
    }
    this.setState({
      coverBtnClicked: true,
    })
  }

  onBlurSearchBox = () => {
    this.setState({
      coverBtnClicked: false,
    })
  }

  onEnterSearchBox = (txt) => {
    this.fileList.search(txt)
  }

  onClickRefreshBtn = () => {
    this.fileList.refresh()
  }

  render() {
    const { coverBtnClicked } = this.state
    const { roomObj, chatObj, options, grid, type, mimeTypeRegEx, active } = this.props

    const refreshBtn = (
      <Button
        className={styles.refreshBtn}
        type="text"
        icon={<SyncOutlined style={{ fontSize: 16 }} />}
        htmlType="button"
        onClick={this.onClickRefreshBtn}
      />
    )

    const coverBtnProps = {}

    if (coverBtnClicked) {
      coverBtnProps[styles.clicked] = true
    }

    return (
      <div
        ref={(ref) => {
          this.bodyElem = ref
        }}
        className={classNames(styles.fileView, { imageType: type === 'image' })}
      >
        <div className={classNames('searchWrapper')}>
          <div className={classNames('searchBox')}>
            <Input.Search
              ref={this.searchInput}
              placeholder="Search for files"
              onSearch={this.onEnterSearchBox}
              enterButton={<img src={iconSearchSVG} alt="search" />}
            />
          </div>
        </div>
        <FileList
          ref={(ref) => {
            this.fileList = ref
          }}
          roomObj={roomObj}
          chatObj={chatObj}
          options={options}
          type={type}
          grid={grid}
          mimeTypeRegEx={mimeTypeRegEx}
          active={active}
        />
        {refreshBtn}
      </div>
    )
  }
}

const FileView = FileViewDef

export default FileView
