import { all, put, takeEvery, call } from 'redux-saga/effects'
import i18n from 'i18n'

import getMenuData from 'services/menu'
import actions from './actions'

const menuActionListener = []

export function* GET_DATA() {
  if (i18n.isInitialized !== true) {
    return
  }
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* ADD_LISTENER({ payload: { listener } }) {
  if (menuActionListener.indexOf(listener) < 0) {
    yield menuActionListener.push(listener)
  }
}

export function* REMOVE_LISTENER({ payload: { listener } }) {
  const pos = menuActionListener.indexOf(listener)
  if (pos >= 0) {
    yield menuActionListener.splice(pos, 1)
  }
}

export function* NOTIFY_LISTENER({ payload: { key } }) {
  yield menuActionListener.forEach((listener) => {
    listener(key)
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RELOAD, GET_DATA),
    takeEvery(actions.ADD_LISTENER, ADD_LISTENER),
    takeEvery(actions.REMOVE_LISTENER, REMOVE_LISTENER),
    takeEvery(actions.NOTIFY_LISTENER, NOTIFY_LISTENER),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
