class IMAPAPI {
  // constructor() {}

  async getCurrentAccount() {
    return null
  }

  async getMyProfilePhoto(size) {
    console.log(size)
    return null
  }
}

const imapAPI = new IMAPAPI()

export default imapAPI
