class GmailAPI {
  // constructor() {}

  async getCurrentAccount() {
    return null
  }

  async getMyProfilePhoto(size) {
    console.log(size)
    return null
  }
}

const gmailAPI = new GmailAPI()

export default gmailAPI
