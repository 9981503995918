import { ApolloClient, ApolloLink, split, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import { createClient } from "graphql-ws";
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import store from 'store'
import localforage from 'localforage'

// eslint-disable-next-line import/no-cycle
import { customFetch, customFetchWithToken } from './services/jwt'

import RestClient from './services/restClient'

import conf from './conf'

const httpLink = new HttpLink({
  uri: conf.SERVER.URL,
  fetch: customFetch,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = store.get('app.user.token')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
  }

  // if (token != null) {
  //   newHeaders.authorization = `JWT ${token}`
  // }

  return {
    headers: newHeaders,
  }
})

let link = null

console.log(' wsURL : ', conf.SERVER.wsURL)

if (conf.SERVER.wsURL == null) {
  link = authLink.concat(ApolloLink.from([httpLink]))
} else {
  // websocket - sub protocol = 'graphql-transport-ws' 인 경우 초기화.
  // (주의. sub protocol이 module 이름과 반대임.)
  // const wsLink = new GraphQLWsLink(
  //   createClient({
  //     url: conf.SERVER.wsURL,
  //     // options: {
  //     //   reconnect: true,
  //     // },
  //   })
  // );

  // websocket - sub protocol = 'graphql-ws' 인 경우 초기화.
  const wsLink = new WebSocketLink(
    new SubscriptionClient(conf.SERVER.wsURL, {
      reconnect: true,
    }),
  )

  link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(ApolloLink.from([httpLink])),
  )
}

const cache = new InMemoryCache()
const client = new ApolloClient({
  link,
  cache,
  queryDeduplication: false,
})

export default client

const httpLinkForLanding = new HttpLink({
  uri: conf.SERVER.URL,
  fetch: customFetchWithToken,
})

const authLinkForLanding = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localforage.getItem('app.user.token')

  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

const linkForClient = authLinkForLanding.concat(ApolloLink.from([httpLinkForLanding]))

const cacheForLanding = new InMemoryCache()
export const clientForLanding = new ApolloClient({
  link: linkForClient,
  cache: cacheForLanding,
  queryDeduplication: false,
})

const httpLinkForRefreshToken = new HttpLink({
  uri: conf.SERVER.URL,
})

const authLinkForRefreshToken = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localforage.getItem('app.user.refreshToken')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

const linkForRefreshToken = authLinkForRefreshToken.concat(
  ApolloLink.from([httpLinkForRefreshToken]),
)

const cacheForRefreshToken = new InMemoryCache()

export const clientForRefreshToken = new ApolloClient({
  link: linkForRefreshToken,
  cache: cacheForRefreshToken,
  queryDeduplication: false,
})

export const restLandingClient = new RestClient(conf.SERVER.restLandigURL, fetch, false)

export const restClient = new RestClient(conf.SERVER.restURL, customFetchWithToken, true)

export const restClientWithNoToken = new RestClient(conf.SERVER.restURL, fetch, false)
