import { notification } from 'antd'
import store from 'store'
import localforage from 'localforage'
// import { ApolloError } from '@apollo/client'

import { store as reduxStore } from 'index'
import { setEnvParam } from 'env'

import { clientForRefreshToken } from 'myNet'

// eslint-disable-next-line import/no-cycle
import Ngql from 'gql/ninegql'
import { parseJwt, processError } from 'utils'
import { refresh } from 'less'

const mutationLogin = Ngql.Mutation('tokenAuth', {
  tokenAuth: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      email: Ngql.Var('email', 'String!'),
      password: Ngql.Var('password', 'String!'),
      serverType: Ngql.Var('serverType', 'String'),
      serverUri: Ngql.Var('serverUri', 'String'),
    },
  ),
})

const mutationUpdataToken = Ngql.Mutation('refreshToken', {
  refreshToken: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      // refreshToken: Ngql.Var('refreshToken', 'String!'),
    },
  ),
})

const updateInfo = {
  isUpdating: false,
  currentQuery: null,
  waitingReq: [],
}

export function updateToken() {
  console.log(' call update token ~!!!')
  const retPromise = new Promise(async (resolve, reject) => {
    const refreshToken = await localforage.getItem('app.user.refreshToken')

    if (refreshToken != null) {
      if (updateInfo.isUpdating === false) {
        updateInfo.isUpdating = true
        // await localforage.setItem('app.user.token', refreshToken)
        try {
          const res = await Ngql.GQLObj(mutationUpdataToken, {
            // vars: {
            //   refreshToken,
            // },
          }).mutate({}, clientForRefreshToken)

          if (
            res.data.refreshToken.token == null ||
            res.data.refreshToken.token.trim().length === 0
          ) {
            throw new Error('updateToken - empty token..!')
          }

          await localforage.setItem('app.user.token', res.data.refreshToken.token)
          await localforage.setItem(
            'app.user.refreshExpiresIn',
            res.data.refreshToken.refreshExpiresIn,
          )
          await localforage.setItem('app.user.refreshToken', res.data.refreshToken.refreshToken)

          updateInfo.waitingReq.forEach((promise) => {
            promise.resolve(res.data.refreshToken.token)
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null

          resolve(res.data.refreshToken.token)
        } catch (ex) {
          await localforage.removeItem('app.user.token')

          updateInfo.waitingReq.forEach((promise) => {
            promise.reject(ex)
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null

          // reduxStore.dispatch({
          //   type: 'user/LOGOUT',1
          // })

          reject(ex)
        }
      } else {
        updateInfo.waitingReq.push({
          resolve,
          reject,
        })
      }
    } else {
      const ex = new Error('RefreshToken is not exist.')
      await localforage.removeItem('app.user.token')

      updateInfo.waitingReq.forEach((promise) => {
        promise.reject(ex)
      })
      updateInfo.waitingReq = []

      updateInfo.isUpdating = false
      updateInfo.currentQuery = null

      // reduxStore.dispatch({
      //   type: 'user/LOGOUT',
      // })

      reject(ex)
    }
  })

  return retPromise
}

function callUpdateToken(uri, options, isTokenEmpty) {
  const retPromise = new Promise(async (resolve, reject) => {
    updateToken()
      .then((token) => {
        console.log(' new token : ', token)
        if (token != null) {
          const newOptions = {
            ...options,
          }
          newOptions.headers.authorization = `JWT ${token}`
          fetch(uri, newOptions).then((res2) => {
            resolve(res2)
          })
        } else {
          reject(new Error('updateToken failed.'))
        }
      })
      .catch((ex) => {
        // refreshToken이 없거나 문제가 있는 경우 여기서 처리하자.
        if (isTokenEmpty !== true) {
          notification.warning({
            message: ex.code || 'updateToken failed.',
            description: ex.message || ex.detail,
            // duration: 0,
          })
        }
        console.log('updateToken failed.', ex)
        // throw ex;
        // responseData = null
        // reject(ex)
      })
  })

  return retPromise
}

export async function customFetchWithToken(uri, options) {
  let isTokenEmpty = null
  const token = await localforage.getItem('app.user.token')

  if (token == null || token.trim().length === 0) {
    // throw new Error('authLink - empty token..!')
    isTokenEmpty = true
  }

  return customFetch(uri, options, isTokenEmpty)
}

export function customFetch(uri, options, isTokenEmpty) {
  console.log(' ==== customFetch ', uri, options, isTokenEmpty, this)
  // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
  // Create initial fetch, this is what would normally be executed in the link without the override

  if (isTokenEmpty === true) {
    console.log(' ==== customFetch - token is Empty~! ')
    const callRet = callUpdateToken(uri, options, isTokenEmpty)
    return callRet.retPromise
  }

  const initialRequest = fetch(uri, options)

  let responseData = null
  // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
  // Here we return the initialRequest promise
  return initialRequest
    .then((response) => {
      const ret = response.clone().json()
      console.log(' ==== fetch result ', response, ret)
      responseData = response
      return ret
    })
    .then((error) => {
      if (responseData.status >= 400 && error && error.code !== 200) {
        let ret = null
        processError(error, (code, message) => {
          if (code === 401 || message.indexOf('Signature has expired') > -1) {
            console.log(' !!! Signature has expired ')
            ret = callUpdateToken(uri, options, false)
            // } else if (message.indexOf('Unauthenticated token') > -1) {
            //   if (window.parent === window) {
            //     setLoginVisible(true)
            //   } else {
            //     window.parent.nPopup.setLoginVisible(true)
            //   }
            //   return true
            // }
          } else if (code === 500 || message === 'Not authenticated') {
            // @todo : office addin에서 동작 중 문제가 발생하면, office email로 로그인 할 수 있도록 경로를 지정해야 함.
            reduxStore.dispatch({
              type: 'user/LOGOUT',
            })
            throw new Error(message)
          }
          return ret
        })

        if (ret != null) {
          return ret
        }

        // if (isError === true) {
        //   const err = new ApolloError();
        //   err.code = error.code;
        //   err.errors = error.errors;
        //   throw err;
        // }
      }

      return responseData
    })
  // .catch((err) => {
  //   console.log(' --- log test. ---');
  //   console.log(' --- test ---', err);
  // })
}

export async function login(email, password) {
  return Ngql.GQLObj(mutationLogin, {
    vars: {
      email,
      password,
      serverType: 'exchange',
      serverUri: 'mail.ninefolders.xyz',
    },
    useUI: false,
  })
    .mutate()
    .then(async (res) => {
      if (process.env.REACT_APP_TARGET !== 'production') {
        store.set('app.user.token', res.data.tokenAuth.token)
        store.set('app.user.refreshExpiresIn', res.data.tokenAuth.refreshExpiresIn)
        store.set('app.user.refreshToken', res.data.tokenAuth.refreshToken)
      }

      await localforage.setItem('app.user.token', res.data.tokenAuth.token)
      await localforage.setItem('app.user.refreshExpiresIn', res.data.tokenAuth.refreshExpiresIn)
      await localforage.setItem('app.user.refreshToken', res.data.tokenAuth.refreshToken)

      return true
    })
    .catch((error) => {
      updateInfo.params = null
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

const queryUserInfo = Ngql.Query('userInfo', {
  // me: Ngql.Node({
  //   id: true,
  //   email: true,
  //   username: true,
  //   avatarUrl: true,
  //   ewsUri: true,
  //   authType: true,
  //   provider: true,
  //   protocol: true,
  //   version: true,
  //   createdAt: true,
  //   updatedAt: true,
  // }),
  systemInfo: Ngql.Node({
    appName: true,
    description: true,
    adminEmail: true,
    version: true,
    locale: true,
    timezone: true,
  }),
  serverType: true,
})

export async function register(/* email, password, name */) {
  function fakeRegister() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeRegister()
}

export async function currentAccount() {
  const token = await localforage.getItem('app.user.token')

  if (token && token.length > 0) {
    return Ngql.GQLObj(queryUserInfo, {
      useUI: false,
    })
      .query()
      .then((res) => {
        console.log(' ---- ', res)

        setEnvParam('serverType', res.data.serverType)
        reduxStore.dispatch({
          type: 'menu/RELOAD',
        })

        if (res.data.systemInfo == null) {
          const tokenInfo = parseJwt(token)
          return {
            name: '',
            uid: '',
            email: tokenInfo.email,
            photoURL: '',
            role: 'admin',
            lastLogin: '',
            serverType: res.data.serverType,
          }
        }
        return {
          name: 'Administrator',
          uid: '',
          email: res.data.systemInfo.adminEmail,
          // photoURL: res.data.me.avatar,
          role: 'admin',
          lastLogin: '',
          serverType: res.data.serverType,
        }
      })
      .catch((error) => {
        if (error) {
          console.log(' ---- ', error)
          if (error.code !== 401) {
            notification.warning({
              message: error.code,
              description: error.message,
            })
          }
        }
      })
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(false)
    }, 200)
  })
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)

  async function fakeLogout() {
    if (process.env.REACT_APP_TARGET !== 'production') {
      store.remove('app.user.token')
      store.remove('app.user.refreshToken')
      store.remove('app.user.workspace_id')
      store.remove('app.user.refreshExpiresIn')
    }

    await localforage.removeItem('app.user.token')
    await localforage.removeItem('app.user.refreshToken')
    await localforage.removeItem('app.user.workspace_id')
    await localforage.removeItem('app.user.refreshExpiresIn')
    await localforage.removeItem('app.user.email')

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return await fakeLogout()
}

const jwt = {
  customFetch,
  login,
  logout,
}

export default jwt
