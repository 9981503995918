import React from 'react'
// import React, { lazy, Suspense } from 'react'
import loadable from '@loadable/component'
import { Spin } from 'antd'
import { Route, Navigate, Routes, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { history as globalHistory } from 'index'
import { getDefaultPage } from 'env'

import { parseSearchParam, processNParam } from 'utils'

import Layout from 'layouts'

export function withRouter(Component, options) {
  return (props) => {
    const newProps = {
      ...props,
    }

    newProps.location = useLocation()
    newProps.navigate = useNavigate()
    newProps.history = globalHistory
    newProps.match = {
      params: useParams(),
      path: newProps.location.pathname,
    }

    if (options != null && options.navigate === true) {
      newProps.navigate = useNavigate()
    }

    return <Component {...newProps} />
  }
}

const config = {
  fallback: <Spin />,
}

function getRoutes() {
  const routes = [
    // {
    //   path: '/users/list',
    //   Component: loadable(() => import('pages/users/list'), config),
    // },
    // {
    //   path: '/users/edit/:id',
    //   Component: loadable(() => import('pages/users/edit'), config),
    // },
    // {
    //   path: '/users/detail/:id',
    //   Component: loadable(() => import('pages/users/detail'), config),
    // },
    // {
    //   path: '/users/create',
    //   Component: loadable(() => import('pages/users/edit'), config),
    // },
    // {
    //   path: '/chat/list/:userId',
    //   Component: loadable(() => import('pages/chat/list'), config),
    // },
    {
      path: '/chat/list',
      Component: loadable(() => import('pages/chat/list'), config),
    },
    {
      path: '/chat/room/:pmId',
      Component: loadable(() => import('pages/chat/room'), config),
    },
    {
      path: '/outlook/main',
      Component: loadable(() => import('pages/outlook/main'), config),
    },
    {
      path: '/outlook/room',
      Component: loadable(() => import('pages/outlook/room'), config),
    },
    {
      path: '/email/test',
      Component: loadable(() => import('pages/email/test'), config),
    },
    {
      path: '/email/list',
      Component: loadable(() => import('pages/email/list'), config),
    },
    {
      path: '/mention/list',
      Component: loadable(() => import('pages/chat/mentionList'), config),
    },
    {
      path: '/comment/list/',
      Component: loadable(() => import('pages/chat/commentList'), config),
    },
    {
      path: '/conversation',
      Component: loadable(() => import('pages/conversations/detail'), config),
      exact: true,
    },
    {
      path: '/conversation/:view',
      Component: loadable(() => import('pages/conversations/detail'), config),
      exact: true,
    },
    // {
    //   path: '/conversation/:wId/:roomId',
    //   Component: loadable(() => import('pages/conversations/detail'), config),
    // },
    {
      path: '/conversation/:view/:wId/:roomId',
      Component: loadable(() => import('pages/conversations/detail'), config),
    },
    {
      path: '/conversation/:view/:wId/:roomId/:messageId',
      Component: loadable(() => import('pages/conversations/detail'), config),
    },
    {
      path: '/conversation/:view/:wId/:roomId/:messageId/:commentId',
      Component: loadable(() => import('pages/conversations/detail'), config),
    },
    {
      path: '/email/detail/:mailId',
      Component: loadable(() => import('pages/email/detail'), config),
    },
    {
      path: '/plain/waiting',
      Component: loadable(() => import('pages/waiting'), config),
    },
    {
      path: '/plain/guest/:wId/:roomId',
      Component: loadable(() => import('pages/conversations/guest'), config),
    },
    {
      path: '/auth/login',
      Component: loadable(() => import('pages/auth/login'), config),
      exact: true,
    },
    {
      path: '/auth/login/guest/:key',
      Component: loadable(() => import('pages/auth/guestByKey'), config),
      exact: true,
    },
    // {
    //   path: '/auth/forgot-password',
    //   Component: loadable(() => import('pages/auth/forgot-password'), config),
    //   exact: true,
    // },
    // {
    //   path: '/auth/register',
    //   Component: loadable(() => import('pages/auth/register'), config),
    //   exact: true,
    // },
    {
      path: '/auth/lockscreen',
      Component: loadable(() => import('pages/auth/lockscreen'), config),
      exact: true,
    },
    {
      path: '/auth/404',
      Component: loadable(() => import('pages/auth/404'), config),
      exact: true,
    },
    {
      path: '/auth/500',
      Component: loadable(() => import('pages/auth/500'), config),
      exact: true,
    },
  ]

  return routes
}

const mapStateToProps = ({ user, settings }) => ({
  routerAnimation: settings.routerAnimation,
  serverType: user.serverType,
})

@connect(mapStateToProps)
class Router extends React.Component {
  constructor(props) {
    super(props)
    // store.set('app.router.location', '');

    const { history } = props
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange)
    this.handleLocationChange(history.location)
  }

  // componentDidMount() {
  // }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  handleLocationChange = (e) => {
    const { history } = this.props
    console.log('ROUTE CHANGED', e, history)

    parseSearchParam()
    processNParam()
  }

  // const Router = ({ history, routerAnimation, serverType }) => {
  render = () => {
    const { location, routerAnimation, serverType } = this.props

    const myRoutes = getRoutes(serverType)

    const defaultPage = getDefaultPage()

    return (
      <Layout>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            appear
            classNames={routerAnimation}
            timeout={routerAnimation === 'none' ? 0 : 300}
          >
            <Routes location={location}>
              <Route exact path="/" element={<Navigate to={defaultPage} />} />
              {myRoutes.map(({ path, Component, exact }) => (
                <Route
                  path={path}
                  key={path}
                  exact={exact}
                  element={
                    <div className={routerAnimation}>
                      <Component />
                    </div>
                  }
                />
              ))}
              <Route exact path="*" element={<Navigate to="/auth/404" />} />
            </Routes>
          </CSSTransition>
        </SwitchTransition>
      </Layout>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Router))
