import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'router'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
// import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
// import Footer from 'components/cleanui/layout/Footer'
import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'

import logoSVG from 'assets/images/logo.svg'

import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const MainLayout = ({
  logo,
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
}) => {
  const devStr = '' // (process.env.NODE_ENV !== 'production') ? ' (dev)' : '';

  return (
    <div
      className={classNames('root', 'layout1110', {
        cui__layout__grayBackground: isGrayBackground,
      })}
    >
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Layout.Header
          className={classNames('cui__layout__header', styles.header, {
            cui__layout__fixedHeader: isTopbarFixed,
            cui__layout__headerGray: isGrayTopbar,
          })}
        >
          <div className="logo">
            <img src={logoSVG} className={styles.logoImg} alt={logo} />
            <span className={styles.nameWrap}>
              <span className={styles.name}>
                {logo}
                {devStr}
              </span>
            </span>
          </div>
          <TopBar />
        </Layout.Header>
        <Layout>
          <Sidebar />
          {/* <SupportChat /> */}
          <Menu />
          {/*
          <React.Suspense fallback={<div>Loading...</div>}>
            <Breadcrumbs />
          </React.Suspense>
          */}
          <Layout.Content style={{ position: 'relative' }}>
            <div className={classNames('cui__utils__content', styles.content)}>{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
