import { initializeApp } from 'firebase/app'
import { getMessaging, deleteToken, onMessage, getToken, isSupported } from 'firebase/messaging'

import i18n from 'i18n'
import store from 'store'
import { getEnvParam } from 'env'

import dataMgr from 'pages/chat/component/chatView/data/dataManager'
import { searchMember } from 'pages/conversations/component/roomUtil'
import cUtil from 'pages/chat/component/chatView/data/cUtil'

import { matchPath } from 'react-router'
import { getRouterType } from 'env'

import { history } from 'index'

// for test
// const firebaseConfig = {
//   apiKey: 'AIzaSyAWQBbqYCqogQhsWZObHKUTVw0Q39OHm9s',
//   authDomain: 'rework-chat-for-web---test.firebaseapp.com',
//   projectId: 'rework-chat-for-web---test',
//   storageBucket: 'rework-chat-for-web---test.appspot.com',
//   messagingSenderId: '664656991491',
//   appId: '1:664656991491:web:4eb39d7a0a6b42328ec536',
// }

// const vapidKey =
//   'BIo9FJIMc-nUVe0b64qqxn6i9ei0j4J0psNjGisG5Pfd53KkP6UO7CcZ5pOQR1-0_sRniM202h13Tpmq-vbem6M'

const firebaseConfig = {
  apiKey: 'AIzaSyAUqnSk8g5NYqo-V2A3D7OA0lL59OzUST8',
  authDomain: 'rework-for-web.firebaseapp.com',
  projectId: 'rework-for-web',
  storageBucket: 'rework-for-web.appspot.com',
  messagingSenderId: '593654720572',
  appId: '1:593654720572:web:5dd4bc768de724ff92bb39',
  measurementId: 'G-8B069PCN4M',
}

const vapidKey =
  'BCmf7SZlQB2AxBSnNHCyEcEjemhzarjVvW8Zp3upc6Ylw8cZToEH1Bw0hR0vt2WJhfJGd9dMNygw7tRMGQrJJp4'

// fcm send test. - "https://developers.google.com/oauthplayground"에서 발송. (권한은 Firebase cloud messaging에 있는 걸로 체크).
// send url - https://fcm.googleapis.com/v1/projects/rework-chat-for-web---test/messages:send
// fcm send 시 header 전달해야 하는 oauth2 token은 "https://developers.google.com/oauthplayground"에서 google developer로 로그인해서 발급받는 access_token임.com
// fcm send 시에 body에 같이 보내는 token은 FCM_TOKEN - firebase -> getMessaging() -> getToken() 에서 얻어오는 token 임
/* body example
{
    "message": {
        "token": "{FCM_TOKEN}",
        "notification": {
          "body": "Body of Your Notification in data",
          "title": "Title of Your Notification in data"
        }
    },
    "validate_only": false
}
*/

const firebaseObj = {}

const routerType = getRouterType()

isSupported().then((value) => {
  firebaseObj.isSupported = value

  if (value === true) {
    firebaseObj.app = initializeApp(firebaseConfig)
    firebaseObj.messaging = getMessaging(firebaseObj.app)
  }
})

export function refreshMessagingToken(cbFunc) {
  if (firebaseObj.isSupported === true) {
    return firebaseObj.messaging.onTokenRefresh(cbFunc)
  }
  return null
}

const notiMap = {}

const messageObj = {}

async function getNotificationInfo(data) {
  const {
    chat_room_name,
    chat_room_type,
    display_names,
    sender,
    sender_display_name,
    reaction_email,
    reaction_type,
    reaction_data,
    type,
    content,
    attachments_count,
    comment_id,
    message_id,
    object_type,
    workspace_id,
    chat_room_id,
  } = data
  // web에서는 mentions에 대한 처리를 따로 하지 않음.
  // console.log(data);

  const notiInfo = {
    workspace_id,
    chat_room_id,
    sender: sender || reaction_email,
  }

  notiInfo.senderName = sender_display_name || notiInfo.sender

  const myWorkspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  if (String(myWorkspaceId) === workspace_id) {
    const res = await searchMember(notiInfo.sender)
    if (res.data != null && res.data.data != null) {
      const memberInfo = res.data.data.find((item) => {
        return item.email === notiInfo.sender
      })

      if (memberInfo != null && memberInfo.avatar_url != null) {
        notiInfo.icon = memberInfo.avatar_url
      }
    }
  }

  if (comment_id != null || object_type === 'comment') {
    notiInfo.type = 'comment'
  } else {
    notiInfo.type = 'message'
  }

  let roomName = null
  let msgContent = null
  let isDirectMessage1to1 = false

  if (chat_room_type === 'direct_message') {
    const members = JSON.parse(display_names)
    const others = members.filter((member) => {
      return member.email !== notiInfo.sender && member.email !== userEmail
    })
    let displayName = sender_display_name
    if (others.length > 0) {
      displayName = others[0].display_name
    }
    if (members.length === 1) {
      isDirectMessage1to1 = true
      roomName = displayName
    } else if (members.length === 2) {
      isDirectMessage1to1 = true
      // roomName = i18n.t('chat.member.desc1', { name: displayName, count: 1 })
      roomName = displayName
    } else {
      roomName = i18n.t('chat.member.desc2', { name: displayName, count: members.length - 1 })
    }
  } else {
    roomName = chat_room_name
  }

  if (reaction_type != null) {
    // reaction noti.
    const reactionType = JSON.parse(reaction_type)
    const reactionInfo = {}
    if (reaction_data == null) {
      const reactionItem = cUtil.ReactionList[reactionType]
      reactionInfo.emoji = reactionItem.label
    } else {
      reactionInfo.emoji = reaction_data
    }
    if (reactionType === 1) {
      msgContent = i18n.t('chat.reaction.liked', {
        emoji: reactionInfo.emoji,
        name: notiInfo.senderName,
      })
    } else {
      msgContent = i18n.t('chat.reaction.reacted', {
        emoji: reactionInfo.emoji,
        name: notiInfo.senderName,
      })
    }

    const { object_id } = data
    if (object_type === 'message') {
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}/${object_id}`
    } else if (object_type === 'comment') {
      const { parent_object_id } = data
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}/${parent_object_id}/${object_id}`
    } else {
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}`
    }

    notiInfo.title = roomName
    notiInfo.content = msgContent
  } else {
    // message/comment noti.
    if (type === 'file') {
      const count = parseInt(attachments_count, 10)
      if (count === 1) {
        msgContent = `Sent a file.`
      } else if (count > 1) {
        msgContent = `Sent ${count} files.`
      }
    } else {
      msgContent = `${content}`
    }

    if (notiInfo.type === 'comment') {
      const { parent_object_id } = data
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}/${parent_object_id}/${comment_id}`
    } else if (notiInfo.type === 'message') {
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}/${message_id}`
    } else {
      notiInfo.path = `/conversation/chat/${notiInfo.workspace_id}/${notiInfo.chat_room_id}`
    }

    notiInfo.title = notiInfo.senderName
    if (isDirectMessage1to1 === true) {
      notiInfo.content = msgContent
    } else {
      notiInfo.content = `${roomName}\n${msgContent}`
    }
  }

  return notiInfo
}

export function getMessagingToken(byUser) {
  const promise = new Promise((resolve, reject) => {
    const isNotificationSupported = 'Notification' in window
    if (isNotificationSupported) {
      const processNotiResult = (result) => {
        if (result === 'granted') {
          console.log('[Notification] 허용: ', result)

          if (firebaseObj.isSupported === true) {
            const { messaging } = firebaseObj

            getToken(messaging, { vapidKey })
              .then((currentToken) => {
                if (currentToken) {
                  console.log(' firebase token - ', currentToken)
                  // Send the token to your server and update the UI if necessary

                  if (messageObj.unsubscribe != null) {
                    messageObj.unsubscribe()
                    messageObj.unsubscribe = null
                  }

                  messageObj.unsubscribe = onMessage(messaging, (payload) => {
                    console.log('onFMSMessage: ', payload)

                    const { notification, data } = payload

                    // notification 정보가 있으면, firebase sdk에서 notification popup을 실행함.
                    if (notification != null) {
                      return
                    }

                    let pathName = ''

                    if (routerType === 'hash') {
                      const { hash } = window.location
                      if (hash != null && hash.length > 0 && hash.indexOf('#') === 0) {
                        pathName = hash.substring(1)
                      }
                    } else {
                      pathName = window.location.pathname
                    }

                    if (data != null && pathName.indexOf('/conversation/') === 0) {
                      const match = matchPath('/conversation/:view/:wId/:roomId', pathName)

                      if (match != null) {
                        const { roomId } = match.params

                        if (roomId !== data.chat_room_id) {
                          // 현재 보여지는 room이 아니므로, 알림을 표시하도록 함.
                          console.log(' ---- foregrount Noti : ', payload)

                          if (data.workspace_id != null && data.chat_room_id != null) {
                            const curKey = `${data.workspace_id != null}_${data.chat_room_id}`

                            if (notiMap[curKey] == null) {
                              notiMap[curKey] = {
                                messages: [],
                                timer: null,
                              }
                            }

                            notiMap[curKey].messages.push(data)

                            // 모든 메시지를 표시하도록 하자.
                            // if (notiMap[curKey].timer != null) {
                            //   clearTimeout(notiMap[curKey].timer)
                            // }
                            ;((key) => {
                              notiMap[key].timer = setTimeout(async () => {
                                const { messages } = notiMap[key]

                                const lastMessage = messages.slice(-1)[0]

                                messages.length = 0

                                const notiInfo = await getNotificationInfo(lastMessage)

                                const notificationTitle = notiInfo.title

                                const notificationOptions = {
                                  body: notiInfo.content,
                                  // actions: [{action: "open_url", title: "Read Now"}]
                                }

                                if (notiInfo.icon != null) {
                                  notificationOptions.icon = notiInfo.icon
                                }

                                notificationOptions.data = {
                                  // checkUrl: `${self.origin}/conversation/chat/`,
                                  // url: `${window.location.origin}/conversation/chat/${data.workspace_id}/${data.chat_room_id}`,
                                  path: notiInfo.path,
                                }
                                // notificationOptions.actions = [
                                //   {
                                //     action: "open_url",
                                //     title: data.sender
                                //   }
                                // ];

                                console.log(' --- noti : ', notificationTitle)

                                const noti = new Notification(
                                  notificationTitle,
                                  notificationOptions,
                                )

                                noti.addEventListener('click', (event) => {
                                  console.log('notification click - ', event)

                                  const { path } = event.target.data

                                  history.push(path)

                                  event.target.close() // Android needs explicit close.
                                })

                                notiMap[key] = null
                              }, 10)
                            })(curKey)
                          }
                        }
                      }
                    }
                  })

                  resolve(currentToken)
                } else {
                  // Show permission request UI
                  console.log(
                    'No registration token available. Request permission to generate one.',
                  )

                  reject(
                    new Error(
                      'No registration token available. Request permission to generate one.',
                    ),
                  )
                }
              })
              .catch((err) => {
                console.log('An error occurred while retrieving token. ', err)
                reject(err)
              })
          } else {
            reject(new Error('Firebase Messaging is not supported.'))
          }
        } else {
          console.log('[Notification] 차단: ', result)
          reject(new Error('Notification permission denied.'))
        }
      }

      if (Notification.permission === 'default' && byUser === true) {
        Notification.requestPermission().then(processNotiResult)
      } else {
        processNotiResult(Notification.permission)
      }
    } else {
      reject(new Error('Notification is not supported.'))
    }
  })

  return promise
}

export function deleteMessagingToken() {
  if (firebaseObj.isSupported === true) {
    const { messaging } = firebaseObj

    getToken(messaging, { vapidKey })
      .then((currentToken) => {
        if (messageObj.unsubscribe != null) {
          messageObj.unsubscribe()
          messageObj.unsubscribe = null
        }
        deleteToken(messaging, currentToken)
          .then(() => {
            console.log('Token deleted.')
            // setTokenSentToServer(false);
          })
          .catch((err) => {
            console.log('Unable to delete token. ', err)
          })
      })
      .catch((err) => {
        console.log('Error retrieving registration token. ', err)
      })
  } else {
    console.log('Firebase Messaging is not supported.')
  }
}

// message test.
// window.postMessage({
//   action: 'redirect-from-notificationclick',
//   url: 'http://localhost:3100/conversation/chat/17/174'
// })

navigator.serviceWorker.addEventListener('message', (event) => {
  if (!event.data.action) {
    return
  }

  switch (event.data.action) {
    case 'redirect-from-notificationclick':
      {
        // window.location.href = event.data.url
        let { url } = event.data
        if (url.indexOf(window.location.origin) === 0) {
          url = url.substring(window.location.origin.length)

          const urlData = {
            pathname: url,
            state: {
              notiClick: true,
            },
            // replaceUrl: true,
          }
          history.push(urlData)
        } else {
          window.location.href = url
        }
      }
      break
    default:
  }
})

const defaultProps = {}

export default defaultProps
