import PostalMime from 'postal-mime'
import mailApi from 'services/mailApi'
import { encode, decode } from 'url-safe-base64'
import forge from 'node-forge'
import { v4 as uuidv4 } from 'uuid'

import { restClient } from 'myNet'
import { getTextFromHTML, makeSearchString } from 'utils'

import store from 'store'
import { getEnvParam } from 'env'

function getQueries(skip, limit, lastId, timeKey, updateTime, idKey, sort) {
  const queries = {
    skip,
    limit,
  }

  queries.filters = []

  if (lastId != null) {
    queries.filters.push(`${idKey} lt ${lastId}`)
  }

  if (updateTime != null) {
    const timeKeyStr = timeKey || 'updated_at'
    queries.filters.push(`${timeKeyStr} gte ${updateTime}`)
    queries.sort = `${timeKeyStr} asc`
  }

  if (sort != null) {
    queries.sort = sort
  }

  return queries
}

export function getPMID64(pmId) {
  // btoa, atob - base64 encoding, decoding
  // primaryMessageId는 base64 encoding 후 사용.
  // encode - url safe base64로 바꿔줌. (input - base64, output - url safe base64)
  const primaryMessageId64 = encode(window.btoa(pmId))
  return primaryMessageId64
}

export function getPMID(pmId64) {
  // btoa, atob - base64 encoding, decoding
  // primaryMessageId는 base64 encoding 후 사용.
  // encode - url safe base64로 바꿔줌. (input - base64, output - url safe base64)
  const primaryMessageId64 = window.atob(decode(pmId64))
  return primaryMessageId64
}

export function getMessageInfo(mailId) {
  const promise = new Promise((resolve, reject) => {
    const ret2 = mailApi.getMessageInfo(mailId)
    ret2.then((res2) => {
      // console.log('res2 -- ', res2)
      const parser = new PostalMime()
      parser
        .parse(res2)
        .then((parsed) => {
          // console.log('parsed - ', parsed)
          let firstReference = null
          if (parsed.references != null && parsed.references.length > 0) {
            const references = parsed.references.split(' ')
            console.log('references - ', references)
            ;[firstReference] = references
          } else {
            firstReference = parsed.messageId
          }

          resolve({
            firstReference,
            data: parsed,
          })
        })
        .catch((err) => {
          console.log('error - ', err)
          reject(err)
        })
    })
  })

  return promise
}

export function getRoomMembers(chatRoomId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = null
  // {
  //   // email: userEmail,
  //   // workspace_id: workspaceId,
  // }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/${chatRoomId}`

  return restClient.get(api, data, options)
}

/*
 *  lastId - 지정된 id 보다 작은 id들을 검색.
 *  updateTime - 지정된 updateTime 보다 크거나 같은 message들을 검색.
 *  ==> queries로 통합.
 */
export function getRoomMessages(chatRoomId, queries) {
  // const queries = getQueries(0, limit, lastId, 'update_time', updateTime, '_id', sort)

  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/${chatRoomId}`

  return restClient.get(api, data, options)
}

export function getMessages(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages`

  return restClient.get(api, data, options)
}

export function searchRoomMessages(chatRoomId, queries) {
  // const queries = getQueries(0, limit, lastId, 'update_time', updateTime, '_id', sort)

  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/search/messages/room/${chatRoomId}`

  return restClient.get(api, data, options)
}

export function searchMessages(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/search/messages`

  return restClient.get(api, data, options)
}

export function checkExistRoom(primaryMessageId64) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  console.log('pmId : ', primaryMessageId64)

  const api = `/api/chat/rooms/key/${primaryMessageId64}`

  const promise = new Promise((resolve, reject) => {
    restClient
      .get(api, params, options)
      .then((res) => {
        console.log('room check : ', res)
        resolve(res)
      })
      .catch((err) => {
        console.log('err - ', err)
        reject(err)
      })
  })

  return promise
}

/**
 * 내부 (동일 workspace) email
 * 외부 (다른 workspace) email
 * guest email
 * 전달된 이메일 주소를 check.
 * 각 이메일 별로 상세정보 및 exists, domain_exists 정보를 반환.
 * 아직 가입하지 않은 내부/외부 email은 domain_exists는 true이고 worksapace_id가 전달됨.
 *
 * @export
 * @param {any} memberList - email 주소 배열.
 * @param {any} checkSelf - true이면 자신의 email이 없는 경우 추가해서 요청.
 * @returns api promise.
 */
export function checkMember(memberList, checkSelf) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  if (checkSelf !== true) {
    // 자기 자신이 없으면 추가할 것.
    const pos = memberList.indexOf(userEmail)
    if (pos < 0) {
      memberList.push(userEmail)
    }
  }

  if (memberList.length === 0) {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: { data: [] },
        })
      }, 10)
    })

    return promise
  }

  const data = {
    // workspace_id: workspaceId,
    // email: userEmail,
    emails: memberList,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = '/api/chat/members/check'

  return restClient.post(api, data, options)
}

// type - mail, calendar, channel, direct_message
// role - private, public
export function createRoom(primaryMessageId64, name, memberList, desc, type, role, memberObjMap) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const members = memberList.map((email) => {
    const ret = {
      email,
    }

    if (memberObjMap != null && memberObjMap[email] != null) {
      const member = memberObjMap[email]
      const isExternal = member.workspace_id != null && workspaceId !== member.workspace_id
      if (member.isGuest === true) {
        ret.type = 'guest'
      } else if (member.exists === false) {
        if (member.domain_exists === false) {
          ret.type = 'guest'
        } else {
          ret.status = 2
          if (isExternal === true) {
            ret.workspace_id = member.workspace_id
          }
        }
      } else if (isExternal === true) {
        ret.type = 'member'
        ret.workspace_id = member.workspace_id
      }
    }
    return ret
  })

  // console.log(' --- createRoom ', members, workspaceId, userEmail);
  // return null;

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    display_name: name,
    primary_id: primaryMessageId64,
    members,
    type,
    access_role: role,
  }

  if (desc != null) {
    data.description = desc
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = '/api/chat/rooms'

  return restClient.post(api, data, options)
}

export function getDirectMessagePID(memberList) {
  const workspaceId = store.get('app.user.workspace_id')

  if (memberList.length < 2 || memberList.length > 9) {
    throw new Error('You can create direct messages for 2 to 9 members..')
  }

  const members = [...memberList]

  members.sort()

  let idStr = `${workspaceId}`
  members.forEach((email) => {
    idStr += `+${email}`
  })

  const md = forge.md.sha256.create()
  md.update(idStr)
  const primaryId = md.digest().toHex()
  console.log(' primaryId - ', primaryId, idStr)

  return `DM:${primaryId}`
}

export function getDirectMessageRoom(members, onRoomReady, onError, memberObjMap) {
  const primaryId = getDirectMessagePID(members)

  console.log(' onMenuClick - ', primaryId)

  getRoomInfo64(primaryId).then((res) => {
    console.log(' getRoomInfo64 - ', res)

    const roomObj = res.data.data

    if (roomObj != null) {
      // 이미 방이 존재함.
      if (onRoomReady != null) {
        onRoomReady(primaryId, roomObj)
      }
    } else {
      // 아직 방이 없음.
      members.sort()
      const title = members.join(', ')
      createRoom(
        primaryId,
        title,
        members,
        'DIRECT_MESSAGE',
        'direct_message',
        'private',
        memberObjMap,
      )
        .then((res1) => {
          const resData = res1.data.data
          if (resData != null) {
            const newRoomObj = {
              ChatRoom: {
                id: resData.chat_room_id,
              },
              ChatRoomKey: resData,
            }

            if (onRoomReady != null) {
              onRoomReady(primaryId, newRoomObj)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          if (onError != null) {
            onError(err)
          }
        })
    }
  })
}

export function convertDMToChannel(roomId, title) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const tmpId = createChannelID()
  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    primary_id: tmpId,
    display_name: title,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/rooms/convert/${roomId}`

  return restClient.post(api, data, options)
}

export function createChannelID() {
  const uuid = uuidv4().replace(/-/gi, '')

  const tmpId = `Channel:${uuid}`

  return tmpId
}

export function createChannel(title, desc, role, members, onRoomReady, onError, memberObjMap) {
  let primaryId = null

  const checkRoomExist = (onEnd) => {
    const tmpId = createChannelID()

    getRoomInfo64(tmpId).then((res) => {
      console.log(' getRoomInfo64 - ', res)

      const roomObj = res.data.data

      if (roomObj == null) {
        console.log(' room is not exist. ')
        onEnd(tmpId)
      } else {
        console.log(' room is aleady exist. ')
        if (onError != null) {
          onError(new Error('aleady exist.'))
        }
      }
    })
  }

  checkRoomExist((uuid) => {
    primaryId = uuid
    members.sort()

    console.log(primaryId, title, members, desc)

    createRoom(primaryId, title, members, desc, 'channel', role, memberObjMap)
      .then((res1) => {
        const { code, data: resData, message } = res1.data
        if (code === 200 && resData != null) {
          const newRoomObj = {
            ChatRoom: {
              id: resData.chat_room_id,
            },
            ChatRoomKey: resData,
          }

          if (onRoomReady != null) {
            onRoomReady(primaryId, newRoomObj)
          }
        } else {
          if (onError != null) {
            const err = new Error(message)
            err.code = code
            onError(err)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        if (onError != null) {
          onError(err)
        }
      })
  })
}

export function getRoomInfo64(pmId64) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/rooms/key/${pmId64}`

  return restClient.get(api, data, options)
}

export function createSendData(isNew, type, content, attachments, previewUrl, replyList, mentions) {
  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    content,
    type,
    preview_url: previewUrl,
  }

  if (replyList != null && replyList.length > 0) {
    ;[data.reply] = replyList
  } else if (isNew !== true) {
    data.reply = null
  }

  if (attachments != null && attachments.length > 0) {
    data.attachments = attachments
  } else if (isNew !== true) {
    data.attachments = null
  }

  if (mentions != null && mentions.length > 0) {
    data.mentions = mentions
  } else if (isNew !== true) {
    data.mentions = null
  }

  if (content != null && content.length > 0) {
    data.text = getTextFromHTML(content, (root) => {
      const atTags = root.querySelectorAll('at')
      atTags.forEach((tag) => {
        const textNode = document.createTextNode('@')
        tag.insertBefore(textNode, tag.firstChild)
      })
    })
  } else {
    data.text = null
  }

  return data
}

export function createMessageObj(
  isNew,
  chatRoomId,
  objectData,
  type,
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const data = createSendData(isNew, type, content, attachments, previewUrl, replyList, mentions)

  if (isNew) {
    const clientMessageId = `CM_${Date.now()}`
    data.content_id = clientMessageId
    data.primary_id = objectData.pmId64
  }

  const messagePacket = {
    isNew,
    chatRoomId,
    ...objectData,
    data,
  }

  return messagePacket
}

export function sendMessageObj(messageObj) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    ...messageObj.data,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/${messageObj.chatRoomId}`

  return restClient.post(api, data, options)
}

export function updateMessageObj(messageObj) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  console.log('updateMessageObj - ', messageObj)

  const data = {
    ...messageObj.data,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/${messageObj.chatRoomId}/${messageObj.messageId}`

  return restClient.put(api, data, options)
}

export function sendMessage(
  chatRoomId,
  pmId64,
  type,
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const messageObj = createMessageObj(
    true,
    chatRoomId,
    {
      pmId64,
    },
    type,
    content,
    attachments,
    previewUrl,
    replyList,
    mentions,
  )

  return sendMessageObj(messageObj)
}

export function updateMessage(
  chatRoomId,
  messageId,
  type,
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const messageObj = createMessageObj(
    false,
    chatRoomId,
    {
      messageId,
    },
    type,
    content,
    attachments,
    previewUrl,
    replyList,
    mentions,
  )

  return updateMessageObj(messageObj)
}

export function getMessage(chatRoomId, messageId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/${chatRoomId}/${messageId}`

  return restClient.get(api, data, options)
}

export function deleteMessage(chatRoomId, messageId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/${chatRoomId}/${messageId}`

  return restClient.delete(api, data, options)
}

export function getFileList(params) {
  // console.log(sort, filters)
  // const queries = getQueries(0, limit, lastId, 'update_time', updateTime, '_id', sort)

  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/list`

  return restClient.get(api, params, options)
}

export function isExistFile(chatRoomId, filename) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/exist/${chatRoomId}/${encodeURIComponent(filename)}`

  return restClient.get(api, data, options)
}

// 사이즈 제한.
// 이미지 5Mb
// 이미지 이외 100Mb
export function xhrUploadFile(chatRoomId, formData, onProgress, onBeforeSend) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
      // 'Content-Type': 'mutipart/form-data'
    },
  }

  const paramStr = makeSearchString(data)

  const api = `/api/chat/files/upload/${chatRoomId}?${paramStr}`

  return restClient.xhrPost(api, formData, onProgress, onBeforeSend, options)
}

export function xhrGetFile(chatRoomId, fileKey, onProgress, onBeforeSend) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/download/${chatRoomId}/${fileKey}`

  // return restClient.get(api, data)

  // const api = restClient.apiURL + path

  // if (params != null) {
  //   const search = makeSearchString(params)
  //   if (search != null) {
  //     api += `?${search}`
  //   }
  // }

  // const options = {
  //   method: 'GET',
  // }

  // const token = store.get('app.user.token')

  // if (token != null) {
  //   const newHeaders = {}
  //   newHeaders.authorization = `JWT ${token}`
  //   options.headers = newHeaders
  // }

  const onBeforeSendNew = (xhr) => {
    xhr.responseType = 'blob'

    if (onBeforeSend != null) {
      onBeforeSend(xhr)
    }
  }

  // return window.fetch.call(window, api, options)
  return restClient.xhrGet(api, params, onProgress, onBeforeSendNew, options)
}

export function xhrGetRoomFile(chatRoomId, fileKey, onProgress, onBeforeSend) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/download/${chatRoomId}/chat_room/${fileKey}`

  const onBeforeSendNew = (xhr) => {
    xhr.responseType = 'blob'

    if (onBeforeSend != null) {
      onBeforeSend(xhr)
    }
  }

  // return window.fetch.call(window, api, options)
  return restClient.xhrGet(api, params, onProgress, onBeforeSendNew, options)
}

export function deleteFile(chatRoomId, fileKey) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/${chatRoomId}/${fileKey}`

  return restClient.delete(api, data, options)
}

export function deleteTempFile(chatRoomId, fileKeyArr) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    temp_digests: [...fileKeyArr],
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/files/delete/temp/${chatRoomId}`

  return restClient.post(api, data, options)
}

export function createCommentObj(
  isNew,
  chatRoomId,
  objectData,
  type,
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const data = createSendData(isNew, type, content, attachments, previewUrl, replyList, mentions)

  if (isNew === true) {
    const clientCommentId = `CC_${Date.now()}`
    data.content_id = clientCommentId
  }

  const commentPacket = {
    isNew,
    chatRoomId,
    ...objectData,
    data,
  }

  return commentPacket
}

export function sendCommentObj(commentObj) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    ...commentObj.data,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/comments/${commentObj.chatRoomId}/${commentObj.objectType}/${commentObj.objectId}`

  return restClient.post(api, data, options)
}

export function updateCommentObj(commentObj) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    ...commentObj.data,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/comments/${commentObj.chatRoomId}/${commentObj.objectId}/${commentObj.commentId}`

  return restClient.put(api, data, options)
}

export function sendComment(
  chatRoomId,
  objectType, // message / mail / comment
  objectId,
  type, // comment / file
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const commentObj = createCommentObj(
    true,
    chatRoomId,
    {
      objectType, // message / mail / comment
      objectId,
    },
    type, // comment / file
    content,
    attachments,
    previewUrl,
    replyList,
    mentions,
  )

  return sendCommentObj(commentObj)
}

export function updateComment(
  chatRoomId,
  objectId,
  commentId,
  type,
  content,
  attachments,
  previewUrl,
  replyList,
  mentions,
) {
  const commentObj = createCommentObj(
    false,
    chatRoomId,
    {
      objectId,
      commentId,
    },
    type, // comment / file
    content,
    attachments,
    previewUrl,
    replyList,
    mentions,
  )

  return updateCommentObj(commentObj)
}

export function getCommentList(chatRoomId, objectId, queries) {
  // const queries = getQueries(0, limit, lastId, 'update_time', updateTime, '_id', sort)

  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/comments/${chatRoomId}/${objectId}`

  return restClient.get(api, data, options)
}

export function getComment(chatRoomId, objectId, commentId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/comments/${chatRoomId}/${objectId}/${commentId}`

  return restClient.get(api, data, options)
}

export function deleteComment(chatRoomId, objectId, commentId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/comments/${chatRoomId}/${objectId}/${commentId}`

  return restClient.delete(api, data, options)
}

export function getRoom(roomId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = null
  // {
  //   // email: userEmail,
  //   // workspace_id: workspaceId,
  // }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.get(`/api/chat/rooms/${roomId}`, params, options)
}

export function getRoomList(lastId, updateTime, limit) {
  const queries = getQueries(0, limit, lastId, 'updated_at', updateTime, 'id', 'updated_at asc')

  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.get('/api/chat/rooms', params, options)
}

export function getRoomList2(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.get('/api/chat/rooms', params, options)
}

export function getChannels(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.get('/api/chat/rooms/channel', params, options)
}

// 사용하지 않음.
// export function updateRoom(roomId, displayName, description, role, archive) {
//   const workspaceId = store.get('app.user.workspace_id')
//   const userEmail = getEnvParam('userEmail')

//   const data = {}

//   if (displayName != null) {
//     data.display_name = displayName
//   }

//   if (description != null) {
//     data.description = description
//   }

//   if (role != null) {
//     data.access_role = role
//   }

//   if (archive != null) {
//     data.archive = archive
//   }

//   const options = {
//     headers: {
//       Email: userEmail,
//       'Workspace-ID': workspaceId,
//       'Product-Name': 'Re:Work Chat for web',
//     },
//   }

//   return restClient.patch(`/api/chat/rooms/${roomId}`, data, options)
// }

export function updateRoomEx(roomId, displayName, description, role, photoFile, archive) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const formData = new FormData()
  if (displayName != null) {
    formData.append('display_name', displayName)
  }

  if (description != null) {
    formData.append('description', description)
  }

  if (role != null) {
    formData.append('access_role', role)
  }

  if (photoFile != null) {
    formData.append('photo', photoFile)
  }

  if (archive != null) {
    formData.append('archive', archive)
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.xhrPatch(`/api/chat/rooms/${roomId}`, formData, null, null, options)
}

export function updateConfig(roomId, notificationObj) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    ...notificationObj,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  return restClient.patch(`/api/chat/members/me/${roomId}`, data, options)
}

export function putReaction(
  chatRoomId,
  objectType, // message / mail / comment
  objectId,
  type,
  customData, // string - custom(7)일 때 사용.
) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    type,
  }

  if (customData != null) {
    data.data = customData
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/reactions/${chatRoomId}/${objectType}/${objectId}`

  return restClient.put(api, data, options)
}

export function deleteReaction(
  chatRoomId,
  objectType, // message / mail / comment
  objectId,
) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/reactions/${chatRoomId}/${objectType}/${objectId}`

  return restClient.delete(api, data, options)
}

export function getUrlInfo(url) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    url,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages/url/info`

  return restClient.get(api, data, options)
}

export function searchMember(str) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    keyword: str || '',
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/search`

  return restClient.post(api, data, options)
}

export function addMember(chatRoomId, emailArray) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    members: emailArray,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/${chatRoomId}`

  return restClient.post(api, data, options)
}

export function deleteMember(chatRoomId, emailArray) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
    members: emailArray,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/${chatRoomId}`

  return restClient.delete(api, data, options)
}

export function enterRoom(chatRoomId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/me/${chatRoomId}`

  return restClient.post(api, data, options)
}

export function leaveRoom(chatRoomId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: userEmail,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/members/me/${chatRoomId}`

  return restClient.delete(api, data, options)
}

export function deleteRoom(chatRoomId) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const data = {
    // email: user.email,
    // workspace_id: workspaceId,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/rooms/${chatRoomId}`

  return restClient.delete(api, data, options)
}

// room에 상관없이 호출 가능.
export function getMentions(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/mentions`

  return restClient.get(api, params, options)
}

// room에 상관없이 호출 가능.
export function getComments(queries) {
  const workspaceId = store.get('app.user.workspace_id')
  const userEmail = getEnvParam('userEmail')

  const params = {
    // email: userEmail,
    // workspace_id: workspaceId,
    ...queries,
  }

  const options = {
    headers: {
      Email: userEmail,
      'Workspace-ID': workspaceId,
      'Product-Name': 'Re:Work Chat for web',
    },
  }

  const api = `/api/chat/messages`

  return restClient.get(api, params, options)
}

const defaultObj = {
  getPMID,
  getPMID64,
  getMessageInfo,
  getRoomMembers,
  getRoomMessages,
  getMessages,
  searchRoomMessages,
  searchMessages,
  checkExistRoom,
  checkMember,
  createRoom,
  createChannelID,
  createChannel,
  getDirectMessagePID,
  getDirectMessageRoom,
  convertDMToChannel,
  getRoomInfo64,
  sendMessage,
  getMessage,
  updateMessage,
  deleteMessage,
  getFileList,
  isExistFile,
  xhrUploadFile,
  xhrGetFile,
  xhrGetRoomFile,
  deleteFile,
  deleteTempFile,
  sendComment,
  updateComment,
  getCommentList,
  getComment,
  deleteComment,
  getRoomList,
  getRoomList2,
  getChannels,
  getRoom,
  // updateRoom,
  updateRoomEx,
  updateConfig,
  putReaction,
  deleteReaction,
  getUrlInfo,
  searchMember,
  addMember,
  deleteMember,
  enterRoom,
  leaveRoom,
  deleteRoom,
  getMentions,
  getComments,
}

export default defaultObj
