function DataDB(name) {
  const self = this

  self.dbName = name
  self.isActive = false
  self.db = null
  self.loaded = false

  self.expireDuration = 30 * 24 * 60 * 60 * 1000 // 30일 후 만료.

  // 내부에 메일 배열을 가지고 있도록 하여, 없는 것만 새로 DB에 추가하도록 처리(배열에도 함께 추가.).
  // 데이터를 가져가는 것은 배열에서 가져가도록 할 것.
  // 표시되는 메세지의 날짜에 맞춰서 적당히 가져가도록~!!
  self.dataMap = {}

  self.init = () => {
    const DBOpenRequest = window.indexedDB.open(self.dbName, 2)

    DBOpenRequest.onerror = (error) => {
      console.log('error - db open failed', error)
      self.isActive = false
    }

    DBOpenRequest.onsuccess = (event) => {
      console.log('success - db open success.')

      self.isActive = true

      self.db = event.target.result

      self.load()
    }

    DBOpenRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        console.log('error - db upgrade.', error)
        self.isActive = false
      }

      if (event.oldVersion === 1) {
        db.deleteObjectStore(self.dbName)
      }

      const objectStore = db.createObjectStore(self.dbName, { keyPath: 'digest' })
      // const objectStore = db.createObjectStore(self.dbName, { keyPath: 'url' })

      // objectStore.createIndex('conversationId', 'conversationId', { unique: true })
      objectStore.createIndex('expireTime', 'expireTime', { unique: false })
      // objectStore.createIndex('id', 'id', { unique: true })
    }

    self.isActive = true
  }

  self.close = () => {
    if (self.isActive === true) {
      self.db.close()
    }
    self.isActive = false
  }

  self.load = () => {
    if (self.isActive !== true) {
      return null
    }

    self.dataMap = {}

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database load finished.', tEvent)
      }

      transaction.onerror = (tEvent) => {
        console.log('Transaction not opened due to error.', tEvent)
        reject(tEvent)
      }

      const objectStore = transaction.objectStore(self.dbName)
      // const req = objectStore.openCursor()
      const index = objectStore.index('expireTime')

      // const keyRangeValue = IDBKeyRange.only(conversationId)
      const keyRangeValue = IDBKeyRange.upperBound(Date.now())
      const req = index.openCursor(keyRangeValue)
      req.onsuccess = (event) => {
        const cursor = event.target.result

        // 만료된 데이터 제거.
        if (cursor) {
          cursor.delete()
          cursor.continue()
        } else {
          self.loaded = true
          resolve(true)
        }
      }

      req.onerror = (err) => {
        console.log('cursor read error.', err)
        reject(err)
      }
    })

    return promise
  }

  self.addData = (digest, blob) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const newItem = {
        digest,
        blob,
        expireTime: Date.now() + self.expireDuration,
      }

      const objectStoreRequest = objectStore.add(newItem)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Added successfully.', event)

        self.dataMap[digest] = {
          ...newItem,
          url: window.URL.createObjectURL(newItem.blob),
        }

        resolve(self.dataMap[digest])
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to add.', error)
        reject(error)
      }
    })

    return promise
  }

  self.putData = (digest, blob) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const newItem = {
        digest,
        blob,
        expireTime: Date.now() + self.expireDuration,
      }

      const objectStoreRequest = objectStore.put(newItem)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Added successfully.', event)

        if (self.dataMap[digest] != null) {
          if (self.dataMap[digest].url != null) {
            window.URL.revokeObjectURL(self.dataMap[digest].url)
          }
        }

        self.dataMap[digest] = {
          ...newItem,
          url: window.URL.createObjectURL(newItem.blob),
        }

        resolve(self.dataMap[digest])
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to add.', error)
        reject(error)
      }
    })

    return promise
  }

  self.deleteData = (digest) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      if (self.dataMap[digest] != null) {
        if (self.dataMap[digest].url != null) {
          window.URL.revokeObjectURL(self.dataMap[digest].url)
        }
        delete self.dataMap[digest]
      }

      const transaction = self.db.transaction([self.dbName], 'readwrite')

      transaction.oncomplete = (tEvent) => {
        console.log('Transaction completed: database modification finished.', tEvent)
      }

      transaction.onerror = (error) => {
        console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
      }

      const objectStore = transaction.objectStore(self.dbName)

      const objectStoreRequest = objectStore.delete(digest)

      objectStoreRequest.onsuccess = (event) => {
        console.log('Deleted successfully.', event)
        resolve(digest)
      }

      objectStoreRequest.onerror = (error) => {
        console.log('failed to delete.', error)
        reject(error)
      }
    })

    return promise
  }

  self.getData = (digest) => {
    if (self.isActive !== true) {
      return null
    }

    const promise = new Promise((resolve, reject) => {
      if (self.dataMap[digest] != null) {
        resolve(self.dataMap[digest])
      } else {
        const transaction = self.db.transaction([self.dbName], 'readwrite')

        transaction.oncomplete = (tEvent) => {
          console.log('Transaction completed: database get finished.', tEvent)
        }

        transaction.onerror = (error) => {
          console.log('Transaction not opened due to error. Duplicate items not allowed.', error)
          reject(error)
        }

        const objectStore = transaction.objectStore(self.dbName)

        const objectStoreRequest = objectStore.get(digest)

        objectStoreRequest.onsuccess = (event) => {
          console.log('get successfully.', event)

          const { result } = objectStoreRequest

          if (result != null) {
            self.dataMap[digest] = {
              ...result,
              url: window.URL.createObjectURL(result.blob),
            }

            resolve(self.dataMap[digest])
          } else {
            reject(new Error('No matching data.'))
          }
        }

        objectStoreRequest.onerror = (error) => {
          console.log('failed to get.', error)
          reject(error)
        }
      }
    })

    return promise
  }

  self.isLoaded = () => {
    return self.loaded
  }
}

const dataDB = new DataDB('imageDB')
dataDB.init()

export default dataDB
