import React from 'react'
import { withTranslation } from 'react-i18next'
import parse from 'html-react-parser'

import { getEnvParam } from 'env'
import { getMemberInfo, getDefaultName } from 'pages/chat/component/chatView/data/cUtil'
import dataMgr from 'pages/chat/component/chatView/data/dataManager'

import classNames from 'classnames'

import styles from '../style.module.scss'

class ShortMemberListDef extends React.Component {
  state = {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { addList, tempMemberMap, extraElem, hideMe, t } = this.props

    const memberMap = dataMgr.globalMemberMap

    if (memberMap != null) {
      const userEmail = getEnvParam('userEmail')

      const memberList = addList.map((tag) => {
        const member = memberMap[tag] || tempMemberMap[tag]
        return member
      })

      const entries =
        hideMe === true
          ? memberList.filter((item) => {
              return item.email !== userEmail
            })
          : memberList

      // if (entries.length === 0) {
      //   return null;
      // }

      let imgs = null
      let descElem = null
      const descProps = {}

      if (entries.length > 0) {
        imgs = entries.slice(0, 3).map((item) => {
          const member = item
          const key = item.email

          const memberInfo = getMemberInfo(member, member.email, null)

          const { avatarSrc, name } = memberInfo

          const memberProps = {
            member: true,
          }
          if (styles != null) {
            memberProps[styles.member] = true
          }

          return (
            <span className={classNames(memberProps)} key={key}>
              <img src={`${avatarSrc}`} alt={name} />
            </span>
          )
        })

        // const firstMember = entries[0].email !== userEmail || entries.length < 2 ? entries[0] : entries[1]
        const firstMember = entries[0]
        const name = getDefaultName(firstMember)
        const nameHtml = `<span class='nameWrap'><span class='name'>${name}</span></span>`

        let descStr = ''
        if (hideMe === true) {
          if (entries.length === 1) {
            descStr = nameHtml
            descProps.singleMember = true
          } else if (entries.length === 2) {
            descStr = t('chat.member.desc2', { name: nameHtml, count: 2 })
          } else {
            descStr = t('chat.member.desc2', { name: nameHtml, count: entries.length })
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (entries.length === 1) {
            descStr = nameHtml
            descProps.singleMember = true
          } else if (entries.length === 2) {
            descStr = t('chat.member.desc1', { name: nameHtml, count: 1 })
          } else {
            descStr = t('chat.member.desc2', { name: nameHtml, count: entries.length - 1 })
          }
        }

        descElem = parse(descStr)
      }

      const memberListElem = (
        <div
          className={classNames(styles.memberList, styles.shortType, 'shortMemberList')}
          key="memberList"
        >
          {imgs}
          <span className={classNames(styles.desc, 'desc', descProps)}>{descElem}</span>
          {extraElem}
        </div>
      )

      return memberListElem
    }

    return ''
  }
}

const ShortMemberListComp = withTranslation()(ShortMemberListDef)

export default ShortMemberListComp
